<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { PaginationNext, type PaginationNextProps } from 'radix-vue';
import { ChevronRight } from 'lucide-vue-next';

import { Button } from '@/components/ui/Button';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

import { COMPONENT_VARIANTS } from '@/constants/component';

const props = withDefaults(
  defineProps<
    PaginationNextProps & {
      class?: HTMLAttributes['class'];
      isDisabled?: boolean;
    }
  >(),
  {
    asChild: true,
    class: null,
    isDisabled: false
  }
);

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <PaginationNext v-bind="delegatedProps">
    <Button
      type="button"
      :class="cn('w-10 h-10 p-0', props.class)"
      :is-disabled="isDisabled"
      :variant="COMPONENT_VARIANTS.ghost"
    >
      <slot>
        <ChevronRight class="h-6 w-6 text-gray-500" />
      </slot>
    </Button>
  </PaginationNext>
</template>
