<script setup lang="ts">
import { computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';

import { User2 } from 'lucide-vue-next';

import { Badge } from '@/components/ui/Badge';
import { GroupUsersDetails } from '@/views/Groups/components';

import { ALLOWED_BREAKPOINTS } from '@/constants/layout';
import { COMPONENT_VARIANTS } from '@/constants/component';
import { MAX_VISIBLE_GROUPS } from '@/constants/group';

const is2XLScreen = useMediaQuery(ALLOWED_BREAKPOINTS['2xl']);
const is4XLScreen = useMediaQuery(ALLOWED_BREAKPOINTS['4xl']);
const props = defineProps<{
  users: string[];
}>();
const displayList = computed(() => {
  if (is4XLScreen.value) {
    return props.users.slice(0, MAX_VISIBLE_GROUPS['4xl']);
  }

  return is2XLScreen.value
    ? props.users.slice(0, MAX_VISIBLE_GROUPS['2xl'])
    : props.users.slice(0, MAX_VISIBLE_GROUPS.lg);
});
</script>

<template>
  <div
    class="flex flex-col lg:flex-row gap-1.5 text-xs text-gray-600 flex-wrap"
  >
    <div
      v-for="(user, index) in displayList"
      :key="`${user}-${index}`"
      class="flex flex-row gap-1.5"
    >
      <Badge
        :variant="COMPONENT_VARIANTS.ghost"
        shape="rectangular"
        class="shrink-0 h-min px-2.5 w-max max-w-80"
        :title="user"
      >
        <User2 class="shrink-0 h-3 w-3" />
        <span class="truncate">{{ user }}</span>
      </Badge>
      <GroupUsersDetails
        v-if="!is2XLScreen && index === displayList.length - 1"
        :users="props.users"
        :display-list="displayList"
      />
    </div>

    <GroupUsersDetails
      v-if="is2XLScreen"
      :users="props.users"
      :display-list="displayList"
    />
  </div>
</template>
