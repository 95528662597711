<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<{
  class?: HTMLAttributes['class'];
  style?: HTMLAttributes['style'];
  isLastColumn?: boolean;
}>();
</script>

<template>
  <th
    :class="
      cn(
        'h-11 px-4 align-middle text-xs font-semibold text-slate-500 uppercase [&:has([role=checkbox])]:pr-0',
        props.class,
        isLastColumn ? 'text-center' : 'text-left'
      )
    "
    :style="props.style"
  >
    <slot />
  </th>
</template>
