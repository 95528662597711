import {
  NAMESPACE_MONITORING,
  NAMESPACE_PORTFOLIO
} from '@/constants/containerAccessLevel';

export enum AccessLevels {
  VIEW_ONLY = 1,
  READ_ONLY = 3,
  READ_WRITE = 5,
  EXTENDED = 6,
  OWNED = 7
}

export type ContainerNamespace =
  | typeof NAMESPACE_PORTFOLIO
  | typeof NAMESPACE_MONITORING;

export type Container = {
  name: string;
  namespace: ContainerNamespace;
  ownerName: string;
  accessLevel: AccessLevels;
};
