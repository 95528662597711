<script setup lang="ts">
import { inject, ref, type Ref } from 'vue';

import { Check, Copy } from 'lucide-vue-next';

import { Button } from '@/components/ui/Button';
import { Badge } from '@/components/ui/Badge';
import { CustomTextarea } from '@/components/custom';

import { COMPONENT_VARIANTS } from '@/constants/component';
import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const copied = ref(false);

/**
 * Please notice useClipboard (https://vueuse.org/core/useClipboard/#useclipboard)
 * have some issues which leads to use deprecated method instead
 */
function copyAction() {
  navigator.clipboard.writeText(
    currentUser.value.twoFactorRecoveryCodes.join('\n')
  );

  copied.value = true;

  window.setTimeout(() => (copied.value = false), 3000);
}
</script>

<template>
  <section>
    <Badge
      class="px-3 py-2.5 h-10"
      :variant="
        currentUser.twoFactorEnabled
          ? COMPONENT_VARIANTS.success
          : COMPONENT_VARIANTS.default
      "
      size="medium"
    >
      <span class="uppercase">
        {{ $t('common.status') }}:
        <strong>
          {{
            currentUser.twoFactorEnabled ? $t('common.on') : $t('common.off')
          }}
        </strong>
      </span>
    </Badge>

    <div v-if="currentUser.twoFactorEnabled">
      <div class="flex flex-col gap-3 my-7">
        <p class="font-normal">
          {{ $t('userProfile.two_fa_description') }}
        </p>
        <p>
          {{ $t('userProfile.two_fa_scan') }}
        </p>
      </div>

      <div v-html="currentUser.twoFactorQrCode" />

      <div class="flex flex-col gap-5 lg:gap-7 mt-7">
        <p class="font-normal">{{ $t('userProfile.two_fa_codes') }}</p>

        <CustomTextarea
          class="h-60"
          id="two-factor-recovery-codes"
          label-text="userProfile.recovery_codes"
          attribute="userProfile.recovery_codes"
          :placeholder="$t('userProfile.recovery_codes')"
          :value="currentUser.twoFactorRecoveryCodes.join('\n')"
          :is-readonly="true"
        />

        <Button
          class="min-w-25 w-full lg:w-max ml-auto"
          type="button"
          :variant="COMPONENT_VARIANTS.ghost"
          @click="copyAction"
        >
          <span
            v-if="!copied"
            class="flex mt-0.5 align-center gap-2 default-message"
          >
            <Copy class="w-3.5 h-3.5 shrink-0 mt-1" />
            {{ $t('userProfile.copy_codes') }}
          </span>

          <span
            v-if="copied"
            class="flex mt-0.5 align-center gap-2 success-message"
          >
            <Check class="w-3.5 h-3.5 shrink-0 mt-1" />
            {{ $t('manageTokens.copied') }}
          </span>
        </Button>
      </div>
    </div>
  </section>
</template>
