<script setup lang="ts">
import { Tippy } from 'vue-tippy';
import { useTranslation } from 'i18next-vue';

import PPIcon from 'assets/icons/patents-platform.svg?component';
import IDIcon from 'assets/icons/idea-database.svg?component';

import { Badge } from '@/components/ui/Badge';

import {
  IDEA_DATABASE_ROLES_NAMES,
  PATENTS_USER_TYPES
} from '@/constants/user';

import { COMPONENT_VARIANTS } from '@/constants/component';
import { POSITION_TOP } from '@/constants/layout';

import type {
  IdeaDatabaseUserRole,
  PatentsPlatformUserType
} from '@/types/users';

import type { BinaryNumber } from '@/types/helpers';

withDefaults(
  defineProps<{
    isIdEnabled: BinaryNumber;
    isPatentPlatformEnabled: BinaryNumber;
    ideaDatabaseRoles: IdeaDatabaseUserRole[] | null;
    patentPlatformRole: PatentsPlatformUserType | null;
  }>(),
  {
    isIdEnabled: 0,
    isPatentPlatformEnabled: 0,
    ideaDatabaseRoles: null,
    patentPlatformRole: null
  }
);

const { t } = useTranslation();

function prepareIdeaDatabaseRoles(
  roles: IdeaDatabaseUserRole[],
  isSingleRole?: boolean
): string {
  const parsedRoles = roles.map(role => t(IDEA_DATABASE_ROLES_NAMES[role]));

  if (isSingleRole) {
    return parsedRoles.splice(0, 1).join();
  }
  return parsedRoles.splice(1).join(', ');
}
</script>

<template>
  <div class="flex flex-col 2xl:flex-row 2xl:flex-wrap gap-3 2xl:gap-6">
    <p v-if="!isPatentPlatformEnabled && !isIdEnabled">
      {{ $t('common.none') }}
    </p>
    <div
      v-if="isPatentPlatformEnabled"
      class="flex items-center gap-1.5 text-xs text-gray-600 break-words"
    >
      <PPIcon class="shrink-0" />
      <span v-if="!patentPlatformRole">
        {{ $t('common.unassigned') }}
      </span>
      <span v-else>{{
        $t(
          PATENTS_USER_TYPES[
            patentPlatformRole as keyof typeof PATENTS_USER_TYPES
          ]
        )
      }}</span>
    </div>

    <div
      v-if="isIdEnabled"
      class="flex items-center gap-1.5 text-xs text-gray-600 break-words"
    >
      <IDIcon class="shrink-0" />
      <span v-if="!ideaDatabaseRoles?.length">
        {{ $t('common.unassigned') }}
      </span>

      <div v-else class="flex gap-1.5 items-center">
        <span>{{ prepareIdeaDatabaseRoles(ideaDatabaseRoles, true) }}</span>
        <Tippy
          :content="prepareIdeaDatabaseRoles(ideaDatabaseRoles)"
          :placement="POSITION_TOP"
        >
          <Badge
            v-if="ideaDatabaseRoles?.length > 1"
            :variant="COMPONENT_VARIANTS.ghost"
            size="medium"
            class="shrink-0 px-2.5"
          >
            <span class="shrink-0">{{
              `+${ideaDatabaseRoles?.length - 1}`
            }}</span>
          </Badge>
        </Tippy>
      </div>
    </div>
  </div>
</template>
