<script
  setup
  lang="ts"
  generic="
    TData extends {
      id: number;
    },
    TValue
  "
>
import { inject, onUpdated, type Ref } from 'vue';
import { useBulkStatus, useSelectedIds } from '@/lib/hooks';

import { ChevronDown } from 'lucide-vue-next';

import {
  FlexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useVueTable
} from '@tanstack/vue-table';

import {
  DataTable,
  TableBody,
  TableCell,
  TableEmpty,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/DataTable';

import {
  TablePagination,
  FilteringPopup,
  FiltersRow
} from '@/components/custom/AdvancedTable';

import { Badge } from '@/components/ui/Badge';
import { Button } from '@/components/ui/Button';
import { ConditionalTooltip } from '@/components/ui/Tooltip';
import { CustomDropdownMenu } from '@/components/custom/CustomDropdownMenu';
import { Input } from '@/components/ui/Input';

import DownloadIcon from 'assets/icons/download-02.svg?component';
import FilterFunnelIcon from 'assets/icons/filter-funnel-02.svg?component';
import SearchIcon from 'assets/icons/search-sm.svg?component';

import { cn, valueUpdater } from '@/lib/utils';

import {
  DEFAULT_COLUMN_MAX_SIZE,
  DEFAULT_ROW_SPAN,
  DISABLED_VIEW_ACTION_LIST
} from '@/constants/table';

import { POSITION_TOP } from '@/constants/layout';
import { COMPONENT_VARIANTS, ELEMENT_SIZES } from '@/constants/component';
import { STATUS_GHOST, STATUS_INFO } from '@/constants/status';
import { BULK_LOADING } from '@/constants/bulkActions';

import type { AdvancedTableProps } from '@/types/components';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

const props = defineProps<AdvancedTableProps<TData, TValue>>();

const activeFilters =
  inject<UseAdvancedTable['activeFilters']>('activeFilters');
const selectedCount =
  inject<UseAdvancedTable['selectedCount']>('selectedCount');
const showFilters = inject<UseAdvancedTable['showFilters']>('showFilters');
const showFilterPopup =
  inject<UseAdvancedTable['showFilterPopup']>('showFilterPopup');
const handleShowFilters =
  inject<UseAdvancedTable['handleShowFilters']>('handleShowFilters');
const handlePopupToggle =
  inject<UseAdvancedTable['handlePopupToggle']>('handlePopupToggle');
const searchQuery = inject<UseAdvancedTable['searchQuery']>('searchQuery');
const isFetching = inject<Ref<boolean>>('isFetching');
const rowSelection = inject<UseAdvancedTable['rowSelection']>('rowSelection');
const isAllSelected =
  inject<UseAdvancedTable['isAllSelected']>('isAllSelected');
const selectedIds = useSelectedIds(true);
const handleIsAllSelected = inject<UseAdvancedTable['handleIsAllSelected']>(
  'handleIsAllSelected'
);
const { bulkActionStatus } = useBulkStatus();

onUpdated(() => {
  if (isFetching?.value) {
    handlePopupToggle && handlePopupToggle(isFetching.value);
  }

  if (isAllSelected?.value && !props.tableData.total && handleIsAllSelected) {
    table.toggleAllRowsSelected(false);
    handleIsAllSelected();
  }
});

const handleFilterButtonClick = () => {
  if (activeFilters && activeFilters.value?.length > 0) {
    handleShowFilters && handleShowFilters();
    return;
  }

  handlePopupToggle && handlePopupToggle();
};

const table = useVueTable({
  initialState: {
    columnPinning: {
      left: ['select'],
      right: ['actions']
    }
  },
  get data() {
    return props.tableData.data;
  },
  get columns() {
    return props.columns;
  },
  state: {
    get rowSelection() {
      return rowSelection?.value;
    }
  },
  defaultColumn: {
    maxSize: DEFAULT_COLUMN_MAX_SIZE
  },
  enableRowSelection: true,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getSortedRowModel: getSortedRowModel(),
  manualPagination: true,
  getRowId(originalRow: TData) {
    return `${originalRow.id}`;
  },
  rowCount: props.tableData.per_page,
  onRowSelectionChange: updaterOrValue =>
    valueUpdater(
      updaterOrValue,
      rowSelection as UseAdvancedTable['rowSelection']
    ),
  debugAll: false
});

function getColumnWidth(width?: number | string) {
  if (typeof width === 'string') {
    return width;
  }

  return width ? `${width}px` : 'auto';
}

const emits = defineEmits<{
  (e: 'rowClick', row: TData): void;
  (e: 'createNewItem'): void;
  (e: 'exportItems', selectedIds: number[]): void;
}>();
</script>

<template>
  <div
    :class="
      cn(
        'min-h-120 grid grid-cols-1 grid-rows-[max-content_1fr_max-content] border border-gray-200 bg-white rounded-md shadow',
        props.class
      )
    "
  >
    <header class="flex flex-col justify-between p-4">
      <div class="flex flex-col lg:flex-row lg:justify-between gap-2.5">
        <div
          :class="
            cn(
              'relative grid grid-cols-1 gap-2.5',
              Object.keys(rowSelection || {}).length
                ? 'grid-rows-2 md:grid-rows-1 md:grid-cols-2 lg:grid-cols-[repeat(2,min-content)]'
                : 'grid-rows-1'
            )
          "
        >
          <Button
            class="w-full gap-1 rounded-lg"
            type="button"
            :is-disabled="isFetching"
            :variant="COMPONENT_VARIANTS.ghost"
            :size="ELEMENT_SIZES.small"
            @click="handleFilterButtonClick"
          >
            <div class="w-4 h-4">
              <FilterFunnelIcon class="w-4 h-4 stroke-gray-800" />
            </div>
            <span class="inline-block min-w-8">{{ $t('common.filter') }}</span>
            <Badge
              :variant="
                activeFilters && activeFilters.length > 0
                  ? STATUS_INFO
                  : STATUS_GHOST
              "
              is-counter
            >
              <span>{{ activeFilters?.length || 0 }}</span>
            </Badge>
            <ChevronDown class="w-4 h-4 text-gray-500" />
          </Button>
          <div class="absolute z-20 w-min h-min top-14 inset-0 mx-auto lg:m-0">
            <Transition
              enter-from-class="opacity-0"
              leave-to-class="opacity-0"
              enter-active-class="transition duration-300"
              leave-active-class="transition duration-300"
            >
              <FilteringPopup
                v-if="!isFetching && !activeFilters?.length && showFilterPopup"
                :active-filters="activeFilters || []"
                @close-popup="handlePopupToggle"
              />
            </Transition>
          </div>

          <CustomDropdownMenu
            v-if="!isFetching && Object.keys(rowSelection || {}).length"
          >
            <template #trigger>
              <Button
                class="w-full rounded-lg"
                type="button"
                :is-disabled="bulkActionStatus === BULK_LOADING"
                :variant="COMPONENT_VARIANTS.ghost"
                :size="ELEMENT_SIZES.small"
              >
                <span>{{ $t('advancedTable.bulkSelect') }}</span>
                <Badge
                  :variant="isFetching ? STATUS_GHOST : STATUS_INFO"
                  is-counter
                >
                  <span>{{ selectedCount }}</span>
                </Badge>
                <ChevronDown class="w-4 h-4 text-gray-500" />
              </Button>
            </template>

            <template #menu-options>
              <slot name="bulk-actions-options"></slot>
            </template>
          </CustomDropdownMenu>
        </div>

        <div
          :class="
            cn(
              'grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-[1fr_min-content] gap-2.5',
              isExportAvailable &&
                'grid-cols-2 md:grid-cols-[1fr_min-content_min-content]',
              isExportAvailable &&
                isImportAvailable &&
                'grid-cols-[1fr_max-content]',
              !isExportAvailable && !isImportAvailable && 'gap-x-0 md:gap-2.5'
            )
          "
        >
          <div class="col-span-2 md:col-auto lg:max-w-60">
            <Input
              :icon-component="SearchIcon"
              :is-disabled="isFetching"
              :model-value="searchQuery"
              class="h-11 w-full text-sm"
              :placeholder="$t('manageAccounts.search')"
              can-be-cleared
              can-be-refocused
              @update:model-value="onSearch($event.toString())"
            />
          </div>
          <ConditionalTooltip
            v-if="isExportAvailable"
            :content="$t('common.select_for_export')"
            :should-show="isFetching || !Object.keys(rowSelection || {}).length"
            :placement="POSITION_TOP"
          >
            <Button
              type="button"
              :variant="COMPONENT_VARIANTS.ghost"
              :is-disabled="
                isFetching || !Object.keys(rowSelection || {}).length
              "
              :size="ELEMENT_SIZES.small"
              :class="cn('px-4 min-w-26 w-full grow-[2] rounded-lg md:w-max')"
              @click="emits('exportItems', selectedIds as number[])"
            >
              <div class="w-3.5">
                <DownloadIcon class="h-3.5 stroke-gray-800" />
              </div>
              <span class="inline-block min-w-10 w-max">{{
                $t('common.export')
              }}</span>
            </Button>
          </ConditionalTooltip>

          <div>
            <CustomDropdownMenu v-if="isImportAvailable">
              <template #trigger>
                <Button
                  type="button"
                  :variant="COMPONENT_VARIANTS.info"
                  :is-disabled="isFetching"
                  :size="ELEMENT_SIZES.small"
                  :class="
                    cn(
                      'min-w-32 w-full px-4 pr-3 grow-[2] rounded-lg md:w-max',
                      !isExportAvailable && 'col-span-2 md:col-span-1'
                    )
                  "
                >
                  <span>{{ $t(props.createDataTranslation) }}</span>
                  <div class="h-full w-px bg-white/50" />
                  <ChevronDown class="w-4 h-4 text-white" />
                </Button>
              </template>

              <template #menu-options>
                <slot name="create-actions"></slot>
              </template>
            </CustomDropdownMenu>

            <Button
              v-else
              type="button"
              :is-disabled="isFetching"
              :variant="COMPONENT_VARIANTS.info"
              :size="ELEMENT_SIZES.small"
              :class="
                cn(
                  'min-w-32 w-full px-4 grow-[2] rounded-lg md:w-max',
                  !isExportAvailable && 'col-span-2 md:col-span-1'
                )
              "
              @click="emits('createNewItem')"
            >
              <span>{{ $t(props.createDataTranslation) }}</span>
            </Button>
          </div>
        </div>
      </div>
      <Transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-active-class="transition duration-300"
        leave-active-class="transition duration-300"
      >
        <FiltersRow v-if="showFilters" :is-advanced-table="true" />
      </Transition>
    </header>

    <section class="w-full overflow-auto">
      <DataTable :class="cn(!tableData.data.length && 'h-full min-h-100')">
        <TableHeader>
          <TableRow
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <TableHead
              v-for="(header, index) in headerGroup.headers"
              :key="header.id"
              :class="
                cn(
                  {
                    'sticky bg-slate-50': header.column.getIsPinned()
                  },
                  header.column.getIsPinned() === 'left' ? 'left-0' : 'right-0'
                )
              "
              :style="{
                width: getColumnWidth(header.column.columnDef.size)
              }"
              :is-last-column="index === headerGroup.headers.length - 1"
            >
              <FlexRender
                v-if="!header.isPlaceholder"
                :render="header.column.columnDef.header"
                :props="header.getContext()"
              />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <template v-if="isFetching || tableData.total === 0">
            <TableEmpty
              :colspan="table.getAllColumns().length"
              :rowspan="table.getRowModel().rows?.length || DEFAULT_ROW_SPAN"
            >
              <div
                v-if="
                  !isFetching &&
                  !searchQuery?.length &&
                  !activeFilters?.length &&
                  tableData.total === 0
                "
              >
                <slot name="empty-page"></slot>
              </div>
              <p
                v-else
                class="w-max mx-auto text-gray-400 text-2xl md:text-3xl font-semibold"
              >
                <span v-if="isFetching">{{
                  `${$t('common.loading')}...`
                }}</span>
                <span v-if="!isFetching && tableData.total === 0">{{
                  $t('common.noResults')
                }}</span>
              </p>
            </TableEmpty>
          </template>

          <template v-else>
            <!-- TODO: Add Suspense component for custom skeleton elements -->
            <TableRow
              v-for="row in table.getRowModel().rows"
              :key="row.id"
              :data-state="
                isAllSelected || row.getIsSelected() ? 'selected' : undefined
              "
            >
              <TableCell
                v-for="cell in row.getVisibleCells()"
                :key="cell.id"
                :style="{
                  width: getColumnWidth(cell.column.columnDef.size)
                }"
                :class="
                  cn(
                    {
                      'sticky bg-white/95': cell.column.getIsPinned()
                    },
                    cell.column.getIsPinned() === 'left' ? 'left-0' : 'right-0',
                    !DISABLED_VIEW_ACTION_LIST.includes(
                      cell?.column
                        .id as (typeof DISABLED_VIEW_ACTION_LIST)[number]
                    ) && 'hover:cursor-pointer'
                  )
                "
                @click="
                  !DISABLED_VIEW_ACTION_LIST.includes(
                    cell?.column
                      ?.id as (typeof DISABLED_VIEW_ACTION_LIST)[number]
                  ) && emits('rowClick', cell.row.original)
                "
              >
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"
                />
              </TableCell>
            </TableRow>
          </template>
        </TableBody>
      </DataTable>
    </section>

    <TablePagination
      :table="table"
      :total="tableData.total"
      :is-fetching="isFetching"
    />
  </div>
</template>
