<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import {
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  HelpCircle
} from 'lucide-vue-next';

import {
  alertVariants,
  type AlertVariants
} from '@/components/custom/CustomAlert/index';

import { Heading, StaticIcon } from '@/components/custom';
import { Alert } from '@/components/ui/Alert';

import { cn } from '@/lib/utils';

import {
  STATUS_DEFAULT,
  STATUS_DESTRUCTIVE,
  STATUS_GHOST,
  STATUS_INFO,
  STATUS_SUCCESS,
  STATUS_WARNING
} from '@/constants/status';

import type { ComponentVariants } from '@/types/components';

const props = withDefaults(
  defineProps<{
    variant?: AlertVariants['variant'];
    class?: HTMLAttributes['class'];
    heading?: string;
  }>(),
  {
    variant: STATUS_DEFAULT,
    class: null,
    heading: ''
  }
);

const checkIfHasCustomChildren = (variant: ComponentVariants) =>
  variant !== STATUS_DEFAULT && variant !== STATUS_GHOST;
</script>

<template>
  <Alert
    :class="cn(alertVariants({ variant }), 'flex gap-2.5', props.class)"
    :variant="STATUS_DEFAULT"
  >
    <StaticIcon
      v-if="variant && checkIfHasCustomChildren(variant)"
      :variant="variant as ComponentVariants"
      class="bg-transparent size-6 dark:bg-transparent dark:border-0"
    >
      <AlertOctagon
        v-if="variant === STATUS_DESTRUCTIVE"
        class="shrink-0"
        stroke-width="{1}"
      />
      <AlertTriangle
        v-if="variant === STATUS_WARNING"
        class="shrink-0"
        stroke-width="{1}"
      />
      <CheckCircle
        v-if="variant === STATUS_SUCCESS"
        class="shrink-0"
        stroke-width="{1}"
      />
      <HelpCircle
        v-if="variant === STATUS_INFO"
        class="shrink-0"
        stroke-width="{1}"
      />
    </StaticIcon>
    <div class="w-full">
      <Heading
        v-if="heading"
        class="block text-base font-semibold text-inherit"
      >
        {{ heading }}
      </Heading>
      <slot></slot>
    </div>
  </Alert>
</template>
