<script setup lang="ts">
import { computed, inject, provide } from 'vue';
import { AxiosError } from 'axios';

import { useFileDialog } from '@vueuse/core';
import { useTranslation } from 'i18next-vue';
import { useToast } from 'vue-toastification';
import { useUsers } from '@/lib/hooks';

import { exportUsersToExcel, uploadUsersCSV } from '@/api/user';
import { parseError } from '@/lib/helpers';

import { UserPlus } from 'lucide-vue-next';
import ImportIcon from 'assets/icons/import-02.svg?component';

import { BulkAccountsActions } from '@/views/Accounts/components';

import { AdvancedTable } from '@/components/custom/AdvancedTable';
import { CustomDropdownMenuItem } from '@/components/custom/CustomDropdownMenu';
import { Heading } from '@/components/custom';
import { TablePlaceholder } from '@/components/ui/Table';

import { accountsColumns } from '@/views/Accounts/components/AccountsColumns';

import {
  openAccountCreateModal,
  openViewAccountModal
} from '@/lib/modals/accountsModals';

import type { UseModal } from '@/types/modals';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

import { HTTP_CODES } from '@/constants/api';

const sortedColumns = inject(
  'sortedColumns'
) as UseAdvancedTable['sortedColumns'];
const isAllSelected = inject(
  'isAllSelected'
) as UseAdvancedTable['isAllSelected'];
const handleSearchQueryChange = inject(
  'handleSearchQueryChange'
) as UseAdvancedTable['handleSearchQueryChange'];
const handleSortingUpdate = inject(
  'handleSortingUpdate'
) as UseAdvancedTable['handleSortingUpdate'];
const handleIsAllSelected = inject(
  'handleIsAllSelected'
) as UseAdvancedTable['handleIsAllSelected'];

const openModal = inject('openModal') as UseModal['openModal'];

const toast = useToast();

const { t } = useTranslation();

const { files, open, onChange, reset } = useFileDialog({
  accept: '.csv', // Restricts file types
  multiple: false
});

onChange(async () => {
  const selectedFile: File | undefined = files.value?.[0];

  if (selectedFile) {
    const response = await uploadUsersCSV(selectedFile);

    if (
      response instanceof AxiosError &&
      response.response?.status &&
      Object.values(HTTP_CODES).includes(
        response.response.status as (typeof HTTP_CODES)[keyof typeof HTTP_CODES]
      )
    ) {
      toast.error(
        t('validation.uploaded', {
          attribute: t('common.file')
        })
      );
    } else {
      toast.success(t('manageAccounts.import_started'));
    }

    reset();
  }
});

const { users, isFetching } = useUsers();

provide('isFetching', isFetching);

const columns = computed(() =>
  accountsColumns({
    isFetching,
    total: users.value.total,
    isAllSelected,
    handleSortingUpdate,
    handleIsAllSelected,
    sortedColumns,
    t
  })
);

async function handleExportUsersToExcel(user_ids: number[]) {
  const response = await exportUsersToExcel({ user_ids });

  if (response instanceof AxiosError) {
    toast.error(parseError(response));
  } else {
    toast.success(response.message);
  }
}
</script>

<template>
  <Heading size="h3" class="font-medium md:text-3xl mb-7 min-h-9 h-max">
    {{ $t('manageAccounts.manage_accounts') }}
  </Heading>
  <AdvancedTable
    class="lg:h-[calc(100dvh-12rem)]"
    :is-export-available="true"
    :is-import-available="true"
    create-data-translation="manageAccounts.create"
    :columns="columns"
    :table-data="users"
    :total="users.total"
    :on-search="handleSearchQueryChange"
    @row-click="openViewAccountModal(openModal, $event)"
    @create-new-item="openAccountCreateModal(openModal)"
    @export-items="handleExportUsersToExcel"
    @import-items="open"
  >
    <template #bulk-actions-options>
      <BulkAccountsActions />
    </template>

    <template #create-actions>
      <CustomDropdownMenuItem
        :item-label="$t('manageAccounts.create_new_account')"
        :item-icon="UserPlus"
        item-class="h-3.5 stroke-gray-800"
        class="pr-1"
        @click="openAccountCreateModal(openModal)"
      />

      <CustomDropdownMenuItem
        :item-label="$t('manageAccounts.import_data')"
        :item-icon="ImportIcon"
        item-class="h-3.5 stroke-1 stroke-gray-800"
        class="pr-1"
        @click="open"
      />
    </template>

    <template #empty-page>
      <TablePlaceholder
        create-new-item-text="manageAccounts.create_new_account"
        not-found-text="manageAccounts.no_accounts_found"
        add-first-text="manageAccounts.add_first_account"
        @create-new-item="openAccountCreateModal(openModal)"
      />
    </template>
  </AdvancedTable>
</template>
