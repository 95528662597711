import { inject } from 'vue';
import { useTableData } from '@/lib/hooks';

import { fetchGroups } from '@/api/group';

import { GROUPS_API } from '@/constants/api';

import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';
import type { ListGroup } from '@/types/groups';

function useGroups() {
  const apiParams = inject<UseAdvancedTable['apiParams']>('apiParams');

  const { data, isFetching, isError, isPlaceholderData } =
    useTableData<ListGroup>({
      apiParams,
      apiKey: GROUPS_API,
      fetchApiData: fetchGroups
    });

  return { groups: data, isFetching, isError, isPlaceholderData };
}

export default useGroups;
