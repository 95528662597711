<script setup lang="ts">
import { useLoadingData, useSessions } from '@/lib/hooks';

import { SessionInfoBlock } from '@/views/Settings/components';
import { LoadingInfoBlock } from '@/views/Common';

const { sessions, isFetching } = useSessions();
const loadingSessions = useLoadingData();
</script>

<template>
  <div class="flex flex-col flex-start gap-5">
    <div
      class="self-stretch p-3 justify-start items-start gap-2.5 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 max-h-70 overflow-y-auto"
    >
      <template v-if="isFetching">
        <LoadingInfoBlock
          v-for="session in loadingSessions"
          :key="session"
          :lines="3"
        />
      </template>

      <SessionInfoBlock
        v-for="session in sessions"
        v-else
        :key="session.id"
        :session="session"
        session-details-class="flex flex-col gap-1 w-3/4"
        is-small
        truncate
        class="bg-white px-2.5 py-2 h-18 items-center border border-gray-200 shadow rounded-md"
      />
    </div>
  </div>
</template>
