import { computed } from 'vue';
import { useSelectedIds } from '@/lib/hooks';
import { useQuery } from '@tanstack/vue-query';

import { fetchTokensSimplified } from '@/api/token';

import { TOKENS_SIMPLIFIED_API } from '@/constants/api';

import type { TokenSimplified } from '@/types/tokens';

function useTokensSimplified(useParams = false) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [TOKENS_SIMPLIFIED_API, selectedIds],
    queryFn: () =>
      fetchTokensSimplified({
        token_ids: selectedIds.value
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedTokens = computed<TokenSimplified[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedTokens, isError, isFetching };
}

export default useTokensSimplified;
