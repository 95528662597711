import request from '@/api/base';

import { parseIdeaDatabaseRoles } from '@/lib/helpers';

import type {
  OrganizationUserPreset,
  OrganizationUserPresetSimplified
} from '@/types/organizationUserPresets';

import type {
  ApiErrorResponse,
  ApiResponse,
  ApiSimpleResponse
} from '@/types/api';

import type {
  CreateOrganizationUserPresetParams,
  UpdateOrganizationUserPresetParams
} from '@/api/organizationUserPreset/types';

export async function fetchOrganizationUserPresets() {
  return await request
    .get<
      ApiResponse<OrganizationUserPresetSimplified[]>
    >(`${import.meta.env.VITE_API_VERSION}/organization-user-presets`)
    .then(res => {
      return {
        ...res.data,
        data: [
          ...(res.data.data.map(preset => ({
            ...preset,
            ideaDatabaseRoles: parseIdeaDatabaseRoles(preset.ideaDatabaseRoles)
          })) as OrganizationUserPresetSimplified[])
        ]
      };
    });
}

export async function fetchSingleOrganizationUserPreset(presetId: number) {
  return await request
    .get<
      ApiResponse<OrganizationUserPreset>
    >(`${import.meta.env.VITE_API_VERSION}/organization-user-presets/${presetId}`)
    .then(res => {
      return {
        ...res.data,
        data: {
          ...res.data.data,
          ideaDatabaseRoles: parseIdeaDatabaseRoles(
            res.data.data.ideaDatabaseRoles
          )
        } as OrganizationUserPreset
      };
    });
}

export async function createOrganizationUserPreset(
  preset: CreateOrganizationUserPresetParams
) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization-user-presets`,
      preset
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function deleteOrganizationUserPreset(presetId: number) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization-user-presets/${presetId}`
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function updateOrganizationUserPreset(
  presetId: number,
  presetParams?: UpdateOrganizationUserPresetParams
) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization-user-presets/${presetId}`,
      presetParams
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}
