import { inject, watch } from 'vue';

import { openInvalidDataModal } from '@/lib/modals';

import type { AllowedApiKeys } from '@/constants/api';
import type { UseModal } from '@/types/modals';
import type { Ref } from 'vue';
import type { ParseKeys } from 'i18next';

function useDataError(
  isError: Ref<boolean>,
  {
    ignoreError = false,
    message
  }: {
    ignoreError?: boolean;
    message?: ParseKeys;
  } = {}
) {
  const openModal = inject<UseModal['openModal']>('openModal');
  const initialModalState =
    inject<UseModal['initialModalState']>('initialModalState');

  watch(isError, () => {
    if (!ignoreError && isError.value && openModal && initialModalState) {
      openInvalidDataModal(
        openModal,
        initialModalState.value.invalidateQueryKey as AllowedApiKeys,
        message
      );
    }
  });
}

export default useDataError;
