export const PASSWORD_STRENGTH_MESSAGES = [
  'userProfile.empty',
  'userProfile.very_weak',
  'userProfile.weak',
  'userProfile.medium',
  'userProfile.strong',
  'userProfile.very_strong'
] as const;

export const PASSWORD_MIN_LENGTH = 12;
export const PASSWORD_MAX_LENGTH = 255;
