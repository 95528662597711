import { h } from 'vue';

import { GroupUsersList, GroupsActions } from '@/views/Groups/components';

import { Button } from '@/components/ui/Button';
import SelectColumn from '@/components/custom/AdvancedTable/SelectColumn';

import { getColumnSorting, getSortingIcon } from '@/lib/sorting';

import { BUTTON_VARIANTS } from '@/constants/component';

import type { ColumnDef } from '@tanstack/vue-table';
import type { ListGroup } from '@/types/groups';
import type { ColumnsParams } from '@/types/table';

export const groupColumns = ({
  isFetching,
  isAllSelected,
  sortedColumns,
  handleSortingUpdate,
  handleIsAllSelected,
  t,
  total
}: ColumnsParams): ColumnDef<ListGroup>[] => [
  SelectColumn({
    isAllSelected,
    isFetching,
    handleIsAllSelected,
    t
  }),
  {
    accessorKey: 'name',
    header: ({ header }) => {
      return h(
        Button,
        {
          class: 'text-xs font-semibold text-inherit uppercase px-0 py-0 h-min',
          isDisabled: isFetching.value || !total,
          variant: BUTTON_VARIANTS.icon,
          title: t('manageGroups.sortByName'),
          onClick: () => {
            handleSortingUpdate(getColumnSorting(sortedColumns, header.id));
          }
        },
        () => [
          t('common.name'),
          h(getSortingIcon(sortedColumns, header.id), {
            class: 'text-gray-800 h-4 w-4'
          })
        ]
      );
    },
    cell: ({ row }) =>
      h(
        'p',
        {
          class: 'break-word'
        },
        row.getValue('name')
      )
  },
  {
    accessorKey: 'description',
    header: ({ header }) => {
      return h(
        Button,
        {
          class: 'text-xs font-semibold text-inherit uppercase px-0 py-0 h-min',
          isDisabled: isFetching.value || !total,
          variant: BUTTON_VARIANTS.icon,
          title: t('manageGroups.sortByDescription'),
          onClick: () => {
            handleSortingUpdate(getColumnSorting(sortedColumns, header.id));
          }
        },
        () => [
          t('manageGroups.description'),
          h(getSortingIcon(sortedColumns, header.id), {
            class: 'text-gray-800 h-4 w-4'
          })
        ]
      );
    },
    cell: ({ row }) =>
      h(
        'p',
        {
          class: 'break-word'
        },
        row.getValue('description') || '-'
      )
  },
  {
    accessorKey: 'users',
    size: '30%' as unknown as number,
    header: () => h('div', t('manageGroups.group_members')),
    cell: ({ row }) => {
      const users = (row.getValue('users') as string[]) || [];

      return h(GroupUsersList, { users: users });
    }
  },
  {
    size: '10%' as unknown as number,
    accessorKey: 'actions',
    header: () => h('p', t('common.listActions')),
    cell: ({ row }) => h(GroupsActions, { group: row.original })
  }
];
