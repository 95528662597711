import { t } from '@/i18next';

import {
  LOWER_CASE_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPER_CASE_REGEX
} from '@/constants/regex';

import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from '@/constants/validation';

import type {
  AllowedValidationKeys,
  PasswordStrength,
  ValidationData
} from '@/types/validation';

export function removeNotFoundItems<
  T extends {
    id: number;
  }
>(item_ids: number[] | ReadonlyArray<number>, simplifiedData: T[]) {
  return item_ids.filter(id => simplifiedData.find(item => item.id === id));
}

export function createValidationData<T extends ValidationData>(
  items_ids: number[] | ReadonlyArray<number>,
  validationData: T[],
  dataKey: AllowedValidationKeys = 'user_ids'
): Partial<Record<AllowedValidationKeys, string[]>> {
  return {
    [dataKey]: items_ids.map(
      itemId =>
        (validationData.find(item => item.id === itemId)?.[
          dataKey === 'user_ids' ? 'fullName' : 'name'
        ] as string) || t('validation.not_found')
    )
  };
}

export function validatePassword(
  value: string,
  createObject?: boolean
): boolean | PasswordStrength {
  if (createObject) {
    return {
      minLength: value.length >= PASSWORD_MIN_LENGTH,
      maxLength: value.length <= PASSWORD_MAX_LENGTH,
      uppercase: UPPER_CASE_REGEX.test(value),
      lowercase: LOWER_CASE_REGEX.test(value),
      number: NUMBER_REGEX.test(value),
      special: SPECIAL_CHARACTER_REGEX.test(value)
    };
  }

  return (
    value.length >= PASSWORD_MIN_LENGTH &&
    value.length <= PASSWORD_MAX_LENGTH &&
    UPPER_CASE_REGEX.test(value) &&
    LOWER_CASE_REGEX.test(value) &&
    NUMBER_REGEX.test(value) &&
    SPECIAL_CHARACTER_REGEX.test(value)
  );
}
