import { API_DISABLED, USERS_API } from '@/constants/api';

export const BULK_NONE = 'BULK_NONE';
export const BULK_LOADING = 'BULK_LOADING';
export const BULK_SUCCESS = 'BULK_SUCCESS';
export const BULK_ERROR = 'BULK_ERROR';
export const BULK_WARNING = 'BULK_WARNING';

export const DEFAULT_INVALIDATE_KEYS = [API_DISABLED] as const;

export const TOAST_INJECT_BULK_MESSAGES = [
  'excel_export.exported_successfully'
] as const;

export type BulkStatus =
  | typeof BULK_NONE
  | typeof BULK_LOADING
  | typeof BULK_SUCCESS
  | typeof BULK_ERROR
  | typeof BULK_WARNING;

export const INVALIDATE_MESSAGES_MAP = {
  'manageAccounts.import_finished_warning': USERS_API,
  'manageAccounts.import_finished_successfully': USERS_API
} as const;
