<script setup lang="ts" generic="T">
import { computed } from 'vue';

import { ProductAccessLabel } from '@/views/Accounts/components';
import { TableCell } from '@/components/ui/Table';
import { SingleCell } from '@/components/custom/CustomTable';

import { cn } from '@/lib/utils';

import {
  type CustomTableColumn,
  PRODUCT_ACCESS_COLUMN,
  renderRow
} from '@/constants/table';

import type { OrganizationUserPresetSimplified } from '@/types/organizationUserPresets';

const props = defineProps<{
  row: T;
  isModal?: boolean;
  updatedRow?: T;
  column: CustomTableColumn;
}>();

const isColumnChanged = computed(() => {
  return (
    props.updatedRow &&
    props.updatedRow[props.column.value.key as keyof T] !==
      props.row[props.column.value.key as keyof T]
  );
});
</script>

<template>
  <TableCell
    :class="
      cn(
        'break-all px-5 py-3 text-sm',
        column.isExtended ? 'min-w-90' : 'min-w-56'
      )
    "
  >
    <ProductAccessLabel
      v-if="!isModal && column.value.key === PRODUCT_ACCESS_COLUMN"
      :idea-database-roles="
        (row as OrganizationUserPresetSimplified).ideaDatabaseRoles
      "
      :patent-platform-role="
        (row as OrganizationUserPresetSimplified).patentsUserType
      "
      :is-id-enabled="
        (row as OrganizationUserPresetSimplified).ideaDatabaseRoles?.length
          ? 1
          : 0
      "
      :is-patent-platform-enabled="
        (row as OrganizationUserPresetSimplified).patentsUserType ? 1 : 0
      "
    />
    <span v-else-if="!isColumnChanged">{{
      renderRow((row as T)[column.value.key as keyof T])
    }}</span>
    <div v-else>
      <SingleCell
        :cell="row[column.value.key as keyof typeof row]"
        :updated-cell="
          (updatedRow as T)[column.value.key as keyof typeof updatedRow]
        "
        :column-key="column.value.key"
        class="text-red-500 line-through"
      />
      <span> -> </span>
      <SingleCell
        :cell="(updatedRow as T)[column.value.key as keyof typeof updatedRow]"
        :updated-cell="row[column.value.key as keyof typeof row]"
        :column-key="column.value.key"
        class="text-green-500"
      />
    </div>
  </TableCell>
</template>
