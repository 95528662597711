import Rollbar, { type Configuration, type LogArgument } from 'rollbar';
import type { App } from 'vue';

const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  scrubTelemetryInputs: true,
  autoInstrument: {
    log: import.meta.env.PROD
  },
  payload: {
    environment: import.meta.env.VITE_APP_ENV,
    client: {
      javascript: {
        code_version: import.meta.env.GIT_CODE_VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  }
};

const rollbar = new Rollbar(rollbarConfig);

export default {
  install(app: App<Element>) {
    app.config.errorHandler = (error, vm, info) => {
      app.config.globalProperties.$rollbar = rollbar;
      rollbar.error(error as LogArgument, { vueComponent: vm, info });
      console.error({
        error,
        ...(import.meta.env.DEV && { vm, info })
      });
    };
    app.provide('rollbar', rollbar);
  }
};
