import { createApp } from 'vue';

import 'preline';
import { VueQueryPlugin } from '@tanstack/vue-query';
import VueTippy from 'vue-tippy';
import I18NextVue from 'i18next-vue';
import RollbarPlugin from '@/rollbar';
import VueApexCharts from 'vue3-apexcharts';
import Toast from 'vue-toastification';

import Accounts from '@/views/Accounts';
import Dashboard from '@/views/Dashboard';
import ErrorPage from '@/views/ErrorPage';
import ExportDownload from '@/views/ExportDownload';
import Groups from '@/views/Groups';
import Organization from '@/views/Organization';
import Settings from '@/views/Settings';
import Sidebar from '@/layouts/Sidebar';
import Tokens from '@/views/Tokens';

import i18next from '@/i18next';

// On test, Vite automatically injects CSS.
// In production, a dedicated stylesheet is generated that the @vite directive will load from the manifest.
import 'vue-toastification/dist/index.css';
import '../css/app.css';
import 'tippy.js/dist/tippy.css';

import { vueQueryPluginOptions } from '@/constants/config';
import { TOAST_OPTIONS } from '@/constants/toasts';

const app = createApp({});

app
  .component('Sidebar', Sidebar)
  .component('Accounts', Accounts)
  .component('ErrorPage', ErrorPage)
  .component('ExportDownload', ExportDownload)
  .component('Dashboard', Dashboard)
  .component('Groups', Groups)
  .component('Organization', Organization)
  .component('Settings', Settings)
  .component('Tokens', Tokens)
  .use(VueApexCharts)
  .use(VueTippy, {
    defaultProps: {
      allowHTML: true
    }
  })
  .use(I18NextVue, { i18next })
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(RollbarPlugin)
  .use(Toast, TOAST_OPTIONS)
  .mount('#app');
