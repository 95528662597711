<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import {
  ScrollAreaCorner,
  ScrollAreaRoot,
  ScrollAreaViewport,
  type ScrollAreaRootProps
} from 'radix-vue';

import { ScrollBar } from '@/components/ui/ScrollArea';
import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  ScrollAreaRootProps & { class?: HTMLAttributes['class'] }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <ScrollAreaRoot
    v-bind="delegatedProps"
    :class="cn('relative overflow-hidden', props.class)"
  >
    <ScrollAreaViewport class="h-full w-full rounded-[inherit]">
      <slot />
    </ScrollAreaViewport>
    <ScrollBar orientation="vertical" />
    <ScrollBar orientation="horizontal" />
    <ScrollAreaCorner />
  </ScrollAreaRoot>
</template>
