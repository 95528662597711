export const LOGIN_EMAIL = 0;
export const LOGIN_SSO = 1;

export const LOGIN_TYPES_NAMES = {
  TYPE_EMAIL: 'E-mail',
  TYPE_SSO: 'SSO'
} as const;

export const PATENTS_ADMIN = 1;
export const PATENTS_FULL_USER = 2;
export const PATENTS_LIMITED_USER = 3;
export const PATENTS_REVIEWER = 4;
export const PATENTS_EXTERNAL = 5;

export const PATENTS_USER_TYPES = {
  [PATENTS_ADMIN]: 'manageAccounts.Administrator',
  [PATENTS_FULL_USER]: 'manageAccounts.Full Access User',
  [PATENTS_LIMITED_USER]: 'manageAccounts.Limited Access User',
  [PATENTS_REVIEWER]: 'manageAccounts.Reviewer',
  [PATENTS_EXTERNAL]: 'manageAccounts.IamIP Viewer'
} as const;

export const PATENTS_USER_TYPES_NAMES = {
  admin: 'Administrator',
  full_user: 'Full Access User',
  limited_user: 'Limited Access User',
  reviewer: 'Reviewer',
  external: 'IamIP Viewer'
} as const;

export const ROLE_USER_ADMINISTRATION = 1;
export const ROLE_PATENT_SEARCH = 2;
export const ROLE_SEARCH_ALERTS = 3;
export const ROLE_PORTFOLIOS = 4;
export const ROLE_TASKS = 5;
export const ROLE_ANALYSIS = 6;
export const ROLE_UPLOAD = 7;
export const ROLE_COMMENTS_REVIEWS = 8;
export const ROLE_MENUS_TAGS_RANKINGS_MANAGEMENT = 9;
export const ROLE_REPORTS = 10;

export const PATENTS_REVIEWER_ROLES = [
  ROLE_TASKS,
  ROLE_COMMENTS_REVIEWS
] as const;

export const PATENTS_LIMITED_USER_ROLES = [
  ...PATENTS_REVIEWER_ROLES,
  ROLE_PORTFOLIOS
] as const;

export const PATENTS_FULL_USER_ROLES = [
  ...PATENTS_LIMITED_USER_ROLES,
  ROLE_PATENT_SEARCH,
  ROLE_SEARCH_ALERTS,
  ROLE_ANALYSIS,
  ROLE_UPLOAD,
  ROLE_MENUS_TAGS_RANKINGS_MANAGEMENT
] as const;

export const PATENTS_ADMIN_ROLES = [
  ...PATENTS_FULL_USER_ROLES,
  ROLE_USER_ADMINISTRATION,
  ROLE_REPORTS
] as const;

export const PATENTS_ROLES = {
  [PATENTS_ADMIN]: PATENTS_ADMIN_ROLES,
  [PATENTS_FULL_USER]: PATENTS_FULL_USER_ROLES,
  [PATENTS_LIMITED_USER]: PATENTS_LIMITED_USER_ROLES,
  [PATENTS_REVIEWER]: PATENTS_REVIEWER_ROLES,
  [PATENTS_EXTERNAL]: PATENTS_REVIEWER_ROLES
} as const;

export const PATENTS_USER_ROLE_NAMES = {
  [ROLE_USER_ADMINISTRATION]: 'manageAccounts.User administration',
  [ROLE_PATENT_SEARCH]: 'manageAccounts.Patent search',
  [ROLE_SEARCH_ALERTS]: 'manageAccounts.Monitorings',
  [ROLE_PORTFOLIOS]: 'manageAccounts.Portfolios',
  [ROLE_TASKS]: 'manageAccounts.Tasks',
  [ROLE_ANALYSIS]: 'manageAccounts.Analysis module',
  [ROLE_UPLOAD]: 'manageAccounts.Upload',
  [ROLE_COMMENTS_REVIEWS]: 'manageAccounts.Comments reviews',
  [ROLE_MENUS_TAGS_RANKINGS_MANAGEMENT]:
    'manageAccounts.Tags & rankings management',
  [ROLE_REPORTS]: 'manageAccounts.Reporting'
} as const;

export const IDEA_DATABASE_REVIEW_COORDINATOR = 'review_coordinator';
export const IDEA_DATABASE_EXPERT = 'expert';
export const IDEA_DATABASE_DECISION_MAKER = 'decision_maker';
export const IDEA_DATABASE_REVIEW_MANAGER = 'review_manager';
export const IDEA_DATABASE_INVENTOR = 'inventor';
export const IDEA_DATABASE_FORMS_ADMIN = 'admin';
export const IDEA_DATABASE_USER_ADMIN = 'users_admin';
export const IDEA_DATABASE_SUPERVISOR = 'supervisor';

export const IDEA_DATABASE_ROLES_NAMES = {
  [IDEA_DATABASE_USER_ADMIN]: 'manageAccounts.User Administrator',
  [IDEA_DATABASE_FORMS_ADMIN]: 'manageAccounts.Forms Administrator',
  [IDEA_DATABASE_INVENTOR]: 'manageAccounts.Inventor',
  [IDEA_DATABASE_REVIEW_MANAGER]: 'manageAccounts.Review Manager',
  [IDEA_DATABASE_DECISION_MAKER]: 'manageAccounts.Decision Maker',
  [IDEA_DATABASE_EXPERT]: 'manageAccounts.Expert',
  [IDEA_DATABASE_REVIEW_COORDINATOR]: 'manageAccounts.Review Coordinator',
  [IDEA_DATABASE_SUPERVISOR]: 'manageAccounts.Supervisor'
} as const;

export const IDEA_DATABASE_ROUTE_ROLES = () =>
  [
    'Forms Administrator',
    'Inventor',
    'Review Manager',
    'Decision Maker',
    'Expert',
    'Review Coordinator',
    'Supervisor'
  ] as const;

export type PatentsPlatformUserRoleName =
  (typeof PATENTS_USER_TYPES_NAMES)[keyof typeof PATENTS_USER_TYPES_NAMES];

export type LoginTypes =
  (typeof LOGIN_TYPES_NAMES)[keyof typeof LOGIN_TYPES_NAMES];

export const USER_PROFILE_DETAILS_LABELS = {
  createdAt: 'manageAccounts.joined_on',
  lastLogin: 'manageAccounts.last_login',
  loginType: 'modals.login_method'
} as const;

export const USER_PROFILE_PRODUCT_ACCESS_LABELS = {
  ideaDatabaseRoles: 'manageAccounts.idea_database_roles',
  patentsPlatformUserType: 'manageAccounts.patents_platform_user_type'
} as const;
