<script setup lang="ts">
import { inject, type Ref } from 'vue';

import { CustomAlert } from '@/components/custom/CustomAlert';
import { Heading } from '@/components/custom';
import { IdeaDatabaseUsers } from '@/components/custom/Charts';
import { StatisticCard } from '@/views/Dashboard/components';

import { STATUS_INFO } from '@/constants/status';

import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>

<template>
  <div class="flex gap-6 flex-col" data-testid="idea-database-statistics">
    <div class="flex flex-col gap-2.5">
      <Heading
        size="h4"
        class="text-lg md:text-xl"
        data-testid="idea-database-statistics-title"
      >
        {{ $t('organizationDashboard.inventions_user_statistics') }}
      </Heading>

      <p
        class="text-sm md:text-base text-gray-500 font-medium"
        data-testid="idea-database-statistics-description"
      >
        {{ $t('organizationDashboard.idea_database_subtitle') }}
      </p>
    </div>

    <CustomAlert
      v-if="!currentUser.organizationStatistics.ideaDatabaseUsersTotal.enabled"
      :variant="STATUS_INFO"
      :heading="$t('modalTitle.notice')"
      class="items-center"
      data-testid="statistics-inactive-users-alert"
    >
      <p class="font-normal">
        {{ $t('organizationDashboard.idea_database_inactive') }}
      </p>
    </CustomAlert>

    <StatisticCard
      v-else
      title="organizationDashboard.idea_database_roles_count"
      tooltip="organizationDashboard.idea_database_roles_count_info"
      data-testid="idea-database-statistics-roles-count"
    >
      <IdeaDatabaseUsers />
    </StatisticCard>
  </div>
</template>
