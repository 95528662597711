<script setup lang="ts">
import { computed, inject, type Ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useTranslation } from 'i18next-vue';

import { ALLOWED_BREAKPOINTS } from '@/constants/layout';

import type { ApexOptions } from 'apexcharts';
import type { CurrentUser } from '@/types/users';

const { t } = useTranslation();

const isMediumScreen = useMediaQuery(ALLOWED_BREAKPOINTS.md);
const isLargeScreen = useMediaQuery(ALLOWED_BREAKPOINTS.lg);
const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
const series = computed(() => [
  {
    name: t('manageAccounts.users'),
    data: [
      currentUser.value.organizationStatistics
        .ideaDatabaseUserAdministratorsUsers.enabled ?? 0,
      currentUser.value.organizationStatistics
        .ideaDatabaseFormAdministratorsUsers.enabled ?? 0,
      currentUser.value.organizationStatistics.ideaDatabaseInventorsUsers
        .enabled ?? 0,
      currentUser.value.organizationStatistics.ideaDatabaseReviewManagersUsers
        .enabled ?? 0,
      currentUser.value.organizationStatistics.ideaDatabaseDecisionMakersUsers
        .enabled ?? 0,
      currentUser.value.organizationStatistics.ideaDatabaseExpertsUsers
        .enabled ?? 0,
      currentUser.value.organizationStatistics
        .ideaDatabaseReviewCoordinatorsUsers.enabled ?? 0
    ]
  }
]);
const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    id: `Idea_Database_Users_Chart`,
    type: 'bar',
    height: 300,
    toolbar: {
      show: false
    },
    fontFamily: 'Poppins',
    fontSize: 14,
    fontColor: '#1F2937'
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'center'
      }
    }
  },
  colors: ['#4361EE'],
  dataLabels: {
    enabled: true,
    style: {
      colors: ['#ffffff'],
      cssClass: 'text-gray-800',
      fontFamily: 'Poppins',
      fontSize: isLargeScreen.value ? '1rem' : '0.75rem',
      fontWeight: 500
    },
    formatter(_val, opts) {
      return series.value[opts.seriesIndex].data[opts.dataPointIndex];
    }
  },
  states: {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none'
      }
    }
  },
  xaxis: {
    type: 'category',
    categories: [
      t('manageAccounts.User Administrator'),
      t('manageAccounts.Forms Administrator'),
      t('manageAccounts.Inventor'),
      t('manageAccounts.Review Manager'),
      t('manageAccounts.Decision Maker'),
      t('manageAccounts.Expert'),
      t('manageAccounts.Review Coordinator')
    ].map((category: string) => category.split(' ')),
    offsetX: isMediumScreen.value ? 0 : 15,
    labels: {
      show: true,
      style: {
        cssClass: 'text-gray-800',
        fontFamily: 'Poppins',
        fontWeight: 500
      }
    }
  },
  yaxis: {
    labels: {
      show: false
    }
  },
  tooltip: {
    enabled: false
  },
  fill: {
    opacity: 1
  },
  legend: {
    onItemClick: {
      toggleDataSeries: isLargeScreen.value
    },
    horizontalAlign: 'left',
    cssClass: 'text-gray-800 text-xs font-medium',
    fontFamily: 'Poppins'
  }
}));
</script>
<template>
  <div class="w-full overflow-auto">
    <apexchart
      type="bar"
      :height="chartOptions.chart?.height"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
