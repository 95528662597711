import { computed, onMounted, onUpdated, provide, ref } from 'vue';
import { refDebounced, until } from '@vueuse/core';

import { getUniqueFilters } from '@/lib/filtering';
import { valueUpdater } from '@/lib/utils';

import {
  DEFAULT_PAGE_INDEX,
  PER_PAGE,
  PER_PAGE_DEFAULT
} from '@/constants/table';

import {
  DEFAULT_FILTER_OPTIONS_COUNT,
  LIST_FILTERS
} from '@/constants/filtering';

import type { ListContentType, SelectOption } from '@/types/components';
import type { SortedColumns } from '@/types/sorting';
import type { Filter } from '@/types/filtering';
import type { RowSelectionState } from '@tanstack/vue-table';

function useAdvancedTable(
  initialSorting: SortedColumns,
  isPlaceholderData?: boolean
) {
  // Pagination state
  const currentPage = ref<number>(DEFAULT_PAGE_INDEX);
  const perPage = ref<number>(PER_PAGE_DEFAULT);
  const perPageOptions = ref<SelectOption[]>([]);

  // Filtering state
  const activeFilters = ref<Filter[]>([]);
  const currentListContent = ref<ListContentType>();
  const filterOptionsCount = ref<number>(DEFAULT_FILTER_OPTIONS_COUNT);
  const hasUniqueFilters = ref<boolean>(false);
  const showFilters = ref<boolean>(false);
  const showFilterPopup = ref<boolean>(false);

  // Search state
  const searchQuery = ref<string>('');
  const debouncedSearchQuery = refDebounced(searchQuery, 2000);

  // Sorting state
  const sortedColumns = ref<SortedColumns>(initialSorting);

  // Row selection state
  const rowSelection = ref<RowSelectionState>({});
  const currentFilteredIds = ref<number[]>([]);
  const isAllSelected = ref<boolean>(false);

  // Combined API params
  const filteringParams = ref({
    filters: activeFilters,
    searchQuery: debouncedSearchQuery
  });
  const apiParams = ref({
    page: currentPage,
    filters: activeFilters,
    perPage,
    searchQuery: debouncedSearchQuery,
    sortedColumns
  });
  const unfilteredCount = computed(() => {
    const selectedItems = Object.keys(rowSelection.value).map(Number);

    return selectedItems.filter(
      currentListItem => !currentFilteredIds.value.includes(currentListItem)
    ).length;
  });
  const selectedCount = computed(() => {
    const selectedItems = Object.keys(rowSelection.value).map(Number);

    return isAllSelected.value
      ? currentFilteredIds.value.length + unfilteredCount.value
      : selectedItems.length;
  });

  // Pagination handlers
  function handlePageSizeChange(newPageSize: number) {
    valueUpdater(DEFAULT_PAGE_INDEX, currentPage);
    valueUpdater(newPageSize, perPage);
  }

  function handlePrevPageClick() {
    valueUpdater(Math.floor(currentPage.value - 1), currentPage);
  }

  function handleCurrentPageClick(newPageIndex: number) {
    valueUpdater(newPageIndex, currentPage);
  }

  function handleNextPageClick() {
    if (!isPlaceholderData) {
      valueUpdater(currentPage.value + 1, currentPage);
    }
  }

  function handleDeleteTableData(isBulkAction: boolean): boolean {
    const deletedItemsCount = isBulkAction
      ? Object.keys(rowSelection.value).length
      : 1;
    const newCurrentPage =
      isAllSelected.value && isBulkAction
        ? DEFAULT_PAGE_INDEX
        : Math.min(
            currentPage.value,
            Math.ceil(
              (currentFilteredIds.value.length - deletedItemsCount) /
                perPage.value
            )
          );
    const hasPageChanged = newCurrentPage !== currentPage.value;
    valueUpdater(newCurrentPage, currentPage);

    return hasPageChanged;
  }

  // Filtering handlers
  function handleFilterCreate(filterToAdd: Filter) {
    if (!activeFilters.value.length) {
      valueUpdater(true, showFilters);
    }

    valueUpdater(
      [
        ...getUniqueFilters(activeFilters.value, filterToAdd),
        { ...filterToAdd }
      ],
      activeFilters
    );
    valueUpdater(filterOptionsCount.value - 1, filterOptionsCount);
    valueUpdater(DEFAULT_PAGE_INDEX, currentPage);
  }

  function handleFilterRemove(filterToRemove: Filter) {
    valueUpdater(
      [...getUniqueFilters(activeFilters.value, filterToRemove)],
      activeFilters
    );
    valueUpdater(filterOptionsCount.value + 1, filterOptionsCount);
    valueUpdater(DEFAULT_PAGE_INDEX, currentPage);
  }

  function handleClearFilters() {
    valueUpdater([], activeFilters);
    valueUpdater(false, showFilters);
    valueUpdater(DEFAULT_FILTER_OPTIONS_COUNT, filterOptionsCount);
    valueUpdater(DEFAULT_PAGE_INDEX, currentPage);
  }

  function handleCurrentListContentUpdate(
    listType: ListContentType | undefined
  ) {
    valueUpdater(listType, currentListContent);
  }

  function handleFilterPopupToggle(isFetching?: boolean) {
    valueUpdater(
      Boolean(isFetching) && typeof isFetching === 'boolean'
        ? false
        : !showFilterPopup.value,
      showFilterPopup
    );
  }

  function handleShowFilters() {
    valueUpdater(!showFilters.value, showFilters);
  }

  // Search handlers
  async function handleSearchQueryChange(newQuery: string) {
    valueUpdater(newQuery, searchQuery);

    if (currentPage.value !== 1) {
      await until(debouncedSearchQuery).changed();
      valueUpdater(DEFAULT_PAGE_INDEX, currentPage);
    }
  }

  // Sorting handlers
  function handleSortingUpdate(columns: SortedColumns) {
    valueUpdater([...columns], sortedColumns);
  }

  // Row selection handlers
  function handleIsAllSelected(forceDeselect = false) {
    if (isAllSelected.value || forceDeselect) {
      rowSelection.value = {};
    }

    valueUpdater(!forceDeselect && !isAllSelected.value, isAllSelected);
  }

  function handleCurrentFilteredIds(ids: number[]) {
    currentFilteredIds.value = ids;
  }

  // Lifecycle hooks
  onMounted(() => {
    const options = PER_PAGE.map(option => {
      return {
        label: option.toString(),
        value: option.toString(),
        selected: option === perPage.value
      };
    });
    valueUpdater(options, perPageOptions);

    if (currentListContent.value) {
      valueUpdater(
        LIST_FILTERS[currentListContent.value].length,
        filterOptionsCount
      );
    }
  });

  onUpdated(() => {
    const options = perPageOptions.value.map((option: SelectOption) => ({
      ...option,
      selected: option.value === perPage.value.toString()
    }));
    valueUpdater(options, perPageOptions);

    if (currentListContent.value) {
      valueUpdater(
        LIST_FILTERS[currentListContent.value].length,
        filterOptionsCount
      );
    }
  });

  provide('apiParams', apiParams);
  provide('activeFilters', activeFilters);
  provide('currentListContent', currentListContent);
  provide('currentPage', currentPage);
  provide('currentFilteredIds', currentFilteredIds);
  provide('filterOptionsCount', filterOptionsCount);
  provide('filteringParams', filteringParams);
  provide('hasUniqueFilters', hasUniqueFilters);
  provide('perPage', perPage);
  provide('perPageOptions', perPageOptions);
  provide('searchQuery', debouncedSearchQuery);
  provide('showFilters', showFilters);
  provide('showFilterPopup', showFilterPopup);
  provide('sortedColumns', sortedColumns);
  provide('selectedCount', selectedCount);
  provide('unfilteredCount', unfilteredCount);
  provide('rowSelection', rowSelection);
  provide('isAllSelected', isAllSelected);

  provide('handleClearFilters', handleClearFilters);
  provide('handleCurrentListContentUpdate', handleCurrentListContentUpdate);
  provide('handleCurrentPageClick', handleCurrentPageClick);
  provide('handleCurrentFilteredIds', handleCurrentFilteredIds);
  provide('handleFilterCreate', handleFilterCreate);
  provide('handleFilterRemove', handleFilterRemove);
  provide('handleNextPageClick', handleNextPageClick);
  provide('handlePageSizeChange', handlePageSizeChange);
  provide('handlePopupToggle', handleFilterPopupToggle);
  provide('handlePrevPageClick', handlePrevPageClick);
  provide('handleSearchQueryChange', handleSearchQueryChange);
  provide('handleShowFilters', handleShowFilters);
  provide('handleSortingUpdate', handleSortingUpdate);
  provide('handleIsAllSelected', handleIsAllSelected);
  provide('handleDeleteTableData', handleDeleteTableData);

  return {
    apiParams,
    activeFilters,
    currentListContent,
    currentFilteredIds,
    currentPage,
    filterOptionsCount,
    filteringParams,
    hasUniqueFilters,
    perPage,
    perPageOptions,
    searchQuery: debouncedSearchQuery,
    showFilters,
    showFilterPopup,
    sortedColumns,
    rowSelection,
    isAllSelected,
    selectedCount,
    unfilteredCount,
    handleClearFilters,
    handleCurrentListContentUpdate,
    handleCurrentPageClick,
    handleCurrentFilteredIds,
    handleFilterCreate,
    handleFilterRemove,
    handleNextPageClick,
    handlePageSizeChange,
    handlePopupToggle: handleFilterPopupToggle,
    handlePrevPageClick,
    handleSearchQueryChange,
    handleShowFilters,
    handleSortingUpdate,
    handleIsAllSelected,
    handleDeleteTableData
  };
}

export default useAdvancedTable;

export type UseAdvancedTable = ReturnType<typeof useAdvancedTable>;
