import { useBulkStatus } from '@/lib/hooks';
import { useQueryClient } from '@tanstack/vue-query';
import { useTranslation } from 'i18next-vue';
import { useToast } from 'vue-toastification';

import Echo from 'laravel-echo';
import socketIoClient from 'socket.io-client';

import { ToastDescriptionWithLink } from '@/components/custom';

import {
  onLogout,
  parseCurrentUserActiveProducts,
  parseUpdatedOrganizationDetails
} from '@/lib/helpers';
import { getAccessToken } from '@/lib/cookies';

import {
  BULK_ERROR,
  BULK_SUCCESS,
  BULK_WARNING,
  INVALIDATE_MESSAGES_MAP,
  TOAST_INJECT_BULK_MESSAGES
} from '@/constants/bulkActions';

import { ECHO_BROADCASTER, ECHO_TRANSPORTS } from '@/constants/config';

import type { ParseKeys } from 'i18next';
import type { TOptions } from 'i18next/typescript/options';
import type { Ref } from 'vue';
import type { CurrentUser } from '@/types/users';
import type { Organization } from '@/types/organizations';

function useEchoServer(currentUser: Ref<CurrentUser>) {
  const { setBulkActionStatus } = useBulkStatus();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  async function invalidateQueryCache(data: BulkActionSocket) {
    const keyToInvalidate =
      INVALIDATE_MESSAGES_MAP[
        data.message as keyof typeof INVALIDATE_MESSAGES_MAP
      ];

    if (keyToInvalidate) {
      await queryClient.invalidateQueries({
        queryKey: [keyToInvalidate]
      });
    }
  }

  function saveSocketMessage(data: EchoSocket) {
    if (!window.Cypress) {
      return;
    }

    if (!window.socketMessages) {
      window.socketMessages = [];
    }

    window.socketMessages.push(data);
  }

  const echoServer = new Echo({
    client: socketIoClient,
    broadcaster: ECHO_BROADCASTER,
    host: `${import.meta.env.VITE_LARAVEL_ECHO_URL}:${import.meta.env.VITE_LARAVEL_ECHO_PORT}`,
    auth: {
      headers: { Authorization: `Bearer ${getAccessToken()}` }
    },
    transports: ECHO_TRANSPORTS
  });

  echoServer
    .private(`User.${window.currentUser.id}`)
    .error((error: Error) => {
      saveSocketMessage(error);

      if (import.meta.env.DEV) {
        console.error('Echo Error: ', { error });
      }
    })
    .listen('.BulkActionSuccess', async (data: BulkActionSocket) => {
      if (
        TOAST_INJECT_BULK_MESSAGES.some(message => data.message === message)
      ) {
        toast.success(
          {
            component: ToastDescriptionWithLink,
            props: {
              message: t(TOAST_INJECT_BULK_MESSAGES[0], data.data)
            }
          },
          {
            timeout: false
          }
        );
      } else {
        toast.success(t(data.message, data.data));
      }

      await invalidateQueryCache(data);
      await setBulkActionStatus(BULK_SUCCESS);

      saveSocketMessage(data);

      if (import.meta.env.DEV) {
        console.log('Bulk Action Success: ', { data });
      }
    })
    .listen('.UserAccountUpdated', async (data: UserUpdatedSocket) => {
      currentUser.value = parseCurrentUserActiveProducts(data.currentUser);
      Object.assign(window.currentUser, currentUser.value);

      saveSocketMessage(data);

      if (import.meta.env.DEV) {
        console.log('UserAccountUpdated: ', {
          currentUser: parseCurrentUserActiveProducts(data.currentUser),
          user: currentUser.value
        });
      }
    })
    .listen('.BulkActionWarning', async (data: BulkActionSocket) => {
      toast.warning(t(data.message, data.data));

      await invalidateQueryCache(data);
      await setBulkActionStatus(BULK_WARNING);

      saveSocketMessage(data);

      if (import.meta.env.DEV) {
        console.log('Bulk Action Warning: ', { data });
      }
    })
    .listen('.BulkActionFailure', async (data: BulkActionSocket) => {
      toast.error(t(data.message, data.data));

      await invalidateQueryCache(data);
      await setBulkActionStatus(BULK_ERROR);

      saveSocketMessage(data);

      if (import.meta.env.DEV) {
        console.log('Bulk Action Failure: ', { data });
      }
    })
    .listen('.LogoutAction', (data: BaseSocket) => {
      onLogout();

      saveSocketMessage(data);

      if (import.meta.env.DEV) {
        console.log('LogoutAction');
      }
    });

  window.currentUser.availableOrganizations.forEach(organization => {
    echoServer
      .private(`Organization.${organization.id}`)
      .listen(
        '.OrganizationUpdated',
        async (data: OrganizationUpdatedSocket) => {
          currentUser.value = parseUpdatedOrganizationDetails(
            currentUser.value,
            data.organization
          );
          currentUser.value = parseCurrentUserActiveProducts(currentUser.value);
          Object.assign(window.currentUser, currentUser.value);

          saveSocketMessage(data);

          if (import.meta.env.DEV) {
            console.log('OrganizationUpdated: ', { data });
          }
        }
      );
  });

  return { echoServer };
}

export default useEchoServer;

export type BaseSocket = {
  socket: null;
};

export type BulkActionSocket = BaseSocket & {
  message: ParseKeys;
  data: TOptions;
};

export type UserUpdatedSocket = BaseSocket & {
  currentUser: CurrentUser;
};

export type OrganizationUpdatedSocket = BaseSocket & {
  organization: Organization;
};

export type EchoSocket =
  | Error
  | BaseSocket
  | BulkActionSocket
  | UserUpdatedSocket
  | OrganizationUpdatedSocket;
