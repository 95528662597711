import { type VariantProps, cva } from 'class-variance-authority';

export { default as Badge } from '@/components/ui/Badge/Badge.vue';
import { STATUS_DEFAULT } from '@/constants/status';

export const badgeVariants = cva(
  'inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium',
  {
    variants: {
      variant: {
        default: 'bg-gray-100 text-gray-800',
        destructive: 'bg-red-100 text-red-800',
        info: 'bg-blue-100 text-blue-800',
        success: 'bg-emerald-100 text-emerald-800',
        warning: 'bg-amber-200 text-yellow-800',
        ghost: 'border border-gray-200 bg-white text-gray-500'
      },
      shape: {
        circular: 'rounded-full px-1.5 py-0.5',
        rectangular: 'rounded-md',
        rectangular_sharp: 'rounded'
      },
      size: {
        small: 'h-4.5 min-w-4.5',
        medium: 'h-6 min-w-6'
      },
      isCounter: {
        true: 'text-center justify-center',
        false: ''
      }
    },
    defaultVariants: {
      variant: STATUS_DEFAULT,
      shape: 'circular',
      size: 'small',
      isCounter: false
    }
  }
);

export type BadgeVariants = VariantProps<typeof badgeVariants>;
