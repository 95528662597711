<script setup lang="ts">
import { inject } from 'vue';

import { Trash2 } from 'lucide-vue-next';

import { CustomDropdownMenuItem } from '@/components/custom/CustomDropdownMenu';

import EditIcon from 'assets/icons/edit-05.svg?component';

import {
  openBulkAddToGroupsModal,
  openBulkDeleteGroupsModal
} from '@/lib/modals/groupsModals';

import type { UseModal } from '@/types/modals';

const openModal = inject('openModal') as UseModal['openModal'];
</script>
<template>
  <div class="min-w-44 w-max">
    <CustomDropdownMenuItem
      :item-label="$t('manageGroups.edit_groups')"
      item-class="stroke-1"
      :item-icon="EditIcon"
      @click="openBulkAddToGroupsModal(openModal)"
    />
    <CustomDropdownMenuItem
      class="text-red-500"
      :item-label="$t('manageGroups.delete_groups')"
      :item-icon="Trash2"
      @click="openBulkDeleteGroupsModal(openModal)"
    />
  </div>
</template>
