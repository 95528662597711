<script setup lang="ts" generic="T">
import { computed, ref } from 'vue';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/Table';

import { SingleRow, TableLoadingBlock } from '@/components/custom/CustomTable';

import { Button } from '@/components/ui/Button';

import { alphabeticalSorting, getSortingIconValue } from '@/lib/sorting';

import { cn } from '@/lib/utils';

import {
  type AllowedColumns,
  createTableColumns,
  DEFAULT_RENDERED_ROWS
} from '@/constants/table';

import { SORT_ASCENDING, SORT_DESCENDING } from '@/constants/sorting';

import type { SortingOrder } from '@/types/sorting';

const props = defineProps<{
  columns: AllowedColumns[];
  tableData: T[];
  updatedTableData?: T[];
  defaultSorting?: AllowedColumns;
  isModal?: boolean;
  searchQuery?: string;
  isFetching: boolean;
}>();

const currentSortingColumn = ref<AllowedColumns | undefined>(
  props.defaultSorting
);
const currentSortingType = ref<SortingOrder>(SORT_ASCENDING);
const tableColumns = computed(() => createTableColumns(props.columns));
const loadingData = Array.from({
  length: props.isModal ? props.columns.length : props.columns.length + 1
}).map((_, i) => i);

const sortedTableData = computed(() => {
  if (!currentSortingColumn.value) {
    return props.tableData;
  }

  return alphabeticalSorting<T>({
    array: props.tableData as T[],
    param: currentSortingColumn.value as keyof T,
    descOrder: currentSortingType.value !== SORT_ASCENDING,
    caseInsensitive: true
  });
});

const sortedUpdatedTableData = computed(() => {
  if (!currentSortingColumn.value || !props.updatedTableData) {
    return props.updatedTableData;
  }

  return alphabeticalSorting<T>({
    array: props.updatedTableData,
    param: currentSortingColumn.value as keyof T,
    descOrder: currentSortingType.value !== SORT_ASCENDING,
    caseInsensitive: true
  });
});

function onSortingChange() {
  currentSortingType.value =
    currentSortingType.value === SORT_ASCENDING
      ? SORT_DESCENDING
      : SORT_ASCENDING;
}
</script>

<template>
  <div
    :class="
      cn(
        'shadow border border-gray-200 rounded-md overflow-hidden',
        isModal &&
          'max-w-[calc(90dvw_-_3.5rem)] sm:max-w-[calc(25rem_-_3.5rem)] md:max-w-[calc(37.5rem_-_3.5rem)] xl:max-w-[calc(50rem_-_5rem)]'
      )
    "
  >
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead
            v-for="column in tableColumns"
            :key="column.value.key"
            :class="cn(column.isSortable ? 'flex items-center gap-2.5' : '')"
          >
            <template v-if="column.isSortable">
              <Button
                class="text-xs font-semibold text-inherit uppercase px-0 py-0 h-min"
                :is-disabled="isFetching || !sortedTableData.length"
                variant="icon"
                :title="column.value.tooltip"
                @click="onSortingChange"
              >
                <span class="text-xs">{{ column.value.label }}</span>
                <component
                  :is="
                    getSortingIconValue(
                      currentSortingColumn === column.value.key
                        ? currentSortingType
                        : SORT_ASCENDING
                    )
                  "
                  class="text-gray-800 h-4 w-4"
                />
              </Button>
            </template>

            <span v-else class="text-xs">{{ column.value.label }}</span>
          </TableHead>

          <TableHead v-if="!isModal" key="actions_head">
            <span class="text-xs">{{ $t('common.listActions') }}</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody v-if="isModal || isFetching || sortedTableData.length">
        <template v-if="isFetching">
          <TableRow
            v-for="row in DEFAULT_RENDERED_ROWS"
            :key="row"
            class="hover:bg-white"
          >
            <TableCell
              v-for="cell in loadingData"
              :key="cell"
              class="px-5 py-[11.5px]"
            >
              <TableLoadingBlock />
            </TableCell>
          </TableRow>
        </template>

        <template v-else>
          <TableRow v-for="(row, index) in sortedTableData" :key="`${row}`">
            <SingleRow
              v-for="column in tableColumns"
              :key="column.value.key"
              :is-modal="isModal"
              :row="row"
              :column="column"
              :updated-row="sortedUpdatedTableData?.[index]"
            />
            <TableRow v-if="!isModal" key="actions_row">
              <slot :row="row"></slot>
            </TableRow>
          </TableRow>
        </template>
      </TableBody>

      <template
        v-else-if="
          !isFetching && !sortedTableData.length && searchQuery?.length
        "
      >
        <slot name="table-no-results"></slot>
      </template>

      <template v-else>
        <slot name="table-empty"></slot>
      </template>
    </Table>
  </div>
</template>
