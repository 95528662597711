import { computed } from 'vue';
import { useSelectedIds } from '@/lib/hooks';
import { useQuery } from '@tanstack/vue-query';

import { fetchUsersSimplifiedWithGroups } from '@/api/user';

import { USERS_SIMPLIFIED_GROUP_API } from '@/constants/api';
import { PATENTS_PLATFORM_FILTERING } from '@/constants/filtering';

import type { UserSimplifiedWithGroup } from '@/types/users';

function useUsersSimplifiedGroup(
  useParams = false,
  usePatentsPlatformFiltering = false
) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [USERS_SIMPLIFIED_GROUP_API, selectedIds],
    queryFn: () =>
      fetchUsersSimplifiedWithGroups({
        user_ids: selectedIds.value,
        ...(usePatentsPlatformFiltering && {
          additionalFilters: PATENTS_PLATFORM_FILTERING
        })
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedUsersWithGroup = computed<UserSimplifiedWithGroup[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedUsersWithGroup, isError, isFetching };
}

export default useUsersSimplifiedGroup;
