<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { SelectGroup, type SelectGroupProps } from 'radix-vue';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  SelectGroupProps & { class?: HTMLAttributes['class'] }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <SelectGroup :class="cn('p-1 w-full', props.class)" v-bind="delegatedProps">
    <slot />
  </SelectGroup>
</template>
