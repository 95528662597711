import { HTTP_CODES } from '@/constants/api';

export const TRANSLATION_INIT_ERROR = 'Translations failed to initialize';
export const FAILED_TO_CHANGE_LANGUAGE = 'Failed to change language';
export const FAILED_TO_DOWNLOAD_FILE =
  'There has been a problem with downloading the file:';

export const ERROR_PAGE_CONTENT = {
  [HTTP_CODES.FORBIDDEN]: {
    heading: 'errorPage.forbidden_title',
    subtitle: 'errorPage.forbidden_subtitle'
  },
  [HTTP_CODES.NOT_FOUND]: {
    heading: 'errorPage.not_found_title',
    subtitle: 'errorPage.not_found_subtitle'
  }
} as const;
