import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchUserSessions } from '@/api/profile';

import { SESSIONS_API } from '@/constants/api';

import type { Session } from '@/types/userSettings';

function useSessions() {
  const { data, isError, isFetching } = useQuery({
    queryKey: [SESSIONS_API],
    queryFn: () => fetchUserSessions(),
    select(data) {
      return data.sessions;
    }
  });

  const sessions = computed<Session[]>(() => {
    return data.value ?? [];
  });

  return { sessions, isError, isFetching };
}

export default useSessions;
