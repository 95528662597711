<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import type { CheckboxRootProps } from 'radix-vue';

import { Label } from '@/components/ui/Label';
import { cn } from '@/lib/utils';
import { useVModel } from '@vueuse/core';

const props = defineProps<
  CheckboxRootProps & {
    class?: HTMLAttributes['class'];
    checked?: boolean;
    isDisabled?: boolean;
    label?: string;
  }
>();

const emits = defineEmits<{
  (e: 'update:checked', payload: boolean): void;
}>();

const checked = useVModel(props, 'checked', emits, {
  passive: true
});
</script>

<template>
  <Label class="font-medium flex items-center" @click.stop>
    <input
      v-model="checked"
      type="checkbox"
      :class="
        cn(
          'shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 hover:cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed dark:bg-slate-900 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800',
          props.label && 'mr-2',
          props.class
        )
      "
      :disabled="isDisabled"
    />
    <span v-if="label" class="mt-1">{{ label }}</span>
  </Label>
</template>
