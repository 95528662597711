import { inject } from 'vue';
import { useTableData } from '@/lib/hooks';

import { fetchUsers } from '@/api/user';

import { USERS_API } from '@/constants/api';

import type { ListUser } from '@/types/users';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

function useUsers() {
  const apiParams = inject<UseAdvancedTable['apiParams']>('apiParams');

  const { data, isFetching, isError, isPlaceholderData } =
    useTableData<ListUser>({
      apiParams,
      apiKey: USERS_API,
      fetchApiData: fetchUsers
    });

  return { users: data, isFetching, isError, isPlaceholderData };
}

export default useUsers;
