<script setup lang="ts">
import { cn } from '@/lib/utils';

const props = withDefaults(
  defineProps<{
    lines?: number;
  }>(),
  {
    lines: 1
  }
);
</script>
<template>
  <div class="rounded-md shadow justify-start items-start inline-flex bg-white">
    <div
      :class="
        cn(
          'grow shrink basis-0 h-13 px-2.5 py-2 rounded-md border border-gray-200 justify-start items-center gap-2 flex',
          lines === 3 && 'h-18'
        )
      "
    >
      <div class="animate-pulse w-7 h-7 bg-gray-200 rounded-lg" />
      <div class="grow flex-col gap-1 inline-flex">
        <div
          v-for="i in props.lines"
          :key="i"
          class="animate-pulse h-3 w-full bg-gray-200 rounded"
        />
      </div>
    </div>
  </div>
</template>
