<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import { TableCell, TableRow } from '@/components/ui/DataTable';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

import { DEFAULT_COL_SPAN, DEFAULT_ROW_SPAN } from '@/constants/table';

const props = withDefaults(
  defineProps<{
    class?: HTMLAttributes['class'];
    rowspan?: number;
    colspan?: number;
  }>(),
  {
    class: null,
    rowspan: DEFAULT_ROW_SPAN,
    colspan: DEFAULT_COL_SPAN
  }
);

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <TableRow>
    <TableCell
      :class="
        cn(
          'p-4 whitespace-nowrap align-middle text-sm text-slate-950',
          props.class
        )
      "
      v-bind="delegatedProps"
    >
      <div class="flex items-center justify-center py-10">
        <slot />
      </div>
    </TableCell>
  </TableRow>
</template>
