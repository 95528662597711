<script setup lang="ts">
import { inject } from 'vue';

import { Ellipsis, Trash2 } from 'lucide-vue-next';

import {
  CustomDropdownMenu,
  CustomDropdownMenuItem
} from '@/components/custom/CustomDropdownMenu';
import { Button } from '@/components/ui/Button';

import EditIcon from 'assets/icons/edit-05.svg?component';

import {
  openGroupDeleteModal,
  openGroupEditModal
} from '@/lib/modals/groupsModals';

import { BUTTON_VARIANTS } from '@/constants/component';

import type { ListGroup } from '@/types/groups';
import type { UseModal } from '@/types/modals';

defineProps<{
  group: ListGroup;
}>();

const openModal = inject('openModal') as UseModal['openModal'];
</script>

<template>
  <div class="flex justify-center" @click.stop>
    <CustomDropdownMenu>
      <template #trigger>
        <Button type="button" :variant="BUTTON_VARIANTS.icon">
          <Ellipsis class="w-6 h-6 text-gray-800" />
        </Button>
      </template>
      <template #menu-options>
        <CustomDropdownMenuItem
          :item-label="$t('manageAccounts.edit_group')"
          item-class="stroke-1"
          :item-icon="EditIcon"
          @click="openGroupEditModal(openModal, group)"
        />
        <CustomDropdownMenuItem
          class="text-red-500"
          :item-label="$t('manageGroups.delete_group')"
          :item-icon="Trash2"
          @click="openGroupDeleteModal(openModal, group)"
        />
      </template>
    </CustomDropdownMenu>
  </div>
</template>
