<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useOrganizationUserPresets } from '@/lib/hooks';

import SearchIcon from 'assets/icons/search-sm.svg?component';

import { Button } from '@/components/ui/Button';
import { CustomTable } from '@/components/custom';
import { Input } from '@/components/ui/Input';
import { OrganizationUserPresetActions } from '@/views/Organization/components';
import { TablePlaceholder } from '@/components/ui/Table';
import { TableEmpty } from '@/components/ui/DataTable';

import { openCreateOrganizationUserPresetModal } from '@/lib/modals/organizationUserPresetsModals';

import {
  DESCRIPTION_COLUMN,
  NAME_COLUMN,
  PRODUCT_ACCESS_COLUMN
} from '@/constants/table';

import { COMPONENT_VARIANTS, ELEMENT_SIZES } from '@/constants/component';
import { PRESET_TABLE_ROWS_COUNT } from '@/constants/organizationUserPreset';

import type { UseModal } from '@/types/modals';

const openModal = inject('openModal') as UseModal['openModal'];
const searchQuery = ref<string>('');

const { organizationUserPresets, isFetching } = useOrganizationUserPresets();
const filteredData = computed(() =>
  organizationUserPresets.value.filter(preset => {
    return preset.name.toLowerCase().includes(searchQuery.value.toLowerCase());
  })
);

function onSearch(event: string) {
  searchQuery.value = event;
}
</script>

<template>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col sm:flex-row self-end gap-2.5 w-full sm:max-w-100">
      <div class="lg:max-w-60">
        <Input
          :icon-component="SearchIcon"
          :is-disabled="isFetching"
          :model-value="searchQuery"
          class="h-11 w-full text-sm"
          :placeholder="$t('manageAccounts.search')"
          can-be-cleared
          @update:model-value="onSearch($event as string)"
        />
      </div>
      <Button
        type="button"
        :is-disabled="isFetching"
        :variant="COMPONENT_VARIANTS.info"
        :size="ELEMENT_SIZES.small"
        class="min-w-32 w-full px-4 grow-[2] rounded-lg sm:w-max"
        @click="openCreateOrganizationUserPresetModal(openModal)"
      >
        <span>{{ $t('organization.create_new_preset') }}</span>
      </Button>
    </div>

    <CustomTable
      :columns="[NAME_COLUMN, DESCRIPTION_COLUMN, PRODUCT_ACCESS_COLUMN]"
      :default-sorting="NAME_COLUMN"
      :is-fetching="isFetching"
      :table-data="filteredData"
      :search-query="searchQuery"
    >
      <template #default="{ row }">
        <OrganizationUserPresetActions :organization-user-preset="row" />
      </template>

      <template #table-empty>
        <TableEmpty :colspan="PRESET_TABLE_ROWS_COUNT">
          <TablePlaceholder
            create-new-item-text="organization.create_new_preset"
            not-found-text="organization.no_user_preset_found"
            add-first-text="organization.add_first_preset"
            @create-new-item="openCreateOrganizationUserPresetModal(openModal)"
          />
        </TableEmpty>
      </template>

      <template #table-no-results>
        <TableEmpty :colspan="PRESET_TABLE_ROWS_COUNT">
          <p
            class="h-52 content-center w-max mx-auto text-gray-400 text-2xl lg:text-3xl font-semibold"
          >
            <span>{{ $t('common.noResults') }}</span>
          </p>
        </TableEmpty>
      </template>
    </CustomTable>
  </div>
</template>
