<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import {
  DropdownMenuLabel,
  type DropdownMenuLabelProps,
  useForwardProps
} from 'radix-vue';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  DropdownMenuLabelProps & { class?: HTMLAttributes['class']; inset?: boolean }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <DropdownMenuLabel
    v-bind="forwardedProps"
    :class="cn('px-2 py-1.5 text-sm font-medium', inset && 'pl-8', props.class)"
  >
    <slot />
  </DropdownMenuLabel>
</template>
