<script setup lang="ts">
import {inject, type Ref} from 'vue';

import { openClearSessionModal } from '@/lib/modals/userSettingsModals';

import { Button } from '@/components/ui/Button';

import { COMPONENT_VARIANTS } from '@/constants/component';

import type { UseModal } from '@/types/modals';
import type { CurrentUser } from "@/types/users";

const openModal = inject('openModal') as UseModal['openModal'];

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>
<template>
  <Button
    class="w-full md:min-w-25 md:w-max"
    type="button"
    :variant="COMPONENT_VARIANTS.info"
    @click="openClearSessionModal(openModal, currentUser.loginType)"
  >
    <span class="block mt-0.5">{{
      $t('userProfile.Logout Other Browser Sessions')
    }}</span>
  </Button>
</template>
