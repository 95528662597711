<script setup lang="ts">
import type { Component, HTMLAttributes } from 'vue';

import {
  DropdownMenuItem,
  DropdownMenuLabel
} from '@/components/ui/DropdownMenu';

import { cn } from '@/lib/utils';

const props = defineProps<{
  class?: string;
  itemIcon?: Component | string;
  itemClass?: HTMLAttributes['class'];
  itemLabel: string;
}>();
</script>

<template>
  <DropdownMenuItem :class="cn('pl-0', props.itemIcon && 'pl-2', props.class)">
    <component
      :is="itemIcon"
      :class="cn('w-4 h-4', itemClass)"
      stroke-width="1.5"
    />
    <DropdownMenuLabel>{{ itemLabel }}</DropdownMenuLabel>
    <slot></slot>
  </DropdownMenuItem>
</template>
