import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { getDataKey } from '@/lib/hooks/table/useDataSimplified';

import { fetchQueryData } from '@/lib/hooks/table/useTableDataSimplified';

import type { ListContentType } from '@/types/components';

function useValidationDataSimplified(listType: ListContentType) {
  const { data, isError, isFetching } = useQuery({
    queryKey: [getDataKey(listType)],
    queryFn: () => fetchQueryData(listType),
    select(data) {
      return data.data;
    },
    staleTime: 5 * 60 * 1000
  });

  const simplifiedData = computed(() => {
    return data.value ?? [];
  });

  return { simplifiedData, isError, isFetching };
}

export default useValidationDataSimplified;
