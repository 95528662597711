import { useToast } from 'vue-toastification';
import request from '@/api/base';

import { onLogout, parseError } from '@/lib/helpers';

import { LOGOUT_ROUTE } from '@/constants/routes';

import type { ApiErrorResponse } from '@/types/api';

const toast = useToast();

export async function logout() {
  return await request
    .post(LOGOUT_ROUTE)

    .then(() => onLogout())
    .catch((error: ApiErrorResponse) => {
      toast.error(parseError(error));
    });
}
