<script setup lang="ts">
import { type HTMLAttributes } from 'vue';
import { useVModel } from '@vueuse/core';

import i18next from 'i18next';
import { DatePicker } from 'v-calendar';
import 'assets/calendar.css';

import { inputVariants } from '@/components/ui/Input';
import { cn } from '@/lib/utils';

import {
  CALENDAR_CONFIG,
  CALENDAR_MASK,
  DEFAULT_CALENDAR_LANGUAGE,
  FIRST_DAY_OF_THE_WEEK
} from '@/constants/date';

import { STATUS_DEFAULT } from '@/constants/status';

const props = defineProps<{
  class?: HTMLAttributes['class'];
  modelValue: string;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', payload: typeof props.modelValue): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true
});
</script>

<template>
  <DatePicker
    v-model.string="modelValue"
    class="calendar"
    :masks="{
      modelValue: CALENDAR_MASK,
      input: CALENDAR_MASK
    }"
    :popover="{
      visibility: 'focus'
    }"
    :first-day-of-week="FIRST_DAY_OF_THE_WEEK"
    :locale="i18next.language || DEFAULT_CALENDAR_LANGUAGE"
    :model-config="CALENDAR_CONFIG"
    trim-weeks
  >
    <template #default="{ inputValue, inputEvents }">
      <input
        :class="cn(inputVariants({ variant: STATUS_DEFAULT, size: 'small' }))"
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </DatePicker>
</template>
