import request, { getQueryData } from '@/api/base';

import { DEFAULT_TOKENS_SORT } from '@/constants/api';

import type {
  ApiErrorResponse,
  ApiResponse,
  ApiSimpleResponse,
  ApiTableParams,
  ApiTableResponse
} from '@/types/api';

import type {
  BulkChangeTokensStatusParams,
  BulkDeleteTokensParams,
  CreateTokenParams,
  FetchBulkTokens,
  UpdateTokenParams
} from '@/api/token/types';

import type { DetailedToken, ListToken, TokenSimplified } from '@/types/tokens';

function parseTokenData<T extends ListToken>(data: T): T {
  return {
    ...data,
    allowedIpAddresses: (data.allowedIpAddresses as unknown as string[]).join(
      ', '
    )
  } as T;
}

function prepareTokenParameters<
  T extends CreateTokenParams | UpdateTokenParams
>(token: T): T {
  return {
    ...token,
    access_level_id: Number(token.access_level_id),
    ip_limited: token.ip_limited
      .split(/\n|,/)
      .map(ip => ip.trim())
      .filter(ip => ip)
  } as T;
}

export async function fetchTokens(params?: ApiTableParams) {
  return await request
    .get<
      ApiTableResponse<ListToken>
    >(`${import.meta.env.VITE_API_VERSION}/tokens${getQueryData({ params, defaultSortField: DEFAULT_TOKENS_SORT })}`)
    .then(response => ({
      ...response.data,
      data: response.data.data.map(token => parseTokenData<ListToken>(token))
    }));
}

export async function fetchToken(tokenId: number) {
  return await request
    .get<
      ApiResponse<DetailedToken>
    >(`${import.meta.env.VITE_API_VERSION}/tokens/${tokenId}`)
    .then(response => ({
      data: parseTokenData<DetailedToken>(response.data.data)
    }));
}

export async function fetchTokensSimplified({
  token_ids,
  params
}: FetchBulkTokens = {}) {
  return await request
    .get<ApiResponse<TokenSimplified[]>>(
      `${import.meta.env.VITE_API_VERSION}/tokens/fetch/tokens${getQueryData({ params })}`,
      {
        ...(token_ids && { params: { token_ids } })
      }
    )
    .then(response => response.data);
}

export async function changeTokenStatus(tokenId: number, value: boolean) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/tokens/${tokenId}/status`,
      {
        active: value
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function createToken(token: CreateTokenParams) {
  const preparedTokenParameters =
    prepareTokenParameters<CreateTokenParams>(token);

  return await request
    .post<ApiSimpleResponse>(`${import.meta.env.VITE_API_VERSION}/tokens`, {
      ...preparedTokenParameters
    })
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function updateToken(token: UpdateTokenParams) {
  const preparedTokenParameters =
    prepareTokenParameters<UpdateTokenParams>(token);

  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/tokens/${token.id}`,
      {
        ...preparedTokenParameters
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function deleteToken(tokenId: number) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/tokens/${tokenId}`
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkDeleteTokens(params: BulkDeleteTokensParams) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/tokens/bulk-actions/delete`,
      {
        data: params
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkChangeTokensStatus(
  params: BulkChangeTokensStatusParams
) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/tokens/bulk-actions/change-status`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}
