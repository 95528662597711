<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import { type BadgeVariants, badgeVariants } from '@/components/ui/Badge/index';

import { cn } from '@/lib/utils';
import { STATUS_DEFAULT } from '@/constants/status';

const props = withDefaults(
  defineProps<{
    variant?: BadgeVariants['variant'];
    class?: HTMLAttributes['class'];
    shape?: BadgeVariants['shape'];
    size?: BadgeVariants['size'];
    isCounter?: boolean;
  }>(),
  {
    variant: STATUS_DEFAULT,
    class: null,
    shape: 'circular',
    size: 'small',
    isCounter: false
  }
);
</script>

<template>
  <div
    :class="cn(badgeVariants({ variant, shape, size, isCounter }), props.class)"
  >
    <slot />
  </div>
</template>
