<script setup lang="ts">
import { computed, inject } from 'vue';

import { CustomAlert } from '@/components/custom/CustomAlert';
import { SingleAlert } from '@/components/custom/AlertContainer';

import { STATUS_WARNING } from '@/constants/status';

import {
  type AllowedAlert,
  SELECTED_ALL_ROW_WARNING,
  V_HTML_ALERTS
} from '@/constants/alerts';

import type { ParseKeys } from 'i18next';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

const props = withDefaults(
  defineProps<{
    entityType: ParseKeys;
    isFetching?: boolean;
    alertsToShow: AllowedAlert[] | ReadonlyArray<AllowedAlert>;
  }>(),
  {
    isFetching: false
  }
);
const unfilteredCount =
  inject<UseAdvancedTable['unfilteredCount']>('unfilteredCount');

const alerts = computed(() =>
  props.alertsToShow
    .filter(alert =>
      alert === SELECTED_ALL_ROW_WARNING ? unfilteredCount?.value : true
    )
    .map(alert => ({
      alert,
      isHTML: V_HTML_ALERTS.includes(alert as (typeof V_HTML_ALERTS)[number])
    }))
);
</script>
<template>
  <CustomAlert
    v-if="!isFetching && alerts.length"
    :variant="STATUS_WARNING"
    :heading="`${$t('common.warning')}!`"
  >
    <div
      class="flex-col justify-start items-start gap-2.5 inline-flex text-yellow-800 text-sm max-h-52 overflow-y-auto"
    >
      <SingleAlert
        v-if="alerts.length === 1"
        :alert="alerts[0]"
        :entity-type="entityType"
      />

      <ul v-else class="list-disc list-inside flex flex-col gap-1">
        <li v-for="alert in alerts" :key="alert.alert">
          <SingleAlert :alert="alert" :entity-type="entityType" />
        </li>
      </ul>
    </div>
  </CustomAlert>
</template>
