<script setup lang="ts">
import { inject, type Ref } from 'vue';

import {
  Trash2,
  Settings,
  UserCheck2,
  MessageSquareText
} from 'lucide-vue-next';

import { CustomCollapsible } from '@/components/custom/CustomCollapsible';
import { CustomDropdownMenuItem } from '@/components/custom/CustomDropdownMenu';

import {
  openBulkCreateNotesModal,
  openBulkDeleteNotesModal
} from '@/lib/modals/notesModals';

import {
  openBulkChangeProductAccessModal,
  openBulkChangeUserGroupsModal,
  openBulkChangeUserLoginTypeModal,
  openBulkChangeUserStatusModal,
  openBulkDeleteAccountsModal
} from '@/lib/modals/accountsModals';

import type { CurrentUser } from '@/types/users';
import type { UseModal } from '@/types/modals';

const openModal = inject('openModal') as UseModal['openModal'];
const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>

<template>
  <div class="min-w-44 w-max">
    <CustomCollapsible
      :item-icon="UserCheck2"
      is-stretched
      is-seperated
      active-class="text-blue-600 stroke-blue-600"
      trigger-styles="justify-start"
      :trigger-label="$t('manageAccounts.set_statuses')"
    >
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageAccounts.activate_accounts')"
        @click="openBulkChangeUserStatusModal(openModal, true)"
      />
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageAccounts.deactivate_accounts')"
        @click="openBulkChangeUserStatusModal(openModal, false)"
      />
    </CustomCollapsible>

    <CustomCollapsible
      :item-icon="Settings"
      is-stretched
      is-seperated
      active-class="text-blue-600 stroke-blue-600"
      trigger-styles="justify-start"
      :trigger-label="$t('manageAccounts.update_settings')"
    >
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageAccounts.change_login_method')"
        @click="openBulkChangeUserLoginTypeModal(openModal)"
      />
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageAccounts.update_user_roles_types')"
        @click="openBulkChangeProductAccessModal(openModal, currentUser)"
      />
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageAccounts.update_groups')"
        @click="openBulkChangeUserGroupsModal(openModal)"
      />
    </CustomCollapsible>

    <CustomCollapsible
      :item-icon="MessageSquareText"
      is-stretched
      is-seperated
      active-class="text-blue-600 stroke-blue-600"
      trigger-styles="justify-start stroke-gray-800"
      :trigger-label="$t('manageAccounts.manage_notes')"
    >
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageAccounts.bulk_add_note_action')"
        @click="openBulkCreateNotesModal(openModal)"
      />
      <CustomDropdownMenuItem
        class="pl-6 text-red-500"
        :item-label="$t('manageAccounts.bulk_delete_note_action')"
        @click="openBulkDeleteNotesModal(openModal)"
      />
    </CustomCollapsible>

    <CustomDropdownMenuItem
      class="text-red-500"
      :item-label="$t('manageAccounts.delete_accounts')"
      :item-icon="Trash2"
      @click="openBulkDeleteAccountsModal(openModal)"
    />
  </div>
</template>
