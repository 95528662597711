<script setup lang="ts">
import { Button } from '@/components/ui/Button';
import { Heading } from '@/components/custom';

import { FileDown } from 'lucide-vue-next';

import { downloadExportFile } from '@/api/exportDownload';
import { STATUS_INFO } from '@/constants/status';

setTimeout(() => {
  downloadExportFile();
}, 1000);
</script>

<template>
  <Heading
    size="h3"
    class="mb-2.5 lg:mb-7 min-h-9 h-max font-medium md:text-3xl"
  >
    {{ $t('excel_export.download') }}
  </Heading>

  <section
    class="min-h-85 h-[calc(100dvh-12rem)] flex flex-col justify-center gap-9 items-center text-center px-12 py-16 bg-white border border-gray-200 rounded-md shadow"
  >
    <div
      class="flex justify-center items-center shrink-0 w-16 h-16 rounded-lg bg-emerald-100"
    >
      <FileDown
        class="relative w-8 h-8 stroke-emerald-800"
        stroke-width="1.5"
      />
    </div>

    <article class="flex flex-col lg:max-w-[90%] gap-3">
      <Heading size="h3" class="font-semibold lg:text-3xl">
        {{ $t('excel_export.file_is_ready') }}
      </Heading>

      <p class="text-[0.938rem] lg:text-lg font-normal text-gray-500">
        <span class="block">
          {{ $t('excel_export.download_cta') }}
        </span>
      </p>
      <p></p>
    </article>
    <Button
      :variant="STATUS_INFO"
      class="w-full md:w-min min-w-13"
      @click="downloadExportFile"
    >
      {{ $t('common.download') }}
    </Button>
  </section>
</template>
