<script setup lang="ts">
import { cn } from '@/lib/utils';

const props = defineProps<{
  size?: string;
  class?: string;
}>();
</script>

<template>
  <h1
    v-if="size === 'h1'"
    :class="cn('text-4xl text-gray-800 font-medium', props.class)"
  >
    <slot></slot>
  </h1>

  <h2
    v-else-if="size === 'h2'"
    :class="cn('text-3xl text-gray-800 font-medium', props.class)"
  >
    <slot></slot>
  </h2>

  <h3
    v-else-if="size === 'h3'"
    :class="cn('text-2xl text-gray-800 font-semibold', props.class)"
  >
    <slot></slot>
  </h3>

  <h4
    v-else-if="size === 'h4'"
    :class="cn('text-xl text-gray-800 font-semibold', props.class)"
  >
    <slot></slot>
  </h4>

  <h5
    v-else-if="size === 'h5'"
    :class="cn('text-lg text-gray-800 font-semibold', props.class)"
  >
    <slot></slot>
  </h5>

  <h6
    v-else-if="size === 'h6'"
    :class="cn('text-base text-gray-800 font-semibold', props.class)"
  >
    <slot></slot>
  </h6>

  <p v-else :class="cn('text-gray-800 font-semibold', props.class)">
    <slot></slot>
  </p>
</template>
