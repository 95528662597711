import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchGroup } from '@/api/group';

import { GROUP_API, INITIAL_GROUP } from '@/constants/api';

import type { Group, ListGroup } from '@/types/groups';

function useGroup(currentGroup: ListGroup) {
  const { data, isFetching, isError, isPlaceholderData } = useQuery({
    queryKey: [GROUP_API, currentGroup],
    queryFn: () => fetchGroup(currentGroup.id),
    select(data) {
      return data.data;
    }
  });

  const group = computed<Group>(() => {
    return data.value ?? INITIAL_GROUP;
  });

  return { group, isFetching, isError, isPlaceholderData };
}

export default useGroup;
