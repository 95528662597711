<script setup lang="ts">
import { inject, type Ref } from 'vue';

import { CustomAlert } from '@/components/custom/CustomAlert';
import { Heading } from '@/components/custom';
// import { ProgressCircle } from '@/components/custom/Charts';
import { StatisticCard } from '@/views/Dashboard/components';

import type { CurrentUser } from '@/types/users';
import { COMPONENT_VARIANTS } from '@/constants/component';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

// TODO: Uncomment Summarizer card when the requirements are ready
</script>

<template>
  <div class="flex gap-6 flex-col" data-testid="patents-api-statistics">
    <div class="flex flex-col gap-2.5">
      <Heading
        size="h4"
        class="text-lg md:text-xl"
        data-testid="patents-api-statistics-title"
      >
        {{ $t('organizationDashboard.patents_api_statistics') }}
      </Heading>

      <p
        class="text-sm md:text-base text-gray-500 font-medium"
        data-testid="iamip-platform-statistics-description"
      >
        {{ $t('organizationDashboard.patents_api_subtitle') }}
      </p>
    </div>

    <CustomAlert
      v-if="
        !currentUser.organizationStatistics.activeAiMonitorings &&
        !currentUser.organizationStatistics.limitAiMonitorings
      "
      :variant="COMPONENT_VARIANTS.info"
      :heading="$t('modalTitle.notice')"
      class="items-center"
      data-testid="statistics-inactive-ai-monitoring-alert"
    >
      <p class="font-normal">
        {{ $t('organizationDashboard.monitoring_ai_inactive') }}
      </p>
    </CustomAlert>

    <CustomAlert
      v-if="
        currentUser.organizationStatistics.limitAiMonitorings <=
          currentUser.organizationStatistics.activeAiMonitorings &&
        currentUser.organizationStatistics.limitAiMonitorings > 0
      "
      :variant="COMPONENT_VARIANTS.warning"
      :heading="$t('modalTitle.notice')"
      class="items-center"
      data-testid="statistics-ai-monitoring-limit-alert"
    >
      <p class="font-normal">
        {{ $t('organizationDashboard.monitoring_ai_limit_reached') }}
      </p>
    </CustomAlert>

    <div class="grid grid-cols-1 w-full justify-start items-start gap-5">
      <StatisticCard
        title="organizationDashboard.active_ai_monitorings"
        tooltip="organizationDashboard.active_ai_monitorings_info"
        data-testid="statistics-active-ai-monitorings"
      >
        <p class="text-gray-800 text-3xl font-medium">
          {{ currentUser.organizationStatistics.activeAiMonitorings }}
        </p>
      </StatisticCard>

      <!--      <StatisticCard-->
      <!--        title="organizationDashboard.summarizer_limit"-->
      <!--        tooltip="organizationDashboard.summarizer_limit_info"-->
      <!--        data-testid="statistics-summarizer-limit"-->
      <!--      >-->
      <!--        <ProgressCircle-->
      <!--          :current-value="-->
      <!--            currentUser.organizationStatistics.activeAiMonitorings-->
      <!--          "-->
      <!--          :max-value="currentUser.organizationStatistics.limitAiMonitorings"-->
      <!--          sub-title="common.current_limit"-->
      <!--        />-->
      <!--      </StatisticCard>-->
    </div>
  </div>
</template>
