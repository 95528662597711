<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { Primitive, type PrimitiveProps } from 'radix-vue';

import {
  type ButtonVariants,
  buttonVariants
} from '@/components/ui/Button/index';
import { LoadingSpinner } from '@/components/custom';

import { cn } from '@/lib/utils';

import {
  STATUS_DEFAULT,
  STATUS_DESTRUCTIVE,
  STATUS_GHOST
} from '@/constants/status';
import { ELEMENT_SIZES } from '@/constants/component';

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant'];
  size?: ButtonVariants['size'];
  as?: string;
  class?: HTMLAttributes['class'];
  isFetching?: boolean;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: STATUS_DEFAULT,
  size: ELEMENT_SIZES.default,
  as: 'button',
  class: null,
  isFetching: false,
  isDisabled: false
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class)"
    :disabled="isFetching || isDisabled"
  >
    <slot v-if="!isFetching" />
    <div v-else class="flex gap-2.5 align-center">
      <span>{{ $t('common.loading') }}... </span>
      <LoadingSpinner
        :class="
          cn(
            'w-5 h-5 border-[3px] border-white border-t-transparent',
            variant === STATUS_GHOST &&
              'border-iamip-accent-blue border-t-transparent',
            variant === STATUS_DESTRUCTIVE &&
              'border-red-400 border-t-transparent'
          )
        "
      />
    </div>
  </Primitive>
</template>
