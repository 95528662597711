<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import {
  SelectIcon,
  SelectTrigger,
  type SelectTriggerProps,
  useForwardProps
} from 'radix-vue';

import { AlertCircle, Check, ChevronDown } from 'lucide-vue-next';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

import { STATUS_ERROR, STATUS_SUCCESS } from '@/constants/status';
import type { ValidationStatuses } from '@/types/components';

const props = defineProps<
  SelectTriggerProps & {
    class?: HTMLAttributes['class'];
    isCompact?: boolean;
    validationStatus?: ValidationStatuses | string;
  }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectTrigger
    v-bind="forwardedProps"
    :class="
      cn(
        'flex relative h-11 w-full items-center justify-between rounded-lg border border-gray-200 shadow-sm bg-white px-4 py-3 pr-7 text-sm font-medium ring-offset-white data-[placeholder]:text-gray-500 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
        validationStatus === STATUS_ERROR &&
          'pr-12 border-red-500 focus:border-red-500 focus:ring-red-200',
        validationStatus === STATUS_SUCCESS &&
          'pr-9 border-emerald-500 focus:border-emerald-500 focus:ring-emerald-200',
        isCompact && 'justify-center',
        props.class
      )
    "
  >
    <slot />

    <div v-if="!isCompact" class="absolute right-2 flex gap-1.5">
      <AlertCircle
        v-if="validationStatus === STATUS_ERROR"
        class="ml-2 w-4 h-4 stroke-red-500 flex-shrink-0"
      />
      <Check
        v-if="validationStatus === STATUS_SUCCESS"
        class="ml-2 w-4 h-4 stroke-emerald-500 flex-shrink-0"
      />

      <SelectIcon as-child>
        <ChevronDown class="w-4 h-4 text-gray-500 flex-shrink-0" />
      </SelectIcon>
    </div>
  </SelectTrigger>
</template>
