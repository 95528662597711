<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { STATUS_DEFAULT } from '@/constants/status';

const iconVariants = cva(
  'm-1 inline-flex justify-center items-center rounded-full border-8',
  {
    variants: {
      variant: {
        default: 'border-gray-100 bg-gray-200 text-gray-800',
        destructive: 'border-red-100 bg-red-200 text-red-800',
        ghost: 'border border-gray-200 bg-white text-gray-700 shadow-sm',
        info: 'border-blue-50 bg-blue-100 text-blue-900',
        success: 'border-emerald-100 bg-emerald-200 text-emerald-800',
        warning: 'border-yellow-50 bg-yellow-100 text-yellow-800'
      },
      size: {
        default: 'size-11.5',
        lg: 'size-16'
      }
    },
    defaultVariants: {
      variant: STATUS_DEFAULT,
      size: STATUS_DEFAULT
    }
  }
);

export type IconVariants = VariantProps<typeof iconVariants>;

const props = withDefaults(
  defineProps<{
    class?: HTMLAttributes['class'];
    isAlert?: boolean;
    size?: IconVariants['size'];
    variant?: IconVariants['variant'];
  }>(),
  {
    class: null,
    isAlert: false,
    variant: STATUS_DEFAULT,
    size: STATUS_DEFAULT
  }
);
</script>

<template>
  <div
    :class="
      cn(
        iconVariants({ variant, size }),
        !isAlert && 'bg-transparent border-0',
        props.class
      )
    "
  >
    <slot></slot>
  </div>
</template>
