import { computed } from 'vue';
import { useSelectedIds } from '@/lib/hooks';
import { useQuery } from '@tanstack/vue-query';

import { fetchUsersSimplifiedWithNote } from '@/api/user';

import { USERS_SIMPLIFIED_NOTE_API } from '@/constants/api';

import type { UserSimplifiedWithNote } from '@/types/users';

function useUsersSimplifiedNote(useParams = false) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [USERS_SIMPLIFIED_NOTE_API, selectedIds],
    queryFn: () =>
      fetchUsersSimplifiedWithNote({
        user_ids: selectedIds.value
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedUsersWithNote = computed<UserSimplifiedWithNote[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedUsersWithNote, isError, isFetching };
}

export default useUsersSimplifiedNote;
