<script setup lang="ts">
import { AlertTriangle } from 'lucide-vue-next';

import { HTTP_CODES } from '@/constants/api';
import { ERROR_PAGE_CONTENT } from '@/constants/errors';

import type { AllowedHttpErrors } from '@/types/errors';
import type { ParseKeys } from 'i18next';

const props = defineProps<{
  errorCode: AllowedHttpErrors;
}>();
</script>

<template>
  <section
    class="min-h-85 h-[calc(100dvh-8rem)] lg:min-h-[calc(100dvh-12rem)] flex flex-col justify-center gap-7 lg:gap-9 items-center text-center px-8 sm:px-12 py-16 bg-white border border-gray-200 rounded-md shadow overflow-auto"
  >
    <div
      class="w-16 h-16 rounded-lg bg-red-100 flex justify-center items-center shrink-0"
    >
      <AlertTriangle
        class="relative w-8 h-8 stroke-red-800"
        stroke-width="1.5"
      />
    </div>

    <div class="flex flex-col gap-3 justify-center items-center text-center">
      <div class="text-black text-2xl lg:text-3xl font-semibold">
        {{ $t(ERROR_PAGE_CONTENT[props.errorCode].heading as ParseKeys) }}
      </div>

      <p class="text-gray-500 font-normal text-lg tracking-tight">
        {{ $t(ERROR_PAGE_CONTENT[props.errorCode].subtitle as ParseKeys) }}
      </p>
    </div>

    <div
      v-if="Number(props.errorCode) === HTTP_CODES.FORBIDDEN"
      class="w-full flex flex-col justify-center items-center gap-9"
    >
      <div
        class="flex flex-col justify-center items-center min-h-28 w-full sm:w-[90%] max-w-150 rounded-lg shadow-inner border border-gray-200"
      >
        <p class="text-sm lg:text-base w-[90%]">
          {{ $t('errorPage.forbidden_message') }}
        </p>
      </div>

      <p
        class="text-sm text-gray-500 font-normal"
        v-html="$t('errorPage.contact')"
      />
    </div>
  </section>
</template>
