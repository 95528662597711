import { computed, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchSingleOrganizationUserPreset } from '@/api/organizationUserPreset';

import { CREATE_EDIT_ACCOUNT_MODAL } from '@/constants/modals';
import { INITIAL_ORGANIZATION_USER_PRESET } from '@/constants/organizationUserPreset';
import { ORGANIZATION_USER_PRESETS_API } from '@/constants/api';

import type { SavedModalState } from '@/types/modals';
import type { OrganizationUserPreset } from '@/types/organizationUserPresets';

function useAccountsOrganizationUserPreset(
  user: Ref<SavedModalState<typeof CREATE_EDIT_ACCOUNT_MODAL>>
) {
  const presetId = computed(() => {
    return user.value.presetId;
  });

  const { data, isFetching } = useQuery({
    queryKey: [ORGANIZATION_USER_PRESETS_API, presetId],
    queryFn: () =>
      presetId.value
        ? fetchSingleOrganizationUserPreset(presetId.value)
        : {
            data: INITIAL_ORGANIZATION_USER_PRESET as unknown as OrganizationUserPreset
          },
    select(data) {
      return data.data;
    }
  });

  const organizationUserPreset = computed(() => {
    return data.value ?? INITIAL_ORGANIZATION_USER_PRESET;
  });

  return { organizationUserPreset, isFetching };
}

export default useAccountsOrganizationUserPreset;
