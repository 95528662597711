import { type VariantProps, cva } from 'class-variance-authority';
import { STATUS_DEFAULT } from '@/constants/status';

export { default as Alert } from '@/components/ui/Alert/Alert.vue';
export { default as AlertTitle } from '@/components/ui/Alert/AlertTitle.vue';
export { default as AlertDescription } from '@/components/ui/Alert/AlertDescription.vue';

export const alertVariants = cva(
  'relative w-full rounded-lg border border-slate-200 p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-0.188rem] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-slate-950',
  {
    variants: {
      variant: {
        default: 'bg-white text-slate-950',
        destructive: 'border-red-500/50 text-red-500 [&>svg]:text-red-500'
      }
    },
    defaultVariants: {
      variant: STATUS_DEFAULT
    }
  }
);

export type AlertVariants = VariantProps<typeof alertVariants>;
