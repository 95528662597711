<script setup lang="ts">
import { useVModel } from '@vueuse/core';

import { Label } from '@/components/ui/Label';

import { cn } from '@/lib/utils';

import {
  POSITION_LEFT,
  POSITION_RIGHT,
  POSITION_X_AXIS
} from '@/constants/layout';

import type { SwitchProps } from '@/types/components';

const props = withDefaults(defineProps<SwitchProps>(), {
  class: null,
  id: 'switch-input',
  isDisabled: false,
  labelPosition: POSITION_RIGHT,
  labelText: () => ({ left: '', right: '' }),
  modelValue: false,
  name: 'switch-input'
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'onClickHandler', event: Event): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits);
</script>

<template>
  <div class="flex items-center" @click.stop>
    <Label
      v-if="
        labelPosition === POSITION_X_AXIS || labelPosition === POSITION_LEFT
      "
      :for="id"
      class="me-3"
      >{{ labelText.left }}
    </Label>
    <input
      :id="id"
      v-model="modelValue"
      type="checkbox"
      role="switch"
      :disabled="isDisabled"
      :name="name"
      :class="
        cn(
          'relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:cursor-not-allowed checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200',
          props.class
        )
      "
      @click="$emit('onClickHandler', $event)"
    />
    <Label
      v-if="
        labelPosition === POSITION_X_AXIS || labelPosition === POSITION_RIGHT
      "
      :for="id"
      class="ms-3"
      >{{ labelText.right }}</Label
    >
  </div>
</template>
