import { h, type Ref } from 'vue';

import { Checkbox } from '@/components/custom';

import type { ColumnDef } from '@tanstack/vue-table';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';
import type { TFunction } from 'i18next';

function SelectColumn<T>({
  isAllSelected,
  isFetching,
  handleIsAllSelected,
  t
}: {
  isAllSelected: Ref<boolean>;
  isFetching: Ref<boolean>;
  handleIsAllSelected: UseAdvancedTable['handleIsAllSelected'];
  t: TFunction;
}): ColumnDef<T> {
  return {
    id: 'select',
    size: '5%' as unknown as number,
    header: ({ table }) =>
      h(Checkbox, {
        class: 'mx-auto',
        checked: isAllSelected.value,
        isDisabled: isFetching.value,
        'onUpdate:checked': (value: boolean) => {
          table.toggleAllRowsSelected(value);
          handleIsAllSelected();
        },
        ariaLabel: t('common.selectAll')
      }),
    cell: ({ row }) =>
      h(Checkbox, {
        class: 'mx-auto',
        checked: isAllSelected.value || row.getIsSelected(),
        isDisabled: isAllSelected.value,
        'onUpdate:checked': (value: boolean) => row.toggleSelected(value),
        ariaLabel: t('common.selectRow')
      }),
    enableSorting: false,
    enableHiding: false,
    enablePinning: true
  };
}

export default SelectColumn;
