import { LOGIN_EMAIL, LOGIN_SSO } from '@/constants/user';

import type { TFunction } from 'i18next';
import type { Filter } from '@/types/filtering';
import { ACCOUNTS_LIST, GROUPS_LIST, TOKENS_LIST } from '@/constants/table';

export const PATENTS_PLATFORM_FILTERING =
  'filter[patents_platform_available][operator]=is&filter[patents_platform_available][value]=1';

// Filter types
export const CREATED_AT_FILTER = 'created_at';
export const EMAIL_FILTER = 'email';
export const GROUP_FILTER = 'group';
export const FIRST_NAME_FILTER = 'first_name';
export const IDEA_DATABASE_ROLES_FILTER = 'idea_database_roles';
export const LAST_LOGIN_FILTER = 'last_login';
export const LAST_NAME_FILTER = 'last_name';
export const LOGIN_TYPE_FILTER = 'login_type';
export const NOTE_FILTER = 'note';
export const PATENTS_USER_TYPE = 'patents_role';
export const USER_STATUS_FILTER = 'status';

export const NAME_FILTER = 'name';
export const DESCRIPTION_FILTER = 'description';
export const USER_FILTER = 'user';

export const TOKEN_ACCESS_LEVEL_FILTER = 'type';
export const IP_ADDRESS_FILTER = 'ip_address';
export const TOKEN_STATUS_FILTER = 'active';

// Operators
export const AFTER_OPERATOR = 'after';
export const BEFORE_OPERATOR = 'before';
export const BETWEEN_OPERATOR = 'between';
export const CONTAINS_OPERATOR = 'contains';
export const IN_OPERATOR = 'in';
export const IS_NOT_OPERATOR = 'is not';
export const IS_OPERATOR = 'is';
export const NOT_IN_OPERATOR = 'not in';
export const ON_OPERATOR = 'on';

export const DEFAULT_FILTER_OPTIONS_COUNT = 0;

export const DATE_FILTER = [
  ON_OPERATOR,
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  BETWEEN_OPERATOR
] as const;

export const ARRAY_FILTER = [IN_OPERATOR, NOT_IN_OPERATOR] as const;

export const NUMBER_FILTER = [IS_OPERATOR, IS_NOT_OPERATOR] as const;

export const TEXT_FILTER = [
  IS_OPERATOR,
  IS_NOT_OPERATOR,
  CONTAINS_OPERATOR
] as const;

export const STATUS_FILTER = [IS_OPERATOR] as const;

export const FILTERING_POPUP_INITIAL_STATE: Filter = {
  filterType: '',
  operator: '',
  value: '',
  values: [],
  dateRange: {
    start: new Date(),
    end: new Date()
  }
};

// Select options
export const ACCOUNTS_FILTERS = [
  CREATED_AT_FILTER,
  EMAIL_FILTER,
  FIRST_NAME_FILTER,
  GROUP_FILTER,
  IDEA_DATABASE_ROLES_FILTER,
  LAST_NAME_FILTER,
  LAST_LOGIN_FILTER,
  LOGIN_TYPE_FILTER,
  NOTE_FILTER,
  PATENTS_USER_TYPE,
  USER_STATUS_FILTER
] as const;

export const GROUPS_FILTERS = [
  NAME_FILTER,
  DESCRIPTION_FILTER,
  USER_FILTER
] as const;

export const TOKENS_FILTERS = [
  NAME_FILTER,
  TOKEN_ACCESS_LEVEL_FILTER,
  IP_ADDRESS_FILTER,
  TOKEN_STATUS_FILTER
] as const;

export const LIST_FILTERS = {
  [ACCOUNTS_LIST]: ACCOUNTS_FILTERS,
  [GROUPS_LIST]: GROUPS_FILTERS,
  [TOKENS_LIST]: TOKENS_FILTERS
} as const;

export const FILTERING_OPERATORS = {
  columns: {
    [CREATED_AT_FILTER]: DATE_FILTER,
    [EMAIL_FILTER]: TEXT_FILTER,
    [FIRST_NAME_FILTER]: TEXT_FILTER,
    [GROUP_FILTER]: ARRAY_FILTER,
    [IDEA_DATABASE_ROLES_FILTER]: [CONTAINS_OPERATOR],
    [LAST_NAME_FILTER]: TEXT_FILTER,
    [LAST_LOGIN_FILTER]: DATE_FILTER,
    [LOGIN_TYPE_FILTER]: NUMBER_FILTER,
    [NOTE_FILTER]: TEXT_FILTER,
    [PATENTS_USER_TYPE]: NUMBER_FILTER,
    [USER_STATUS_FILTER]: STATUS_FILTER,
    [NAME_FILTER]: TEXT_FILTER,
    [DESCRIPTION_FILTER]: TEXT_FILTER,
    [USER_FILTER]: ARRAY_FILTER,
    [TOKEN_ACCESS_LEVEL_FILTER]: NUMBER_FILTER,
    [IP_ADDRESS_FILTER]: [CONTAINS_OPERATOR],
    [TOKEN_STATUS_FILTER]: STATUS_FILTER
  }
} as const;

export const SELECT_FILTERING_TYPES = [
  LOGIN_TYPE_FILTER,
  PATENTS_USER_TYPE,
  USER_STATUS_FILTER,
  TOKEN_ACCESS_LEVEL_FILTER,
  TOKEN_STATUS_FILTER
] as const;

export const MULTISELECT_FILTERING_TYPES = [
  GROUP_FILTER,
  IDEA_DATABASE_ROLES_FILTER,
  USER_FILTER
] as const;

export const FILTER_TRANSLATIONS = {
  [CREATED_AT_FILTER]: 'manageAccounts.created_at',
  [EMAIL_FILTER]: 'manageAccounts.email',
  [FIRST_NAME_FILTER]: 'manageAccounts.first_name',
  [GROUP_FILTER]: 'manageAccounts.groups',
  [IDEA_DATABASE_ROLES_FILTER]: 'manageAccounts.idea_database_roles',
  [LAST_NAME_FILTER]: 'manageAccounts.last_name',
  [LAST_LOGIN_FILTER]: 'manageAccounts.last_login',
  [LOGIN_TYPE_FILTER]: 'manageAccounts.login_type',
  [NOTE_FILTER]: 'manageAccounts.note',
  [PATENTS_USER_TYPE]: 'manageAccounts.patents_platform_user_type',
  [USER_STATUS_FILTER]: 'manageAccounts.user_status',
  [NAME_FILTER]: 'common.name',
  [DESCRIPTION_FILTER]: 'manageGroups.description',
  [USER_FILTER]: 'manageGroups.group_members',
  [TOKEN_ACCESS_LEVEL_FILTER]: 'manageTokens.type',
  [IP_ADDRESS_FILTER]: 'manageTokens.allowed_ip_addresses',
  [TOKEN_STATUS_FILTER]: 'common.status'
} as const;

export const prepareLoginTypeOptions = (t: TFunction) => [
  { label: t('manageAccounts.email'), value: LOGIN_EMAIL.toString() },
  { label: t('manageAccounts.SSO'), value: LOGIN_SSO.toString() }
];

export const prepareStatusOptions = (t: TFunction) => [
  { label: t('common.enabled'), value: '1' },
  { label: t('common.disabled'), value: '0' }
];

export const prepareTokenAccessTypeOptions = (t: TFunction) =>
  [
    { label: t('common.read_only'), value: '1' },
    { label: t('common.write_only'), value: '2' },
    { label: t('common.read_write'), value: '3' }
  ] as const;
