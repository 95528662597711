import { AccessLevels } from '@/types/containers';

export const NAMESPACE_PORTFOLIO = 'portfolio';
export const NAMESPACE_MONITORING = 'search-alert';

export const ACCESS_LEVEL_NAMES = {
  [AccessLevels.VIEW_ONLY]: 'accessLevel.view_only',
  [AccessLevels.READ_ONLY]: 'accessLevel.read_only',
  [AccessLevels.READ_WRITE]: 'accessLevel.read_write',
  [AccessLevels.EXTENDED]: 'accessLevel.extended',
  [AccessLevels.OWNED]: 'accessLevel.owned'
} as const;
