<script setup lang="ts">
import { computed, inject, provide } from 'vue';
import { AxiosError } from 'axios';

import { useGroups } from '@/lib/hooks';
import { useToast } from 'vue-toastification';
import { useTranslation } from 'i18next-vue';

import { exportGroupsToExcel } from '@/api/group';

import { AdvancedTable } from '@/components/custom/AdvancedTable';
import { BulkGroupActions } from '@/views/Groups/components';
import { Heading } from '@/components/custom';
import { TablePlaceholder } from '@/components/ui/Table';

import {
  openCreateGroupModal,
  openViewGroupModal
} from '@/lib/modals/groupsModals';

import { groupColumns } from '@/views/Groups/components/GroupColumns';
import { parseError } from '@/lib/helpers';

import type { UseModal } from '@/types/modals';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

const { t } = useTranslation();

const sortedColumns = inject(
  'sortedColumns'
) as UseAdvancedTable['sortedColumns'];
const isAllSelected = inject(
  'isAllSelected'
) as UseAdvancedTable['isAllSelected'];
const handleSearchQueryChange = inject(
  'handleSearchQueryChange'
) as UseAdvancedTable['handleSearchQueryChange'];
const handleSortingUpdate = inject(
  'handleSortingUpdate'
) as UseAdvancedTable['handleSortingUpdate'];
const handleIsAllSelected = inject(
  'handleIsAllSelected'
) as UseAdvancedTable['handleIsAllSelected'];

const { groups, isFetching } = useGroups();

provide('isFetching', isFetching);

const columns = computed(() =>
  groupColumns({
    isFetching,
    isAllSelected,
    handleSortingUpdate,
    handleIsAllSelected,
    sortedColumns,
    t,
    total: groups.value.total
  })
);

const openModal = inject('openModal') as UseModal['openModal'];
const toast = useToast();

async function handleExportGroupsToExcel(group_ids: number[]) {
  const response = await exportGroupsToExcel({ group_ids });

  if (response instanceof AxiosError) {
    toast.error(parseError(response));
  } else {
    toast.success(response.message);
  }
}
</script>

<template>
  <Heading size="h3" class="mb-7 min-h-9 h-max font-medium md:text-3xl"
    >{{ $t('manageGroups.manage_groups') }}
  </Heading>
  <AdvancedTable
    class="lg:h-[calc(100dvh-12rem)]"
    :is-export-available="true"
    :table-data="groups"
    :columns="columns"
    create-data-translation="manageAccounts.create_new_group"
    :total="groups.total"
    :on-search="handleSearchQueryChange"
    @row-click="openViewGroupModal(openModal, $event)"
    @create-new-item="openCreateGroupModal(openModal)"
    @export-items="handleExportGroupsToExcel"
  >
    <template #bulk-actions-options>
      <BulkGroupActions />
    </template>

    <template #empty-page>
      <TablePlaceholder
        create-new-item-text="manageGroups.create_new_group"
        not-found-text="manageGroups.no_groups_found"
        add-first-text="manageGroups.add_first_group"
        @create-new-item="openCreateGroupModal(openModal)"
      />
    </template>
  </AdvancedTable>
</template>
