<script setup lang="ts">
import { Trash2 } from 'lucide-vue-next';

import { CustomDropdownMenuItem } from '@/components/custom/CustomDropdownMenu';

import EditIcon from 'assets/icons/edit-05.svg?component';
import { openBulkChangeTokensStatusModal, openBulkDeleteTokensModal } from "@/lib/modals/tokensModals";
import { inject } from "vue";
import type { UseModal } from "@/types/modals";
import { CustomCollapsible } from "@/components/custom/CustomCollapsible";

const openModal = inject('openModal') as UseModal['openModal'];
</script>
<template>
  <div class="min-w-44 w-max">
    <CustomCollapsible
      :item-icon="EditIcon"
      is-stretched
      is-seperated
      active-class="text-blue-600 stroke-blue-600"
      trigger-styles="justify-start"
      :trigger-label="$t('manageTokens.change_tokens_status')"
    >
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageTokens.activate_tokens')"
        @click="openBulkChangeTokensStatusModal(openModal, true)"
      />
      <CustomDropdownMenuItem
        class="pl-6"
        :item-label="$t('manageTokens.deactivate_tokens')"
        @click="openBulkChangeTokensStatusModal(openModal, false)"
      />
    </CustomCollapsible>
    <CustomDropdownMenuItem
      class="text-red-500"
      :item-label="$t('manageTokens.delete_tokens')"
      :item-icon="Trash2"
      @click="openBulkDeleteTokensModal(openModal)"
    />
  </div>
</template>
