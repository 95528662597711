export const DEV_ENV = 'dev';
export const PROD_ENV = 'prod';
export const STAGE_ENV = 'stage';

export const DEVELOPMENT_MODE = 'development';
export const PRODUCTION_MODE = 'production';

export const ECHO_BROADCASTER = 'socket.io';
export const ECHO_TRANSPORTS = ['websocket', 'polling', 'flashsocket'] as const;

export type TEnv = typeof DEV_ENV | typeof PROD_ENV | typeof STAGE_ENV;
export type TMode = typeof DEVELOPMENT_MODE | typeof PRODUCTION_MODE;

export const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: 1
      }
    }
  }
} as const;
