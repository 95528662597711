import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from '@/components/ui/Button/Button.vue';
import { STATUS_DEFAULT } from '@/constants/status';

export const buttonVariants = cva(
  'py-3 px-4 inline-flex justify-center items-center gap-x-2 min-w-max font-medium rounded-lg border border-transparent cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out',
  {
    variants: {
      variant: {
        default:
          'bg-gray-800 text-white hover:bg-gray-900 disabled:hover:bg-gray-800',
        destructive:
          'bg-white text-red-500 border border-red-500 font-semibold hover:bg-red-500 hover:text-white disabled:hover:bg-white disabled:hover:text-red-500',
        info: 'bg-iamip-accent-blue text-white hover:bg-blue-700 disabled:hover:bg-iamip-accent-blue',
        infoOutlined:
          'bg-white text-iamip-accent-blue border border-iamip-accent-blue font-semibold hover:bg-iamip-accent-blue hover:text-white disabled:hover:bg-white disabled:hover:text-iamip-accent-blue',
        success:
          'bg-emerald-500 text-white hover:bg-emerald-600 disabled:hover:bg-emerald-500',
        warning:
          'bg-amber-500 text-white hover:bg-amber-600 disabled:hover:bg-amber-500',
        ghost:
          'border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:hover:bg-white',
        icon: 'bg-transparent border-0 h-min w-min'
      },
      size: {
        default: 'h-13 px-4 py-3.5 text-[0.938rem]',
        sm: 'h-11 rounded-md px-3 text-sm',
        lg: 'h-16 rounded-md px-5 py-5.5 text-base',
        icon: 'h-min w-min text-sm p-0'
      }
    },
    defaultVariants: {
      variant: STATUS_DEFAULT,
      size: STATUS_DEFAULT
    }
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
