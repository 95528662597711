<script setup lang="ts">
import { ref } from 'vue';

import { ChevronDown } from 'lucide-vue-next';

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/Collapsible';

import { Button } from '@/components/ui/Button';
import { Separator } from '@/components/ui/Separator';

import { cn } from '@/lib/utils';

import { ELEMENT_SIZES, COMPONENT_VARIANTS } from '@/constants/component';

import type { CustomCollapsibleProps } from '@/types/components';

const props = withDefaults(defineProps<CustomCollapsibleProps>(), {
  class: null,
  isButtonTrigger: false,
  isSeperated: false,
  activeClass: null,
  isStretched: false,
  isFetching: false,
  chevronClass: null,
  triggerLabel: 'Collapse',
  triggerSize: ELEMENT_SIZES.small,
  triggerStyles: ''
});

const isOpen = ref<boolean>(false);
</script>

<template>
  <Collapsible v-model:open="isOpen" :class="cn('w-full', props.class)">
    <CollapsibleTrigger as-child>
      <Button
        type="button"
        :variant="COMPONENT_VARIANTS.ghost"
        :size="props.triggerSize"
        :class="
          cn(
            'w-full px-2 py-0',
            !isButtonTrigger && 'border-0 shadow-none outline-none',
            triggerStyles,
            isOpen && activeClass,
            isStretched && 'justify-between'
          )
        "
        @click.stop
      >
        <div class="flex flex-row gap-2.5 items-center">
          <component
            :is="itemIcon"
            v-if="itemIcon"
            class="w-4 h-4 stroke-1"
            stroke-width="1.5"
          />
          <span>{{ triggerLabel }}</span>
          <slot v-if="$slots.badge" name="badge" />
        </div>

        <ChevronDown
          :class="
            cn(
              'w-4 h-4 transition-transform transform',
              isOpen && 'rotate-180',
              isFetching ? 'pointer-events-none' : 'cursor-pointer',
              chevronClass
            )
          "
          stroke-width="{2}"
        />
      </Button>
    </CollapsibleTrigger>

    <CollapsibleContent class="bg-white rounded-lg">
      <!--  INFO: Do not add padding or margin to the CollapsibleContent directly -->
      <!-- Possibly a bug: https://github.com/radix-ui/primitives/discussions/583   -->
      <slot></slot>
      <Separator v-if="isSeperated" />
    </CollapsibleContent>
  </Collapsible>
</template>
