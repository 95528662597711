<script setup lang="ts">
import { computed, inject, provide } from 'vue';
import { useTokens } from '@/lib/hooks/tokens';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

import { useTranslation } from 'i18next-vue';

import { AdvancedTable } from '@/components/custom/AdvancedTable';
import { BulkTokenActions } from '@/views/Tokens/components';
import { Heading } from '@/components/custom';
import { TablePlaceholder } from '@/components/ui/Table';

import { tokenColumns } from '@/views/Tokens/components/TokenColumns';
import {
  openCreateTokenModal,
  openViewTokenModal
} from '@/lib/modals/tokensModals';

import type { UseModal } from '@/types/modals';

const { t } = useTranslation();

const sortedColumns = inject(
  'sortedColumns'
) as UseAdvancedTable['sortedColumns'];
const isAllSelected = inject(
  'isAllSelected'
) as UseAdvancedTable['isAllSelected'];
const handleSearchQueryChange = inject(
  'handleSearchQueryChange'
) as UseAdvancedTable['handleSearchQueryChange'];
const handleSortingUpdate = inject(
  'handleSortingUpdate'
) as UseAdvancedTable['handleSortingUpdate'];
const handleIsAllSelected = inject(
  'handleIsAllSelected'
) as UseAdvancedTable['handleIsAllSelected'];

const { tokens, isFetching } = useTokens();

provide('isFetching', isFetching);

const columns = computed(() =>
  tokenColumns({
    isFetching,
    isAllSelected,
    handleSortingUpdate,
    handleIsAllSelected,
    sortedColumns,
    t,
    total: tokens.value.total
  })
);

const openModal = inject('openModal') as UseModal['openModal'];
</script>

<template>
  <Heading size="h3" class="mb-7 min-h-9 h-max font-medium md:text-3xl"
    >{{ $t('manageTokens.manage_tokens') }}
  </Heading>
  <AdvancedTable
    class="lg:h-[calc(100dvh-12rem)]"
    :is-export-available="false"
    :table-data="tokens"
    :columns="columns"
    action-column-class="px-0 p-0"
    create-data-translation="manageTokens.create_new_token"
    :total="tokens.total"
    :on-search="handleSearchQueryChange"
    @row-click="openViewTokenModal(openModal, $event)"
    @create-new-item="openCreateTokenModal(openModal)"
  >
    <template #bulk-actions-options>
      <BulkTokenActions />
    </template>

    <template #empty-page>
      <TablePlaceholder
        create-new-item-text="manageTokens.create_new_token"
        not-found-text="manageTokens.not_found"
        add-first-text="manageTokens.add_first_token"
        @create-new-item="openCreateTokenModal(openModal)"
      />
    </template>
  </AdvancedTable>
</template>
