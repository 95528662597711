import { t } from '@/i18next';

import {
  STATUS_DEFAULT,
  STATUS_DESTRUCTIVE,
  STATUS_GHOST,
  STATUS_INFO,
  STATUS_SUCCESS,
  STATUS_WARNING
} from '@/constants/status';

import type {
  AvatarVariants,
  ButtonVariants,
  ComponentVariants,
  ElementSizes
} from '@/types/components';

import type { ParseKeys, TFunction } from 'i18next';

// Component related
export const SIDE_OFFSET = 4;
export const TEXTAREA_DEFAULT_ROWS = 3;
export const NAVIGATION_NEXT = 'next';
export const NAVIGATION_PREV = 'prev';
export const VARIANT_ICON = 'icon';
export const VARIANT_INFO_OUTLINED = 'infoOutlined';

// Progress bar related
export const DEFAULT_MAX_PROGRESS = 100;
export const DEFAULT_MIN_PROGRESS = 0;

// Component variants
export const COMPONENT_VARIANTS: Record<ComponentVariants, ComponentVariants> =
  {
    default: STATUS_DEFAULT,
    destructive: STATUS_DESTRUCTIVE,
    ghost: STATUS_GHOST,
    info: STATUS_INFO,
    success: STATUS_SUCCESS,
    warning: STATUS_WARNING
  } as const;

export const BUTTON_VARIANTS: Record<ButtonVariants, ButtonVariants> = {
  ...COMPONENT_VARIANTS,
  icon: VARIANT_ICON,
  infoOutlined: VARIANT_INFO_OUTLINED
};

export const AVATAR_VARIANTS: Record<AvatarVariants, AvatarVariants> = {
  default: STATUS_DEFAULT,
  blue: 'blue',
  violet: 'violet'
} as const;

// Component properties
export const ALERT_HEADINGS = (t: TFunction) =>
  ({
    default: '',
    destructive: t('modalTitle.error'),
    ghost: '',
    info: t('modalTitle.notice'),
    success: t('modalTitle.success'),
    warning: t('modalTitle.warning')
  }) as const;

export const ELEMENT_SIZES: Record<
  typeof STATUS_DEFAULT | 'small' | 'icon' | 'large',
  ElementSizes
> = {
  default: STATUS_DEFAULT,
  small: 'sm',
  icon: 'icon',
  large: 'lg'
} as const;

export const INPUT_MESSAGES = (attributeKey: ParseKeys, minSize = 1) => ({
  errorMessage: t(
    minSize === 1 ? 'validation.min.string_single' : 'validation.min.string',
    {
      attribute: (t(attributeKey) as string).toLowerCase(),
      min: minSize
    }
  ),
  successMessage: t('common.success')
});

export const IP_ADDRESS_MESSAGE = (attributeKey: ParseKeys) => ({
  errorMessage: t('validation.ip', {
    attribute: (t(attributeKey) as string).toLowerCase()
  }),
  successMessage: t('common.success')
});

export const EMAIL_ADDRESS_MESSAGE = (attributeKey: ParseKeys) => ({
  errorMessage: t('auth.invalidEmail', {
    attribute: (t(attributeKey) as string).toLowerCase()
  }),
  successMessage: t('common.success')
});

export const MULTISELECT_MESSAGES = (attributeKey: ParseKeys, minSize = 1) => ({
  errorMessage: t('validation.min.users', {
    attribute: (t(attributeKey) as string).toLowerCase(),
    min: minSize
  }),
  successMessage: t('common.success')
});

export const SELECT_MESSAGES = (attributeKey: ParseKeys) => ({
  errorMessage: t('validation.min.select', {
    attribute: (t(attributeKey) as string).toLowerCase()
  }),
  successMessage: t('common.success')
});

export const CONFIRM_PASSWORD_MESSAGES = () => ({
  errorMessage: t('validation.password_not_match'),
  successMessage: t('common.success')
});

export const CONFIRM_PASSWORD_CRITERIA_MESSAGES = () => ({
  errorMessage: t('validation.criteria_not_matching'),
  successMessage: t('common.success')
});
