<script setup lang="ts">
import { inject } from 'vue';

import { Button } from '@/components/ui/Button';

import { openUpdateUserPasswordModal } from '@/lib/modals/userSettingsModals';

import { COMPONENT_VARIANTS } from '@/constants/component';

import type { UseModal } from '@/types/modals';

const openModal = inject('openModal') as UseModal['openModal'];
</script>

<template>
  <Button
    class="w-full md:min-w-25 md:w-max"
    type="button"
    :variant="COMPONENT_VARIANTS.info"
    @click="openUpdateUserPasswordModal(openModal)"
  >
    <span class="block mt-0.5">{{ $t('common.update') }}</span>
  </Button>
</template>
