import { computed } from 'vue';

import { createValidationData } from '@/lib/validation';

import type { AllowedValidationKeys, ValidationData } from '@/types/validation';
import type { Ref } from 'vue';

function useCreateValidationData<T extends ValidationData>(
  validationData: Ref<T[]>,
  dataKey: AllowedValidationKeys = 'user_ids'
) {
  return computed(() =>
    createValidationData(
      validationData.value.map(({ id }) => id),
      validationData.value,
      dataKey
    )
  );
}

export default useCreateValidationData;
