<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits
} from 'radix-vue';

import { X } from 'lucide-vue-next';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  DialogContentProps & {
    class?: HTMLAttributes['class'];
  }
>();
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-gray-900/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />

    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'font-poppins fixed left-1/2 top-1/2 z-50 rounded-xl sm:rounded-2xl min-w-min max-w-[90vw] -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
          props.class
        )
      "
    >
      <slot />

      <DialogClose
        class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500"
      >
        <X class="w-5 h-5" stroke-width="{1}" />
        <span class="sr-only">{{ $t('manageTokens.close') }}</span>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
