<script setup lang="ts">
import { cn } from '@/lib/utils';

import type { HTMLAttributes } from 'vue';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>
<template>
  <div class="flex justify-center">
    <div
      :class="
        cn(
          'animate-spin inline-block size-20 border-[9px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500',
          props.class
        )
      "
      role="status"
      aria-label="loading"
    />
  </div>
</template>
