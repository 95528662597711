import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchOrganizationUserPresets } from '@/api/organizationUserPreset';

import { ORGANIZATION_USER_PRESETS_API } from '@/constants/api';

function useOrganizationUserPresets() {
  const { data, isFetching, isError } = useQuery({
    queryKey: [ORGANIZATION_USER_PRESETS_API],
    queryFn: fetchOrganizationUserPresets,
    select(data) {
      return data.data;
    }
  });

  const organizationUserPresets = computed(() => {
    return data.value ?? [];
  });

  return { organizationUserPresets, isFetching, isError };
}

export default useOrganizationUserPresets;
