import axios from 'axios';
import request from '@/api/base';

import type { ApiErrorResponse, ApiSimpleResponse } from '@/types/api';
import type { PasswordConfirmationParams } from '@/api/profile/types';

export async function uploadOrganizationLogo(
  organizationId: number,
  file: File
) {
  return await axios
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization/${organizationId}/logo`,
      { image: file },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function deleteOrganizationLogo(organizationId: number) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization/${organizationId}/logo`
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function forceOrganizationTwoFactor(
  organizationId: number,
  params?: PasswordConfirmationParams
) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization/${organizationId}/force-two-factor`,
      {
        password: params?.password
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function disableOrganizationTwoFactor(
  organizationId: number,
  params?: PasswordConfirmationParams
) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/organization/${organizationId}/disable-two-factor`,
      {
        password: params?.password
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}
