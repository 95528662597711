import { defineAsyncComponent } from 'vue';

export const IdeaDatabaseStatistic = defineAsyncComponent(
  () => import('@/views/Dashboard/components/IdeaDatabaseStatistic.vue')
);

export const OrganizationData = defineAsyncComponent(
  () => import('@/views/Dashboard/components/OrganizationData.vue')
);

export const PatentsAPIStatistic = defineAsyncComponent(
  () => import('@/views/Dashboard/components/PatentsAPIStatistic.vue')
);

export const PatentsPlatformStatistic = defineAsyncComponent(
  () => import('@/views/Dashboard/components/PatentsPlatformStatistic.vue')
);

export const StatisticCard = defineAsyncComponent(
  () => import('@/views/Dashboard/components/StatisticCard.vue')
);

export const UserProfile = defineAsyncComponent(
  () => import('@/views/Dashboard/components/UserProfile.vue')
);
