import {
  ForceTwoFactorOrganization,
  ForceTwoFactorOrganizationFooter,
  OrganizationUserPresets,
  UpdateOrganizationLogo,
  UpdateOrganizationLogoFooter
} from '@/views/Organization/components';

export const ORGANIZATION_PRODUCTS = {
  IAMIP_PATENTS_PLATFORM: '1',
  IAMIP_IDEA_DATABASE: '2',
  IAMIP_PATENTS_API_CONTAINERS: '3'
} as const;

export type OrganizationProducts =
  (typeof ORGANIZATION_PRODUCTS)[keyof typeof ORGANIZATION_PRODUCTS];

export const ORGANIZATION_SETTINGS_SECTIONS = {
  'update-logo': {
    id: 'update-logo',
    title: 'organization.update_logo',
    description: 'organization.update_logo_description',
    mainComponent: UpdateOrganizationLogo,
    hasFooter: true,
    footerComponent: UpdateOrganizationLogoFooter
  },
  'force-two-factor': {
    id: 'force-two-factor',
    title: 'organizationDashboard.force_two_factor_authentication',
    description:
      'organizationDashboard.force_two_factor_authentication_description',
    mainComponent: ForceTwoFactorOrganization,
    hasFooter: true,
    footerComponent: ForceTwoFactorOrganizationFooter
  },
  'user-presets': {
    id: 'user-presets',
    title: 'organization.manage_user_presets',
    description: 'organization.user_presets_section_description',
    mainComponent: OrganizationUserPresets,
    hasFooter: false,
    footerComponent: null
  }
} as const;
