import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useSelectedIds } from '@/lib/hooks';

import { fetchUsersSimplifiedWithLoginType } from '@/api/user';

import { USERS_SIMPLIFIED_LOGIN_TYPE_API } from '@/constants/api';

import type { UserSimplifiedWithLoginType } from '@/types/users';

function useUsersSimplifiedLoginType(useParams = false) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [USERS_SIMPLIFIED_LOGIN_TYPE_API, selectedIds],
    queryFn: () =>
      fetchUsersSimplifiedWithLoginType({
        user_ids: selectedIds.value
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedUsersWithLoginType = computed<UserSimplifiedWithLoginType[]>(
    () => {
      return data.value ?? [];
    }
  );

  return { simplifiedUsersWithLoginType, isError, isFetching };
}

export default useUsersSimplifiedLoginType;
