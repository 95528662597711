import { defineAsyncComponent } from 'vue';

export const UserProfileSettings = defineAsyncComponent(
  () => import('@/views/Settings/components/UserProfileSettings.vue')
);

export const UserProfileSettingsEdit = defineAsyncComponent(
  () => import('@/views/Settings/components/UserProfileSettingsEdit.vue')
);

export const UpdatePassword = defineAsyncComponent(
  () => import('@/views/Settings/components/UpdatePassword.vue')
);

export const SessionInfoBlock = defineAsyncComponent(
  () => import('@/views/Settings/components/SessionInfoBlock.vue')
);

export const TwoFactorSettings = defineAsyncComponent(
  () => import('@/views/Settings/components/TwoFactorSettings/TwoFactorSettings.vue')
);

export const TwoFactorSettingsFooter = defineAsyncComponent(
  () => import('@/views/Settings/components/TwoFactorSettings/TwoFactorSettingsFooter.vue')
);

export const BrowserSessions = defineAsyncComponent(
  () => import('@/views/Settings/components/BrowserSessions.vue')
);

export const BrowserSessionsFooter = defineAsyncComponent(
  () => import('@/views/Settings/components/BrowserSessionsFooter.vue')
);
