import { defineAsyncComponent } from 'vue';

export const ForceTwoFactorOrganization = defineAsyncComponent(
  () =>
    import(
      '@/views/Organization/components/ForceTwoFactorOrganization/ForceTwoFactorOrganization.vue'
    )
);

export const ForceTwoFactorOrganizationFooter = defineAsyncComponent(
  () =>
    import(
      '@/views/Organization/components/ForceTwoFactorOrganization/ForceTwoFactorOrganizationFooter.vue'
    )
);

export const OrganizationUserPresetActions = defineAsyncComponent(
  () =>
    import('@/views/Organization/components/OrganizationUserPresetActions.vue')
);

export const OrganizationUserPresets = defineAsyncComponent(
  () => import('@/views/Organization/components/OrganizationUserPresets.vue')
);

export const UpdateOrganizationLogo = defineAsyncComponent(
  () =>
    import(
      '@/views/Organization/components/UpdateOrganizationLogo/UpdateOrganizationLogo.vue'
    )
);

export const UpdateOrganizationLogoFooter = defineAsyncComponent(
  () =>
    import(
      '@/views/Organization/components/UpdateOrganizationLogo/UpdateOrganizationLogoFooter.vue'
    )
);
