<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';

import {
  SelectItem,
  SelectItemIndicator,
  type SelectItemProps,
  SelectItemText,
  useForwardProps
} from 'radix-vue';

import { Check, LockKeyhole } from 'lucide-vue-next';

import { ConditionalTooltip } from '@/components/ui/Tooltip';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

import { ALLOWED_BREAKPOINTS, POSITION_RIGHT } from '@/constants/layout';

import type { ParseKeys } from 'i18next';

const props = defineProps<
  SelectItemProps & {
    class?: HTMLAttributes['class'];
    disabledContent?: ParseKeys;
  }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);

const forwardedProps = useForwardProps(delegatedProps);
const isDesktop = useMediaQuery(ALLOWED_BREAKPOINTS.lg);
</script>

<template>
  <ConditionalTooltip
    :should-show="Boolean(disabledContent && isDesktop && disabled)"
    :content="disabledContent ? $t(disabledContent) : ''"
    :placement="POSITION_RIGHT"
  >
    <SelectItem
      v-bind="forwardedProps"
      :class="
        cn(
          'relative flex w-full cursor-default select-none items-center rounded-lg py-2 pr-8 pl-2 text-[0.938rem] outline-none focus:bg-blue-50 focus:text-slate-900 hover:cursor-pointer data-[disabled]:gray-500 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
          props.class
        )
      "
    >
      <slot name="select-item-icon"></slot>

      <SelectItemText>
        <slot name="select-item-text" />
      </SelectItemText>

      <span
        class="absolute right-2 flex h-3.5 w-3.5 items-center justify-center"
      >
        <LockKeyhole
          v-if="disabled"
          class="w-4 h-4"
          stroke-width="1.5"
          data-testid="select-item-lock-icon"
        />
        <SelectItemIndicator v-else>
          <Check
            class="h-4 w-4 stroke-iamip-accent-blue"
            data-testid="select-item-check-icon"
          />
        </SelectItemIndicator>
      </span>
    </SelectItem>
  </ConditionalTooltip>
</template>
