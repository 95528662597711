<script setup lang="ts">
import { type HTMLAttributes } from 'vue';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/DropdownMenu';

import { cn } from '@/lib/utils';

import type {
  AutoCloseOptions,
  ElementAlignment,
  ElementPosition
} from '@/types/components';

const props = defineProps<{
  contentAlignment?: ElementAlignment;
  sideOffset?: number;
  autoClose?: AutoCloseOptions;
  dropdownClass?: HTMLAttributes['class'];
  contentPosition?: ElementPosition;
  triggerClass?: HTMLAttributes['class'];
}>();
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger :class="cn(props.dropdownClass)">
      <slot name="trigger"></slot>
    </DropdownMenuTrigger>
    <DropdownMenuContent
      :align="contentAlignment"
      :side="contentPosition"
      :side-offset="sideOffset"
    >
      <slot name="menu-header"></slot>
      <slot name="menu-options"></slot>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
