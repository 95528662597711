<script setup lang="ts" generic="T extends string | number">
import { computed, type HTMLAttributes } from 'vue';
import { useVModel } from '@vueuse/core';

import Multiselect from '@vueform/multiselect';
import { ChevronDown, X, Check, AlertCircle } from 'lucide-vue-next';

import SearchIcon from 'assets/icons/search-sm.svg?component';

import { cn } from '@/lib/utils';
import { prepareMessage } from '@/lib/helpers';

import {
  STATUS_DEFAULT,
  STATUS_ERROR,
  STATUS_SUCCESS
} from '@/constants/status';

import type {
  SelectOption,
  ValidationMessages,
  ValidationStatuses
} from '@/types/components';

const props = withDefaults(
  defineProps<{
    class?: HTMLAttributes['class'];
    validationStatus?: ValidationStatuses;
    modelValue:
      | string[]
      | number[]
      | ReadonlyArray<string>
      | ReadonlyArray<number>;
    showErrorMessage?: boolean;
    messages?: ValidationMessages | undefined;
    options: SelectOption<T>[];
    placeholder?: string;
    loading?: boolean;
  }>(),
  {
    messages: () => ({
      errorMessage: 'An error has occurred.',
      successMessage: 'Success!'
    }),
    showErrorMessage: true,
    validationStatus: STATUS_DEFAULT,
    placeholder: 'Type value to search',
    class: null,
    loading: false
  }
);

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string): void;
  (e: 'onOptionRemove', option: string | number): void;
}>();

const inputMessage = computed(() =>
  prepareMessage(props.validationStatus, props.messages)
);

const modelValue = useVModel(props, 'modelValue', emits);
</script>
<template>
  <div class="w-full">
    <Multiselect
      v-model="modelValue"
      mode="multiple"
      :placeholder="$t('common.input_value')"
      :close-on-select="false"
      :clear-on-select="false"
      :options="options"
      :hide-selected="false"
      :disabled="loading"
      :loading="loading"
      :searchable="true"
      :classes="{
        container: cn(
          'relative text-sm h-11 rounded-lg border shadow-sm',
          loading && 'opacity-50',
          validationStatus === STATUS_DEFAULT
            ? 'border-gray-200'
            : 'border-red-500'
        ),
        containerActive: cn(
          'ring-2 ring-offset-0 outline-none border',
          validationStatus === STATUS_DEFAULT
            ? 'border-blue-500 ring-blue-100'
            : 'border-red-500 ring-red-100'
        ),
        wrapper: cn(
          'multiselect-wrapper h-10 rounded-lg overflow-hidden',
          loading && 'cursor-not-allowed'
        ),
        input:
          'w-full absolute inset-0 outline-none focus:ring-0 focus:border-none appearance-none box-border border-0 text-[0.938rem] bg-white rounded-md pl-3.5 rtl:pl-0 rtl:pr-3.5',
        option:
          'flex items-center justify-start box-border text-left cursor-pointer break-word text-sm py-2 px-3 hover:bg-blue-50',
        spinner: 'multiselect-spinner bg-iamip-accent-blue'
      }"
    >
      <template #placeholder>
        <div class="multiselect-placeholder text-sm font-medium text-gray-500">
          <SearchIcon class="w-3.5 h-3.5 mr-1.5 stroke-gray-500" />
          <span>{{ placeholder }}</span>
        </div>
      </template>
      <template #caret="{ handleCaretClick, isOpen }">
        <AlertCircle
          v-if="validationStatus === STATUS_ERROR"
          class="w-4 h-4 stroke-red-500 mr-1 relative pointer-events-none flex-shrink-0 flex-grow-0"
        />
        <ChevronDown
          :class="
            cn(
              'h-4 mr-1 relative text-gray-500 pointer-events-none transition-transform transform rtl:mr-0 rtl:ml-3.5 flex-shrink-0 flex-grow-0',
              isOpen && 'rotate-180 pointer-events-auto'
            )
          "
          @click="handleCaretClick"
        />
      </template>
      <template #clear="{ clear }">
        <X
          class="w-4 h-4 mr-3 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5"
          @click="clear"
        />
      </template>
      <template v-if="$slots.option" #option="{ option, isSelected }">
        <slot name="option" :option="option" :is-selected="isSelected" />
      </template>
      <template v-else #option="{ option, isSelected }">
        <p>{{ option.label }}</p>
        <Check
          v-if="(isSelected as unknown as (option: any) => boolean)(option)"
          class="h-4 w-4 stroke-iamip-accent-blue shrink-0 ml-auto"
        />
      </template>
    </Multiselect>

    <p
      v-if="inputMessage && showErrorMessage"
      :class="
        cn(
          'text-sm mt-1',
          validationStatus === STATUS_ERROR && 'text-red-600',
          validationStatus === STATUS_SUCCESS && 'text-emerald-600'
        )
      "
    >
      {{ inputMessage }}
    </p>
  </div>
</template>
