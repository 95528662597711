import { h } from 'vue';

import { AsyncSwitch } from '@/components/custom';
import { Button } from '@/components/ui/Button';
import { TokensActions } from '@/views/Tokens/components';
import SelectColumn from '@/components/custom/AdvancedTable/SelectColumn';

import { getColumnSorting, getSortingIcon } from '@/lib/sorting';
import { changeTokenStatus } from '@/api/token';

import { TOKENS_API } from '@/constants/api';
import { BUTTON_VARIANTS } from '@/constants/component';

import type { ApiResponse, ApiTableResponse } from '@/types/api';

import type { ColumnDef } from '@tanstack/vue-table';
import type { ColumnsParams } from '@/types/table';
import type { ListToken } from '@/types/tokens';
import type { ParseKeys } from 'i18next';

function updateTokenList(
  oldData: ApiTableResponse<unknown> | undefined,
  response: ApiResponse<unknown>
) {
  return {
    ...(oldData as ApiTableResponse<ListToken>),
    data: (oldData as ApiTableResponse<ListToken>).data.map(token => {
      if (
        token.id ===
        (
          response as ApiResponse<{
            token_id: number;
          }>
        ).data.token_id
      ) {
        return {
          ...token,
          active: !token.active
        };
      }

      return token;
    })
  };
}

export const tokenColumns = ({
  isFetching,
  isAllSelected,
  sortedColumns,
  handleSortingUpdate,
  handleIsAllSelected,
  t,
  total
}: ColumnsParams): ColumnDef<ListToken>[] => [
  SelectColumn({
    isAllSelected,
    isFetching,
    handleIsAllSelected,
    t
  }),
  {
    accessorKey: 'name',
    header: ({ header }) => {
      return h(
        Button,
        {
          class: 'text-xs font-semibold text-inherit uppercase px-0 py-0 h-min',
          isDisabled: isFetching.value || !total,
          variant: BUTTON_VARIANTS.icon,
          title: t('manageTokens.sortByName'),
          onClick: () => {
            handleSortingUpdate(getColumnSorting(sortedColumns, header.id));
          }
        },
        () => [
          t('common.name'),
          h(getSortingIcon(sortedColumns, header.id), {
            class: 'text-gray-800 h-4 w-4'
          })
        ]
      );
    },
    cell: ({ row }) =>
      h(
        'p',
        {
          class: 'break-word'
        },
        row.getValue('name')
      )
  },
  {
    accessorKey: 'accessLevel',
    header: () => {
      return h(
        Button,
        {
          class: 'text-xs font-semibold text-inherit uppercase px-0 py-0 h-min',
          isDisabled: isFetching.value || !total,
          variant: BUTTON_VARIANTS.icon,
          title: t('manageTokens.sortByType'),
          onClick: () => {
            handleSortingUpdate(
              getColumnSorting(sortedColumns, 'access_level')
            );
          }
        },
        () => [
          t('manageTokens.type'),
          h(getSortingIcon(sortedColumns, 'access_level'), {
            class: 'text-gray-800 h-4 w-4'
          })
        ]
      );
    },
    cell: ({ row }) => h('p', t(row.getValue('accessLevel') as ParseKeys))
  },
  {
    accessorKey: 'allowedIpAddresses',
    size: '30%' as unknown as number,
    header: () => h('div', t('manageTokens.allowed_ip_addresses')),
    cell: ({ row }) => {
      const ipAddresses = (row.getValue('allowedIpAddresses') as string) || '';

      return h('p', {}, ipAddresses);
    }
  },
  {
    accessorKey: 'active',
    size: '10%' as unknown as number,
    header: () => h('p', t('common.status')),
    cell: ({ row }) => {
      return h(AsyncSwitch, {
        'model-value': row.getValue('active'),
        invalidateQueryKey: TOKENS_API,
        onChangeUpdate: updateTokenList,
        onChangeHandle: changeTokenStatus.bind(null, row.original.id)
      });
    }
  },
  {
    size: '10%' as unknown as number,
    accessorKey: 'actions',
    header: () => h('p', t('common.listActions')),
    cell: ({ row }) => h(TokensActions, { token: row.original })
  }
];
