<script setup lang="ts">
import { inject, type Ref, ref } from 'vue';

import { Button } from '@/components/ui/Button';

import { emitterInstance } from '@/lib/helpers';
import { COMPONENT_VARIANTS } from '@/constants/component';
import type { UseModal } from '@/types/modals';

const isFetchingUserSettings = inject<Ref<boolean>>('isFetchingUserSettings');
const setIsLoading = inject('setIsLoading') as UseModal['setIsLoading'];

const isUpdate = ref(false);

emitterInstance().on('user-profile-update-success', () => {
  isUpdate.value = false;
  setIsLoading(false);
});
emitterInstance().on('user-profile-update-error', () => {
  isUpdate.value = true;
  setIsLoading(false);
});
</script>

<template>
  <Button
    v-if="!isUpdate"
    class="w-full md:min-w-25 md:w-max gap-4"
    type="button"
    :is-fetching="isFetchingUserSettings"
    :variant="COMPONENT_VARIANTS.info"
    @click="
      emitterInstance().emit('activate-user-profile');
      isUpdate = true;
    "
  >
    <span class="block mt-0.5">{{ $t('settings.edit') }}</span>
  </Button>

  <div
    v-else
    class="w-full flex flex-col-reverse md:flex-row md:justify-end gap-4"
  >
    <Button
      class="w-full md:min-w-25 md:w-max gap-4"
      :is-fetching="isFetchingUserSettings"
      :variant="COMPONENT_VARIANTS.ghost"
      @click="
        emitterInstance().emit('deactivate-user-profile');
        isUpdate = false;
      "
    >
      <span class="block mt-0.5">{{ $t('common.cancel') }}</span>
    </Button>

    <Button
      class="w-full md:min-w-25 md:w-max"
      type="button"
      :is-fetching="isFetchingUserSettings"
      :variant="COMPONENT_VARIANTS.info"
      @click="emitterInstance().emit('update-user-profile')"
    >
      <span class="block mt-0.5">{{ $t('common.update') }}</span>
    </Button>
  </div>
</template>
