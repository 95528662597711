<script setup lang="ts">
import { inject, type Ref } from 'vue';

import { AlertContainer } from '@/components/custom';
import { Badge } from '@/components/ui/Badge';

import { COMPONENT_VARIANTS } from '@/constants/component';
import { ORGANIZATION_TWO_FACTOR_FORCED } from '@/constants/alerts';

import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>

<template>
  <section class="flex flex-col gap-7">
    <AlertContainer
      v-if="!currentUser.organization.twofaForced"
      :alerts-to-show="[ORGANIZATION_TWO_FACTOR_FORCED]"
      entity-type="organization.two_factor_force_warning"
    />

    <Badge
      class="px-3 py-3.5 h-10 w-max"
      :variant="
        currentUser.organization.twofaForced
          ? COMPONENT_VARIANTS.success
          : COMPONENT_VARIANTS.default
      "
      size="medium"
    >
      <span class="uppercase">
        {{ $t('common.status') }}:
        <strong>
          {{
            currentUser.organization.twofaForced
              ? $t('common.on')
              : $t('common.off')
          }}
        </strong>
      </span>
    </Badge>
  </section>
</template>
