<script setup lang="ts">
import { type Component, inject } from 'vue';

import { VisuallyHidden } from 'radix-vue';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/Dialog';

import { StaticIcon } from '@/components/custom';

import { cn } from '@/lib/utils';

import { STATUS_INFO } from '@/constants/status';

import type { IconVariants } from '@/components/custom/StaticIcon.vue';
import type { UseModal } from '@/types/modals';

/*
 * @INFO: Please use the DialogClose component in the slots, to use buttons to close the dialog window
 * */
const props = withDefaults(
  defineProps<{
    description?: string;
    dialogIcon?: Component | string;
    iconVariant?: IconVariants['variant'];
    isAlert?: boolean;
    hasLargeGap?: boolean;
    title?: string;
    isOpen: boolean;
    class?: string;
  }>(),
  {
    description: '',
    dialogIcon: '',
    hasLargeGap: false,
    iconVariant: STATUS_INFO,
    isAlert: false,
    title: '',
    isOpen: false,
    class: ''
  }
);
const closeModal = inject('closeModal') as UseModal['closeModal'];
</script>

<template>
  <Dialog :open="isOpen" @update:open="closeModal">
    <VisuallyHidden>
      <DialogTitle :aria-describedby="title || undefined" />
    </VisuallyHidden>
    <DialogContent
      :class="
        cn(
          'max-h-[90dvh] overflow-y-auto w-full',
          isAlert && 'text-center gap-4 sm:max-w-150',
          props.class
        )
      "
    >
      <section
        :class="
          cn('grid gap-4 lg:gap-5 p-7 lg:p-10', hasLargeGap && 'gap-6 lg:gap-6')
        "
      >
        <div>
          <StaticIcon
            v-if="isAlert"
            class="mx-auto"
            :is-alert="isAlert"
            size="lg"
            :variant="iconVariant"
          >
            <component :is="dialogIcon" class="w-6 h-6" stroke-width="{1}" />
          </StaticIcon>
          <DialogHeader class="mt-2.5">
            <DialogTitle :class="isAlert && 'text-2xl text-center'">
              {{ title }}
            </DialogTitle>
            <DialogDescription
              v-show="description"
              :class="cn('mt-3', isAlert && 'text-center')"
            >
              {{ description }}
            </DialogDescription>
          </DialogHeader>
        </div>
        <slot name="dialog-content"></slot>
      </section>
      <DialogFooter v-if="!isAlert">
        <slot name="dialog-footer"></slot>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
