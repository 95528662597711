import { computed, inject, type Ref } from 'vue';

import { getSelectedIds } from '@/lib/filtering';

import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

function useSelectedIds(
  isBulkAction: boolean = false
): Ref<number[] | undefined> {
  const rowSelection = inject<UseAdvancedTable['rowSelection']>('rowSelection');
  const currentFilteredIds =
    inject<UseAdvancedTable['currentFilteredIds']>('currentFilteredIds');
  const isAllSelected =
    inject<UseAdvancedTable['isAllSelected']>('isAllSelected');

  return computed(() =>
    isBulkAction
      ? getSelectedIds({
          rowSelection,
          currentFilteredIds,
          isAllSelected
        })
      : undefined
  );
}

export default useSelectedIds;
