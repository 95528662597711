// Position related
export const POSITION_BOTTOM = 'bottom';
export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';
export const POSITION_TOP = 'top';
export const POSITION_X_AXIS = 'x_axis';

export const BREAKPOINT_HORIZONTAL_MOBILE = '(max-height: 600px)';

export const BREAKPOINT_MD = '(min-width: 768px)';
export const BREAKPOINT_LG = '(min-width: 1024px)';
export const BREAKPOINT_XL = '(min-width: 1280px)';
export const BREAKPOINT_2XL = '(min-width: 1536px)';
export const BREAKPOINT_3XL = '(min-width: 1920px)';
export const BREAKPOINT_4XL = '(min-width: 2560px)';
export const BREAKPOINT_5XL = '(min-width: 3840px)';

export const ALLOWED_BREAKPOINTS = {
  md: BREAKPOINT_MD,
  lg: BREAKPOINT_LG,
  xl: BREAKPOINT_XL,
  '2xl': BREAKPOINT_2XL,
  '3xl': BREAKPOINT_3XL,
  '4xl': BREAKPOINT_4XL,
  '5xl': BREAKPOINT_5XL
} as const;
