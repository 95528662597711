import { useMediaQuery } from '@vueuse/core';

import { ALLOWED_BREAKPOINTS } from '@/constants/layout';

function useLoadingData(itemsInColumn = 3): number[] {
  const isLargeScreen = useMediaQuery(ALLOWED_BREAKPOINTS.lg);
  const isXLargeScreen = useMediaQuery(ALLOWED_BREAKPOINTS.xl);

  if (isXLargeScreen.value) {
    return Array.from({ length: itemsInColumn * 3 }, (_, i) => i);
  }

  return isLargeScreen.value
    ? Array.from({ length: itemsInColumn * 2 }, (_, i) => i)
    : Array.from({ length: itemsInColumn }, (_, i) => i);
}

export default useLoadingData;
