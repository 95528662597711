<script setup lang="ts">
import type { HTMLAttributes } from 'vue';

import { cn } from '@/lib/utils';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>

<template>
  <thead
    :class="
      cn('sticky top-0 z-10 bg-slate-50 shadow-table-shadow', props.class)
    "
  >
    <slot />
  </thead>
</template>
