import {
  BrowserSessions,
  UpdatePassword,
  UserProfileSettings,
  TwoFactorSettingsFooter,
  BrowserSessionsFooter,
  UserProfileSettingsEdit,
  TwoFactorSettings
} from '@/views/Settings/components';
import { STATUS_DEFAULT } from '@/constants/status';

export const INITIAL_USER_PROFILE_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  languagePreference: ['', '', ''],
  language: ''
};

export const INITIAL_USER_PROFILE_VALIDATION = {
  first_name: STATUS_DEFAULT,
  last_name: STATUS_DEFAULT,
  email: STATUS_DEFAULT,
  language: STATUS_DEFAULT,
  language_preference: STATUS_DEFAULT
};

export const USER_SETTINGS_SECTIONS = {
  'user-profile-settings': {
    id: 'user-profile-settings',
    title: 'userProfile.Profile Information',
    description: 'userProfile.Profile Information Description',
    mainComponent: UserProfileSettings,
    contentClass: null,
    footerClass: 'flex-row-reverse',
    hasFooter: true,
    footerComponent: UserProfileSettingsEdit
  },
  'update-password': {
    id: 'update-password',
    title: 'userProfile.Update Password',
    description: 'userProfile.Update Password Description',
    mainComponent: null,
    contentClass: 'md:p-3',
    footerClass: 'flex-row-reverse',
    hasFooter: true,
    footerComponent: UpdatePassword
  },
  'two-factor-settings': {
    id: 'two-factor-settings',
    title: 'userProfile.Two Factor Authentication',
    description: 'userProfile.Two Factor Authentication Description',
    mainComponent: TwoFactorSettings,
    contentClass: null,
    footerClass: null,
    hasFooter: true,
    footerComponent: TwoFactorSettingsFooter
  },
  'browser-sessions': {
    id: 'browser-sessions',
    title: 'userProfile.Browser Sessions',
    description: 'userProfile.Browser Sessions Description',
    mainComponent: BrowserSessions,
    hasFooter: true,
    contentClass: null,
    footerClass: 'flex-row-reverse',
    footerComponent: BrowserSessionsFooter
  }
} as const;
