import { cva, type VariantProps } from 'class-variance-authority';
import { STATUS_DEFAULT } from '@/constants/status';

export { default as Textarea } from '@/components/ui/Textarea/Textarea.vue';

export const textareaVariants = cva(
  'py-3 px-4 block w-full shadow-sm rounded-lg text-[0.938rem] font-medium focus:ring-2 disabled:opacity-50 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default:
          'border border-gray-200 focus:border-blue-500 focus:ring-blue-100 outline-blue-500',
        error:
          'pr-8 border-red-500 outline-red-500 focus:border-red-500 focus:ring-red-100',
        success:
          'pr-8 border-emerald-500 outline-emerald-500 focus:border-emerald-500 focus:ring-emerald-100'
      }
    },
    defaultVariants: {
      variant: STATUS_DEFAULT
    }
  }
);

export type TextareaVariants = VariantProps<typeof textareaVariants>;
