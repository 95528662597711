import { type VariantProps, cva } from 'class-variance-authority';
import { STATUS_DEFAULT } from '@/constants/status';

export { default as CustomAlert } from '@/components/custom/CustomAlert/CustomAlert.vue';

export const alertVariants = cva('rounded-lg p-4 text-sm border', {
  variants: {
    variant: {
      default: 'bg-gray-100 border-gray-200 text-gray-800',
      destructive: 'bg-red-100 border-red-200 text-red-800',
      ghost: 'bg-white border-gray-200',
      info: 'bg-blue-50 border-blue-100 text-blue-900',
      success: 'bg-emerald-100 border-emerald-200 text-emerald-800',
      warning: 'bg-yellow-100 border-yellow-200 text-yellow-800'
    }
  },
  defaultVariants: {
    variant: STATUS_DEFAULT
  }
});

export type AlertVariants = VariantProps<typeof alertVariants>;
