import {
  MANAGE_ACCOUNT_ROUTE,
  MANAGE_GROUPS_ROUTE,
  MANAGE_TOKEN_ROUTE
} from '@/constants/routes';

import type { AllowedRoutes } from '@/constants/routes';
import type { SortedColumns } from '@/types/sorting';

export const SORT_ASCENDING = 'ASC';
export const SORT_DESCENDING = 'DESC';

export const ACCOUNTS_INITIAL_SORTING: SortedColumns = [
  {
    name: 'full_name',
    order: SORT_ASCENDING,
    isActive: true
  },
  {
    name: 'last_login',
    order: SORT_ASCENDING,
    isActive: false
  }
] as const;

export const GROUPS_INITIAL_SORTING: SortedColumns = [
  {
    name: 'name',
    order: SORT_ASCENDING,
    isActive: true
  },
  {
    name: 'description',
    order: SORT_ASCENDING,
    isActive: false
  }
] as const;

export const TOKENS_INITIAL_SORTING: SortedColumns = [
  {
    name: 'name',
    order: SORT_ASCENDING,
    isActive: true
  },
  {
    name: 'access_level',
    order: SORT_ASCENDING,
    isActive: false
  }
] as const;

export function getInitialSorting(route: AllowedRoutes): SortedColumns {
  switch (route) {
    case MANAGE_ACCOUNT_ROUTE:
      return ACCOUNTS_INITIAL_SORTING;
    case MANAGE_GROUPS_ROUTE:
      return GROUPS_INITIAL_SORTING;
    case MANAGE_TOKEN_ROUTE:
      return TOKENS_INITIAL_SORTING;
    default:
      return [] as const;
  }
}
