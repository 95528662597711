<script setup lang="ts" generic="T">
import { cn } from '@/lib/utils';

import { type AllowedColumns, renderRow } from '@/constants/table';

import type { HTMLAttributes } from 'vue';

defineProps<{
  cell: T;
  updatedCell: T;
  class: HTMLAttributes['class'];
  columnKey: AllowedColumns;
}>();

function shouldAddClass(cell: T, cellValue: unknown): boolean {
  return Array.isArray(cell) ? !cell.includes(cellValue) : cell !== cellValue;
}
</script>

<template>
  <template v-if="!Array.isArray(cell)">
    <span :class="cn(shouldAddClass(updatedCell as T, cell) && $props.class)">
      {{ renderRow(cell) }}
    </span>
  </template>
  <template
    v-for="(cellValue, index) in cell"
    v-else
    :key="cellValue as string"
  >
    <span
      :class="cn(shouldAddClass(updatedCell as T, cellValue) && $props.class)"
    >
      {{ renderRow(cellValue) }}
    </span>
    <span v-if="index !== (cell as unknown[]).length - 1">, </span>
  </template>
</template>
