<script setup lang="ts">
import { inject } from 'vue';

import { Trash2, Ellipsis } from 'lucide-vue-next';

import {
  CustomDropdownMenu,
  CustomDropdownMenuItem
} from '@/components/custom/CustomDropdownMenu';

import { Button } from '@/components/ui/Button';

import EditIcon from 'assets/icons/edit-05.svg?component';

import {
  openDeleteOrganizationUserPresetModal,
  openEditOrganizationUserPresetModal
} from '@/lib/modals/organizationUserPresetsModals';

import { BUTTON_VARIANTS } from '@/constants/component';

import type { UseModal } from '@/types/modals';
import type { OrganizationUserPresetSimplified } from '@/types/organizationUserPresets';

defineProps<{
  organizationUserPreset: OrganizationUserPresetSimplified;
}>();

const openModal = inject('openModal') as UseModal['openModal'];
</script>

<template>
  <div class="flex justify-center" @click.stop>
    <CustomDropdownMenu>
      <template #trigger>
        <Button type="button" :variant="BUTTON_VARIANTS.icon">
          <Ellipsis class="w-6 h-6 text-gray-800" />
        </Button>
      </template>

      <template #menu-options>
        <CustomDropdownMenuItem
          item-class="stroke-1"
          :item-label="$t('manageAccounts.edit_preset')"
          :item-icon="EditIcon"
          @click="
            openEditOrganizationUserPresetModal(
              openModal,
              organizationUserPreset
            )
          "
        />
        <CustomDropdownMenuItem
          class="text-red-500"
          :item-label="$t('manageAccounts.delete_preset')"
          :item-icon="Trash2"
          @click="
            openDeleteOrganizationUserPresetModal(
              openModal,
              organizationUserPreset
            )
          "
        />
      </template>
    </CustomDropdownMenu>
  </div>
</template>
