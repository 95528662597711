import { computed } from 'vue';
import { useSelectedIds } from '@/lib/hooks';
import { useQuery } from '@tanstack/vue-query';

import { fetchGroupsSimplified } from '@/api/group';

import { GROUPS_SIMPLIFIED_API } from '@/constants/api';

import type { GroupSimplified } from '@/types/groups';

function useGroupsSimplified(useParams = false) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [GROUPS_SIMPLIFIED_API, selectedIds],
    queryFn: () =>
      fetchGroupsSimplified({
        group_ids: selectedIds.value
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedGroups = computed<GroupSimplified[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedGroups, isError, isFetching };
}

export default useGroupsSimplified;
