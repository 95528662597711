<script setup lang="ts">
import { Tippy } from 'vue-tippy';

import { Badge } from '@/components/ui/Badge';

import { COMPONENT_VARIANTS } from '@/constants/component';
import { POSITION_TOP } from '@/constants/layout';

const props = defineProps<{
  users: string[];
  displayList: string[];
}>();
</script>

<template>
  <div
    v-if="props.displayList.length < props.users.length"
    class="flex gap-1.5 items-center"
  >
    <Tippy
      :content="props.users.slice(props.displayList.length).join(', ')"
      :placement="POSITION_TOP"
    >
      <Badge
        :variant="COMPONENT_VARIANTS.ghost"
        class="shrink-0 h-7 px-2.5 cursor-pointer"
        is-counter
      >
        <span>{{ `+${props.users.length - props.displayList.length}` }}</span>
      </Badge>
    </Tippy>
  </div>
</template>
