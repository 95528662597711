import type { PluginOptions } from 'vue-toastification';

export const TOAST_LIMIT = 10;
export const TOAST_REMOVE_DELAY = 10000;

export const TOAST_ACTION_TYPES = {
  ADD_TOAST: 'ADD_TOAST',
  UPDATE_TOAST: 'UPDATE_TOAST',
  DISMISS_TOAST: 'DISMISS_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST'
} as const;

export const TOAST_OPTIONS: PluginOptions = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: TOAST_LIMIT,
  newestOnTop: true,
  timeout: TOAST_REMOVE_DELAY,
  toastClassName: 'custom-toast-container',
  bodyClassName: 'custom-toast-body'
};
