import {
  ArrowDownUpIcon,
  ArrowDownWideNarrow,
  ArrowUpNarrowWide
} from 'lucide-vue-next';

import { SORT_ASCENDING, SORT_DESCENDING } from '@/constants/sorting';

import type {
  SortedColumns,
  SortingOrder,
  SortingParams,
  SortingReturn
} from '@/types/sorting';

import type { Ref } from 'vue';

export function getColumnSorting(
  sortedColumns: Ref<SortedColumns>,
  columnName: string
): SortedColumns {
  return sortedColumns.value.map(column => ({
    ...column,
    order:
      column.name === columnName && column.order === SORT_ASCENDING
        ? SORT_DESCENDING
        : SORT_ASCENDING,
    isActive: column.name === columnName
  }));
}

export function getSortingIconValue(order: SortingOrder, isActive = true) {
  if (!isActive) {
    return ArrowDownUpIcon;
  }
  return order === SORT_ASCENDING ? ArrowUpNarrowWide : ArrowDownWideNarrow;
}

export function getSortingIcon(
  sortedColumns: Ref<SortedColumns>,
  columnName: string
) {
  const currentColumn = sortedColumns.value.find(
    column => column.name === columnName
  );

  return getSortingIconValue(
    currentColumn?.order ?? SORT_ASCENDING,
    currentColumn?.isActive ?? false
  );
}

export function sortAsc<T = string>({
  a,
  b,
  descOrder
}: SortingParams<T>): SortingReturn {
  if (a === b) return 0;
  if (a === null || a === undefined) return descOrder ? -1 : 1;
  if (b === null || b === undefined) return descOrder ? 1 : -1;
  if (a < b) return -1;

  return 1;
}

export function sortingFunction<T = string>({
  a,
  b,
  descOrder,
  caseInsensitive
}: SortingParams<T> & {
  caseInsensitive: boolean;
}): SortingReturn {
  const sortA = caseInsensitive && typeof a === 'string' ? a.toLowerCase() : a;
  const sortB = caseInsensitive && typeof b === 'string' ? b.toLowerCase() : b;

  if (descOrder) {
    return -sortAsc<T | string>({
      a: sortA,
      b: sortB,
      descOrder
    }) as SortingReturn;
  }

  return sortAsc<T | string>({
    a: sortA,
    b: sortB,
    descOrder
  });
}

export function alphabeticalSorting<T>({
  array,
  param,
  descOrder = false,
  caseInsensitive = true
}: {
  array: T[];
  param: false | keyof T;
  descOrder?: boolean;
  caseInsensitive?: boolean;
}): T[] {
  // If array is empty, return it as is
  if (!array.length) {
    return array;
  }

  // If there is no param, sort the array items itself
  if (typeof param === 'boolean') {
    return array.slice().sort((a, b) =>
      sortingFunction<T>({
        a,
        b,
        descOrder,
        caseInsensitive
      })
    );
  }

  // If there is a param, sort the array items by that param
  return array.slice().sort((a, b) =>
    sortingFunction<T[typeof param]>({
      a: a[param],
      b: b[param],
      descOrder,
      caseInsensitive
    })
  );
}
