import { INFORMATION_MODAL, INVALID_DATA_MODAL } from '@/constants/modals';

import type { UseModal } from '@/types/modals';
import type { PromiseResolve } from '@/types/helpers';
import type { AllowedApiKeys } from '@/constants/api';
import type { ParseKeys } from 'i18next';

export function openInformationModal(
  openModal: UseModal['openModal'],
  message: string,
  description?: string
) {
  async function confirmAction(resolve: PromiseResolve) {
    resolve(true);
  }

  openModal<typeof INFORMATION_MODAL>(INFORMATION_MODAL, {
    confirmAction,
    useStatusConfirmation: false,
    message,
    description
  });
}

export function openInvalidDataModal(
  openModal: UseModal['openModal'],
  invalidateQueryKey: AllowedApiKeys,
  message?: ParseKeys
) {
  async function confirmAction(resolve: PromiseResolve) {
    resolve(true);
  }

  openModal<typeof INVALID_DATA_MODAL>(INVALID_DATA_MODAL, {
    confirmAction,
    useStatusConfirmation: false,
    invalidateQueryKey: invalidateQueryKey,
    message
  });
}
