<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { Separator, type SeparatorProps } from 'radix-vue';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  SeparatorProps & { class?: HTMLAttributes['class'] }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <Separator
    v-bind="delegatedProps"
    :class="
      cn(
        'shrink-0 bg-slate-200 dark:bg-slate-800',
        props.orientation === 'vertical' ? 'w-px h-full' : 'h-px w-full',
        props.class
      )
    "
  />
</template>
