<script setup lang="ts">
import { inject, type Ref } from 'vue';
import { useTranslation } from 'i18next-vue';
import { useDataSimplified } from '@/lib/hooks';

import { Tippy } from 'vue-tippy';

import { Badge } from '@/components/ui/Badge';
import { Button } from '@/components/ui/Button';
import { FilteringPopup } from '@/components/custom/AdvancedTable';

import CloseIcon from 'assets/icons/close-icon.svg?component';

import { cn } from '@/lib/utils';
import { parseBadgeValue } from '@/lib/filtering';

import { BUTTON_VARIANTS, COMPONENT_VARIANTS } from '@/constants/component';
import { FILTER_TRANSLATIONS } from '@/constants/filtering';

import { type TableFilter, type Filter } from '@/types/filtering';

import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';
import type { ListContentType } from '@/types/components';

defineProps<{
  isAdvancedTable?: boolean;
}>();

const currentListContent = inject('currentListContent') as Ref<ListContentType>;
const activeFilters =
  inject<UseAdvancedTable['activeFilters']>('activeFilters');
const filterOptionsCount =
  inject<UseAdvancedTable['filterOptionsCount']>('filterOptionsCount');
const isFetching = inject<Ref<boolean>>('isFetching');
const showFilterPopup =
  inject<UseAdvancedTable['showFilterPopup']>('showFilterPopup');

const handleClearFilters =
  inject<UseAdvancedTable['handleClearFilters']>('handleClearFilters');
const handleFilterRemove =
  inject<UseAdvancedTable['handleFilterRemove']>('handleFilterRemove');
const handlePopupToggle =
  inject<UseAdvancedTable['handlePopupToggle']>('handlePopupToggle');
const { simplifiedData } = useDataSimplified(currentListContent);

const { t } = useTranslation();

const getBadgeLabel = (filter: Filter) => {
  const parsedLabels = parseBadgeValue(
    filter.filterType as TableFilter,
    filter.value,
    filter.values,
    simplifiedData.value,
    t
  );

  const label = Array.isArray(parsedLabels)
    ? parsedLabels.slice(0, 1).join(', ')
    : parsedLabels;

  const hiddenItems = Array.isArray(parsedLabels) ? parsedLabels?.slice(1) : [];

  return { label, hiddenItems };
};
</script>

<template>
  <div
    v-if="activeFilters?.length"
    :class="
      cn(
        'flex justify-between flex-wrap lg:flex-nowrap gap-2.5 min-h-15 p-2 rounded-md border bg-amber-100 border-yellow-300',
        isAdvancedTable && 'bg-gray-50 border-gray-200 mt-2.5'
      )
    "
  >
    <div class="flex gap-2.5 flex-col w-full lg:flex-row">
      <div v-if="isAdvancedTable" class="relative w-full lg:w-min">
        <Button
          :variant="BUTTON_VARIANTS.infoOutlined"
          :is-disabled="isFetching || filterOptionsCount === 0"
          size="sm"
          class="lg:min-h-12 w-full lg:w-min"
          @click="handlePopupToggle"
        >
          {{ $t('common.new_filter') }}
        </Button>
        <div class="absolute z-20 w-min h-min top-16 -left-2">
          <Transition
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
            enter-active-class="transition duration-300"
            leave-active-class="transition duration-300"
          >
            <FilteringPopup
              v-if="!isFetching && showFilterPopup"
              :active-filters="activeFilters || []"
              @close-popup="handlePopupToggle"
            />
          </Transition>
        </div>
      </div>
      <div class="flex flex-wrap gap-2.5 max-h-40 overflow-auto">
        <Badge
          v-for="(filter, index) in activeFilters"
          :key="`${index}${filter.filterType}${filter.operator}${filter.value}`"
          :variant="COMPONENT_VARIANTS.ghost"
          shape="rectangular"
          :size="null"
          :class="
            cn(
              'text-gray-800 flex h-min break-all',
              isFetching ? 'opacity-50' : 'pointer-events-auto'
            )
          "
        >
          <div class="flex gap-1 flex-wrap w-full lg:flex-nowrap">
            <p class="font-normal shrink-0">
              {{ $t(FILTER_TRANSLATIONS[filter.filterType as TableFilter]) }}
            </p>
            <p class="font-semibold underline shrink-0">
              {{ filter.operator }}
            </p>
            <p class="font-normal">
              {{ getBadgeLabel(filter).label }}
            </p>
            <Tippy
              v-if="getBadgeLabel(filter).hiddenItems.length"
              :content="getBadgeLabel(filter).hiddenItems.join(', ')"
              placement="top"
            >
              <Badge
                :variant="COMPONENT_VARIANTS.ghost"
                class="hover:cursor-pointer"
                is-counter
                >{{ `+${getBadgeLabel(filter).hiddenItems.length}` }}
              </Badge>
            </Tippy>
          </div>
          <Button
            v-if="isAdvancedTable"
            :variant="BUTTON_VARIANTS.icon"
            :is-disabled="isFetching"
            class="p-0 h-9"
            @click="handleFilterRemove && handleFilterRemove(filter)"
          >
            <CloseIcon class="h-4 w-4 shrink-0" />
          </Button>
        </Badge>
      </div>
    </div>
    <div v-if="isAdvancedTable" class="relative flex gap-2.5 w-full lg:w-min">
      <Button
        :variant="BUTTON_VARIANTS.destructive"
        :is-disabled="isFetching"
        size="sm"
        class="lg:min-h-12 w-full lg:w-min"
        @click="handleClearFilters"
      >
        {{ $t('common.clear_filters') }}
      </Button>
    </div>
  </div>
</template>
