<script setup lang="ts">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import { AlertCircle, Check } from 'lucide-vue-next';

import { cn } from '@/lib/utils';
import { prepareMessage } from '@/lib/helpers';

import { textareaVariants } from '@/components/ui/Textarea/index';

import {
  STATUS_DEFAULT,
  STATUS_ERROR,
  STATUS_SUCCESS
} from '@/constants/status';
import { TEXTAREA_DEFAULT_ROWS } from '@/constants/component';

import type { TextareaProps } from '@/types/components';

const props = withDefaults(defineProps<TextareaProps>(), {
  class: null,
  defaultValue: '',
  id: 'default-textarea',
  isDisabled: false,
  isReadonly: false,
  showErrorMessage: true,
  messages: () => ({
    errorMessage: 'An error has occurred.',
    successMessage: 'Success!'
  }),
  modelValue: '',
  placeholder: 'Type your message here',
  rows: TEXTAREA_DEFAULT_ROWS,
  validationStatus: STATUS_DEFAULT
});

const inputMessage = computed(() =>
  prepareMessage(props.validationStatus, props.messages)
);

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue
});
</script>

<template>
  <div class="w-full">
    <div class="relative">
      <textarea
        :id="id"
        v-model="modelValue"
        :disabled="isDisabled"
        :readonly="isReadonly"
        :placeholder="placeholder"
        :rows="rows"
        :class="
          cn(textareaVariants({ variant: validationStatus }), props.class)
        "
      />
      <div
        v-if="validationStatus === STATUS_ERROR"
        class="absolute top-0 end-0 flex items-center pointer-events-none p-3"
      >
        <AlertCircle class="w-4 h-4 stroke-red-500" />
      </div>

      <div
        v-if="validationStatus === STATUS_SUCCESS"
        class="absolute top-0 end-0 flex items-center pointer-events-none p-3"
      >
        <Check class="w-4 h-4 stroke-emerald-500" />
      </div>
    </div>
    <p
      v-if="inputMessage && showErrorMessage"
      :class="
        cn(
          'text-sm mt-1',
          validationStatus === STATUS_ERROR && 'text-red-600',
          validationStatus === STATUS_SUCCESS && 'text-emerald-600'
        )
      "
    >
      {{ inputMessage }}
    </p>
  </div>
</template>
