import { defineAsyncComponent } from 'vue';

export const PatentsPlatformUsers = defineAsyncComponent(
  () => import('@/components/custom/Charts/PatentsPlatformUsers.vue')
);

export const IdeaDatabaseUsers = defineAsyncComponent(
  () => import('@/components/custom/Charts/IdeaDatabaseUsers.vue')
);

export const ProgressCircle = defineAsyncComponent(
  () => import('@/components/custom/Charts/ProgressCircle.vue')
);
