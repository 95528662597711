<script setup lang="ts">
import { computed, inject, onMounted, type Ref, ref } from 'vue';
import { AxiosError } from 'axios';

import { useToast } from 'vue-toastification';
import { useTranslation } from 'i18next-vue';

import { CustomInput } from '@/components/custom';
import { CustomSelect } from '@/components/custom/CustomSelect';
import { ValidationErrorAlert } from '@/components/custom/Modals/components';

import { updateProfile, fetchLanguages } from '@/api/profileSettings';
import { emitterInstance, parseErrorFields } from '@/lib/helpers';

import {
  INITIAL_USER_PROFILE_STATE,
  INITIAL_USER_PROFILE_VALIDATION
} from '@/constants/userSettings';

import { STATUS_DEFAULT, STATUS_ERROR } from '@/constants/status';
import { CREATE_ACCOUNT_VALIDATION_TRANSLATIONS } from '@/constants/modals';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';

import type { CurrentUser } from '@/types/users';
import type { ParsedApiErrors } from '@/types/api';
import type { ProfileSettingsParams } from '@/api/profileSettings/types';
import type { SelectOption, ValidationStatuses } from '@/types/components';
import type { SetIsFetchingUserSettings } from '@/types/userSettings';
import type { UseModal } from '@/types/modals';

const toast = useToast();
const { t } = useTranslation();

const isFetchingUserSettings = inject<Ref<boolean>>('isFetchingUserSettings');
const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const setIsFetchingUserSettings = inject<SetIsFetchingUserSettings>(
  'setIsFetchingUserSettings'
);
const setIsLoading = inject('setIsLoading') as UseModal['setIsLoading'];

const isFormValid = ref(INITIAL_USER_PROFILE_VALIDATION);
const isInactive = ref<boolean>(true);
const state = ref<ProfileSettingsParams>(INITIAL_USER_PROFILE_STATE);
const validationErrors = ref<ParsedApiErrors>({});

const textLanguages = ref([] as SelectOption[]);
const uiLanguages = ref([] as SelectOption[]);

onMounted(async () => {
  state.value.firstName = currentUser.value.firstName;
  state.value.lastName = currentUser.value.lastName;
  state.value.email = currentUser.value.email;

  if (setIsFetchingUserSettings) {
    setIsFetchingUserSettings(true);
  }

  const response = await fetchLanguages();
  const uiCodes = SUPPORTED_LANGUAGES.map(code => code as string);

  for (const lang of response) {
    textLanguages.value.push({
      value: lang.code,
      label: lang.name
    } as SelectOption);

    if (uiCodes.includes(lang.code)) {
      uiLanguages.value.push({
        value: lang.code,
        label: lang.name
      } as SelectOption);
    }
  }

  state.value.language = currentUser.value.language;
  state.value.languagePreference = [...currentUser.value.languagePreference];

  if (setIsFetchingUserSettings) {
    setIsFetchingUserSettings(false);
  }
});

function validateState(): boolean {
  isFormValid.value = {
    first_name:
      state.value?.firstName?.length && state.value?.firstName.length > 0
        ? STATUS_DEFAULT
        : STATUS_ERROR,
    last_name:
      state.value?.lastName?.length && state.value?.lastName.length > 0
        ? STATUS_DEFAULT
        : STATUS_ERROR,
    email:
      state.value?.email?.length && state.value?.email?.length > 0
        ? STATUS_DEFAULT
        : STATUS_ERROR,
    language: !Object.keys(validationErrors).includes('language')
      ? STATUS_DEFAULT
      : STATUS_ERROR,
    language_preference: !Object.keys(validationErrors).includes(
      'language_preference'
    )
      ? STATUS_DEFAULT
      : STATUS_ERROR
  };

  return Object.values(isFormValid.value).every(
    value => value === STATUS_DEFAULT
  );
}

function updateFormValidationData() {
  const formValidationData = { ...isFormValid.value };

  Object.keys(formValidationData).forEach(key => {
    if (key in validationErrors.value) {
      formValidationData[key as keyof typeof formValidationData] = STATUS_ERROR;
    }
  });

  return formValidationData;
}

const resetState = () => (state.value = INITIAL_USER_PROFILE_STATE);
const resetFormValidation = () =>
  (isFormValid.value = INITIAL_USER_PROFILE_VALIDATION);
const resetErrorValidation = () => (validationErrors.value = {});

const availableTextLanguageOptions = (index: number) => {
  return computed(() => {
    const selected = state.value?.languagePreference?.filter(
      (_, i) => i !== index
    );
    return textLanguages.value.filter(
      language => !selected?.includes(language.value)
    );
  });
};

const setLanguagePreference = (num: number, lang: string) => {
  if (state.value.languagePreference === null) {
    state.value.languagePreference = [];
  }

  state.value.languagePreference[num] = lang;
};

const updateUserProfileSettings = async () => {
  resetErrorValidation();
  resetFormValidation();

  let refresh = false;

  const form = <ProfileSettingsParams>{
    firstName: state.value.firstName ?? currentUser?.value?.firstName,
    lastName: state.value.lastName ?? currentUser?.value?.lastName,
    email: state.value.email ?? currentUser?.value?.email,
    languagePreference:
      state.value.languagePreference ?? currentUser?.value?.languagePreference,
    language: state.value.language ?? currentUser?.value?.language
  };

  if (state.value.language !== currentUser?.value?.language) {
    refresh = true;
  }

  if (!validateState()) {
    return;
  }

  setIsLoading(true);

  const result = await updateProfile(form);

  if (result instanceof AxiosError) {
    validationErrors.value = parseErrorFields(
      result.response?.data?.errors ?? {}
    );

    isFormValid.value = updateFormValidationData();

    toast.error(t('common.error_validation'));
    emitterInstance().emit('user-profile-update-error');

    return;
  }

  emitterInstance().emit('user-profile-update-success');
  toast.success(t('userProfile.update_user_profile_success'));

  isInactive.value = true;

  if (refresh) {
    location.reload();
  }
};

emitterInstance().on('update-user-profile', updateUserProfileSettings);

emitterInstance().on('activate-user-profile', () => {
  isInactive.value = false;
});

emitterInstance().on('deactivate-user-profile', () => {
  resetState();
  resetFormValidation();
  resetErrorValidation();

  state.value.firstName = currentUser.value.firstName;
  state.value.lastName = currentUser.value.lastName;
  state.value.email = currentUser.value.email;
  state.value.language = currentUser.value.language;
  state.value.languagePreference = [...currentUser.value.languagePreference];

  isInactive.value = true;
});
</script>

<template>
  <div class="flex flex-col gap-5">
    <ValidationErrorAlert
      :validation-errors="validationErrors"
      :error-translation-map="CREATE_ACCOUNT_VALIDATION_TRANSLATIONS"
    />
    <div class="flex flex-col md:flex-row gap-5">
      <div class="w-full">
        <CustomInput
          id="firstName"
          attribute="settings.first_name"
          :is-disabled="isInactive"
          label-text="settings.first_name"
          :placeholder="$t('settings.first_name')"
          :show-error-message="false"
          :value="state.firstName"
          :validation-status="isFormValid.first_name as ValidationStatuses"
          @change-value="state.firstName = $event"
        />
        <p
          v-if="
            isFormValid.first_name === STATUS_ERROR &&
            !Object.keys(validationErrors).includes('first_name')
          "
          class="text-sm mt-1 text-red-600"
        >
          {{
            t('validation.min.string_single', {
              attribute: (t('settings.first_name') as string).toLowerCase(),
              min: 1
            })
          }}
        </p>
      </div>

      <div class="w-full">
        <CustomInput
          id="lastName"
          attribute="settings.last_name"
          :is-disabled="isInactive"
          label-text="settings.last_name"
          :placeholder="$t('settings.last_name')"
          :show-error-message="false"
          :value="state.lastName"
          :validation-status="isFormValid.last_name as ValidationStatuses"
          @change-value="state.lastName = $event"
        />
        <p
          v-if="
            isFormValid.last_name === STATUS_ERROR &&
            !Object.keys(validationErrors).includes('last_name')
          "
          class="text-sm mt-1 text-red-600"
        >
          {{
            t('validation.min.string_single', {
              attribute: (t('settings.last_name') as string).toLowerCase(),
              min: 1
            })
          }}
        </p>
      </div>
    </div>

    <div class="w-full">
      <CustomInput
        id="email"
        attribute="settings.email"
        label-text="settings.email"
        :is-disabled="isInactive"
        :placeholder="$t('settings.email')"
        :show-error-message="false"
        :value="state.email"
        :validation-status="isFormValid.email as ValidationStatuses"
        @change-value="state.email = $event"
      />
      <p
        v-if="
          isFormValid.email === STATUS_ERROR &&
          !Object.keys(validationErrors).includes('email')
        "
        class="text-sm mt-1 text-red-600"
      >
        {{
          t('validation.min.string_single', {
            attribute: (t('settings.email') as string).toLowerCase(),
            min: 1
          })
        }}
      </p>
    </div>

    <div class="flex flex-col gap-1.5">
      <p class="text-gray-800 text-lg font-semibold tracking-tight">
        {{ $t('settings.language_preferences') }}
      </p>
      <p class="text-gray-500">
        {{ $t('settings.language_preferences_description') }}
      </p>
    </div>

    <CustomSelect
      :model-value="state.language"
      label-text="settings.interface_language"
      :placeholder="$t('settings.interface_language')"
      :validation-status="isFormValid.language as ValidationStatuses"
      :is-disabled="isFetchingUserSettings || isInactive"
      :options="uiLanguages"
      class="w-full"
      @update:model-value="state.language = $event"
    />

    <div class="flex flex-col md:flex-row gap-5">
      <CustomSelect
        :model-value="state.languagePreference?.[0]"
        label-text="settings.primary_language"
        :placeholder="$t('settings.primary_language')"
        :validation-status="
          isFormValid.language_preference as ValidationStatuses
        "
        :is-disabled="isFetchingUserSettings || isInactive"
        :options="availableTextLanguageOptions(0).value"
        class="w-full"
        @update:model-value="setLanguagePreference(0, $event)"
      />

      <CustomSelect
        :model-value="state.languagePreference?.[1]"
        label-text="settings.secondary_language"
        :placeholder="$t('settings.secondary_language')"
        :is-disabled="isFetchingUserSettings || isInactive"
        :options="availableTextLanguageOptions(1).value"
        class="w-full"
        @update:model-value="setLanguagePreference(1, $event)"
      />

      <CustomSelect
        :model-value="state.languagePreference?.[2]"
        label-text="settings.tertiary_language"
        :placeholder="$t('settings.tertiary_language')"
        :is-disabled="isFetchingUserSettings || isInactive"
        :options="availableTextLanguageOptions(2).value"
        class="w-full"
        @update:model-value="setLanguagePreference(2, $event)"
      />
    </div>
  </div>
</template>
