<script setup lang="ts">
import { Tippy } from 'vue-tippy';

import type { HTMLAttributes } from 'vue';
import type { Placement } from 'tippy.js';

const props = defineProps<{
  shouldShow: boolean;
  class?: HTMLAttributes['class'];
  content: string;
  placement: Placement;
}>();
</script>

<template>
  <Tippy
    v-if="props.shouldShow"
    :class="props.class"
    :content="props.content"
    :placement="props.placement"
  >
    <slot></slot>
  </Tippy>
  <slot v-else></slot>
</template>
