import { ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import { getDataKey } from '@/lib/hooks/table/useDataSimplified';
import { getCurrentListContentType } from '@/lib/helpers';

import { type AllowedApiKeys, API_DISABLED } from '@/constants/api';

import {
  BULK_NONE,
  BULK_SUCCESS,
  type BulkStatus,
  DEFAULT_INVALIDATE_KEYS
} from '@/constants/bulkActions';

import type { AllowedRoutes } from '@/constants/routes';

const bulkActionStatus = ref<BulkStatus>(BULK_NONE);
const invalidateQueryKey = ref<
  ReadonlyArray<AllowedApiKeys> | AllowedApiKeys[]
>(DEFAULT_INVALIDATE_KEYS);

function useBulkStatus() {
  const queryClient = useQueryClient();

  async function setBulkActionStatus(
    status: BulkStatus,
    apiKey: AllowedApiKeys = API_DISABLED
  ) {
    const validApiKeys = invalidateQueryKey.value.filter(
      key => key !== API_DISABLED
    );
    const apiKeysWithSimplifiedData = [
      ...validApiKeys,
      ...(validApiKeys.length
        ? [
            getDataKey(
              getCurrentListContentType(
                window.location.pathname as AllowedRoutes
              )
            )
          ].filter(Boolean)
        : [])
    ];
    bulkActionStatus.value = status;

    if (
      bulkActionStatus.value === BULK_SUCCESS &&
      apiKeysWithSimplifiedData.length
    ) {
      await Promise.all(
        apiKeysWithSimplifiedData.map(key =>
          queryClient.invalidateQueries({
            queryKey: [key]
          })
        )
      );

      bulkActionStatus.value = BULK_NONE;
      invalidateQueryKey.value = DEFAULT_INVALIDATE_KEYS;
    }

    invalidateQueryKey.value = Array.from(
      new Set([...invalidateQueryKey.value, apiKey])
    );
  }

  return { bulkActionStatus, setBulkActionStatus };
}

export type UseBulkStatus = ReturnType<typeof useBulkStatus>;

export default useBulkStatus;
