import { provide, ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { useBulkStatus } from '@/lib/hooks';

import { AxiosError } from 'axios';

import {
  getCurrentListContentType,
  parseError,
  parseErrorFields
} from '@/lib/helpers';

import { getDataKey } from '@/lib/hooks/table/useDataSimplified';
import { openInvalidDataModal } from '@/lib/modals';

import { BULK_LOADING, BULK_NONE } from '@/constants/bulkActions';
import { type AllowedApiKeys, API_DISABLED, HTTP_CODES } from '@/constants/api';
import { DISABLE_ERROR_API_CODES, INACTIVE_MODAL } from '@/constants/modals';

import type {
  AllowedModals,
  InitialModalState,
  ModalConfirmAction,
  SavedModalState
} from '@/types/modals';

import type { AllowedRoutes } from '@/constants/routes';
import type { ParsedApiErrors } from '@/types/api';
import type { ParseKeys } from 'i18next';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

function useModal({
  rowSelection,
  handleDeleteTableData
}: {
  rowSelection: UseAdvancedTable['rowSelection'];
  handleDeleteTableData: UseAdvancedTable['handleDeleteTableData'];
}) {
  const isModalLoading = ref<boolean>(false);
  const isModalError = ref<false | string>(false);
  const isModalSuccess = ref<boolean>(false);
  const currentModal = ref<AllowedModals>(INACTIVE_MODAL);
  // INFO: Used for initializing modal state
  const initialModalState = ref<InitialModalState>({} as InitialModalState);
  // INFO: Used for saving modal state - e.g. form data. May be removed in the future, if none of modals will need it
  const savedModalState = ref<SavedModalState>({} as SavedModalState);
  const validationErrors = ref<ParsedApiErrors>({});
  const previousSavedState = ref<SavedModalState>({} as SavedModalState);
  const queryClient = useQueryClient();
  const { setBulkActionStatus } = useBulkStatus();

  function setIsLoading(isLoading: boolean) {
    isModalLoading.value = isLoading;
  }

  function setInitialModalState<
    T extends AllowedModals = typeof INACTIVE_MODAL
  >(initialState: InitialModalState<T>) {
    initialModalState.value = initialState;
  }

  function setInitialModalStateByKey<
    T extends AllowedModals = typeof INACTIVE_MODAL
  >(
    key: keyof InitialModalState<T>,
    value: InitialModalState<T>[keyof InitialModalState<T>]
  ) {
    setInitialModalState<T>({
      ...initialModalState.value,
      [key]: value
    } as InitialModalState<T>);
  }

  function openModal<T extends AllowedModals = typeof INACTIVE_MODAL>(
    openedModal: T,
    initialState: InitialModalState<T>
  ) {
    currentModal.value = openedModal;

    setInitialModalState<T>(initialState);
  }

  function deselectItems(startedState: InitialModalState) {
    const { [`${startedState.deselectItems}`]: _, ...updatedRowSelection } =
      rowSelection.value;

    rowSelection.value =
      typeof startedState.deselectItems === 'boolean'
        ? {}
        : updatedRowSelection;
  }

  function handleItemsDelete(startedState: InitialModalState) {
    const hasPageChanged = handleDeleteTableData(
      typeof startedState.deselectItems === 'boolean'
    );

    if (hasPageChanged) {
      startedState.invalidateQueryKey = API_DISABLED;
    }
  }

  async function invalidateData(startedState: InitialModalState) {
    const allowedApiKeys = [
      startedState.invalidateQueryKey,
      ...[
        getDataKey(
          getCurrentListContentType(window.location.pathname as AllowedRoutes)
        )
      ].filter(Boolean)
    ];

    await Promise.all(
      allowedApiKeys.map(key =>
        queryClient.invalidateQueries({
          queryKey: [key]
        })
      )
    );
  }

  async function closeModal() {
    if (
      isModalError.value &&
      typeof initialModalState.value.deselectItems === 'number'
    ) {
      deselectItems(initialModalState.value);
    }

    if (isModalError.value && initialModalState.value.invalidateQueryKey) {
      await invalidateData(initialModalState.value);
    }

    isModalError.value = false;
    isModalSuccess.value = false;
    setIsLoading(false);
    validationErrors.value = {};
    previousSavedState.value = {} as SavedModalState;
    currentModal.value = INACTIVE_MODAL;
    initialModalState.value = {} as InitialModalState;
    savedModalState.value = {} as SavedModalState;
  }

  function setModalState<T extends AllowedModals = typeof INACTIVE_MODAL>(
    modalState: SavedModalState<T>
  ) {
    savedModalState.value = modalState;
  }

  function setModalStateByKey<T extends AllowedModals = typeof INACTIVE_MODAL>(
    key: keyof SavedModalState<T>,
    value: SavedModalState<T>[keyof SavedModalState<T>]
  ) {
    setModalState<T>({
      ...savedModalState.value,
      [key]: value
    } as SavedModalState<T>);
  }

  function goBackAction() {
    setIsLoading(false);
    isModalError.value = false;
    isModalSuccess.value = false;
  }

  function setValidationErrors(errors: ParsedApiErrors) {
    validationErrors.value = errors;
    previousSavedState.value = savedModalState.value;
  }

  async function confirmAction() {
    const startedState = { ...initialModalState.value };
    if (initialModalState.value.isBulkAction) {
      await setBulkActionStatus(
        BULK_LOADING,
        initialModalState.value.invalidateQueryKey
      );
    }

    if (initialModalState.value.useStatusConfirmation) {
      setIsLoading(true);
    }

    const response = await new Promise(resolve =>
      (initialModalState.value.confirmAction as unknown as ModalConfirmAction)(
        resolve,
        Object.fromEntries(
          Object.entries(savedModalState.value).map(([key, value]) => [
            key,
            typeof value === 'string' && !value.length ? undefined : value
          ])
        ) as never // TODO: Low priority - fix this typing.
      )
    );

    // There is no response if new modal is opened.
    if (!response) {
      if (initialModalState.value.isBulkAction) {
        await setBulkActionStatus(BULK_NONE);
      }

      return;
    }

    if (
      initialModalState.value.useStatusConfirmation &&
      response instanceof AxiosError
    ) {
      if (response.response?.status === HTTP_CODES.BAD_REQUEST) {
        setValidationErrors(parseErrorFields(response.response?.data.errors));
      }

      if (response.response?.status === HTTP_CODES.NOT_FOUND) {
        openInvalidDataModal(
          openModal,
          initialModalState.value.invalidateQueryKey as AllowedApiKeys,
          'common.item_is_invalid'
        );
      }

      if (initialModalState.value.isBulkAction) {
        await setBulkActionStatus(BULK_NONE);
      }

      setIsLoading(false);

      if (
        DISABLE_ERROR_API_CODES.includes(
          response.response?.status as (typeof DISABLE_ERROR_API_CODES)[number]
        )
      ) {
        return;
      }

      isModalError.value = parseError(response);

      return;
    }

    if (startedState.deselectItems) {
      handleItemsDelete(startedState);
      deselectItems(startedState);
    }

    if (
      !startedState.isBulkAction &&
      startedState.useStatusConfirmation &&
      startedState.invalidateQueryKey
    ) {
      await invalidateData(startedState);
    }

    if (initialModalState.value.isBulkAction) {
      initialModalState.value.successMessage = (
        response as { message: ParseKeys }
      ).message;
    }

    if (initialModalState.value.useStatusConfirmation) {
      isModalSuccess.value = true;
      setIsLoading(false);

      return;
    }

    await closeModal();
  }

  provide('isModalLoading', isModalLoading);
  provide('isModalError', isModalError);
  provide('isModalSuccess', isModalSuccess);
  provide('currentModal', currentModal);
  provide('initialModalState', initialModalState);
  provide('savedModalState', savedModalState);
  provide('validationErrors', validationErrors);
  provide('previousSavedState', previousSavedState);

  provide('openModal', openModal);
  provide('closeModal', closeModal);
  provide('setInitialModalState', setInitialModalState);
  provide('setInitialModalStateByKey', setInitialModalStateByKey);
  provide('setModalState', setModalState);
  provide('setModalStateByKey', setModalStateByKey);
  provide('goBackAction', goBackAction);
  provide('confirmAction', confirmAction);
  provide('setIsLoading', setIsLoading);
  provide('setValidationErrors', setValidationErrors);

  return {
    isModalLoading,
    isModalError,
    isModalSuccess,
    currentModal,
    initialModalState,
    savedModalState,
    validationErrors,
    previousSavedState,
    openModal,
    closeModal,
    setIsLoading,
    setInitialModalState,
    setInitialModalStateByKey,
    setModalState,
    setModalStateByKey,
    goBackAction,
    confirmAction,
    setValidationErrors
  };
}

export default useModal;
