<script
  setup
  lang="ts"
  generic="
    TData extends ListUser | ListToken,
    AData extends ApiSimpleResponse | ApiSimpleResponse<{ user: ListUser }>
  "
>
import { inject } from 'vue';
import { useToast } from 'vue-toastification';
import { useQueryClient } from '@tanstack/vue-query';

import { AxiosError } from 'axios';

import { Switch } from '@/components/custom';

import { parseError } from '@/lib/helpers';
import { openInvalidDataModal } from '@/lib/modals';

import { type AllowedApiKeys, HTTP_CODES } from '@/constants/api';

import type {
  ApiErrorResponse,
  ApiSimpleResponse,
  ApiTableResponse
} from '@/types/api';

import type { UseModal } from '@/types/modals';
import type { SwitchProps } from '@/types/components';
import type { ListUser } from '@/types/users';
import type { ListToken } from '@/types/tokens';

const props = defineProps<
  SwitchProps & {
    invalidateQueryKey: AllowedApiKeys;
    onChangeHandle: (value: boolean) => Promise<AData | ApiErrorResponse>;
    onChangeUpdate?: (
      oldData: ApiTableResponse<TData> | undefined,
      response: AData
    ) => ApiTableResponse<TData>;
  }
>();
const setIsLoading = inject('setIsLoading') as UseModal['setIsLoading'];
const openModal = inject('openModal') as UseModal['openModal'];
const queryClient = useQueryClient();
const toast = useToast();

function handleError(response: ApiErrorResponse) {
  const data = response.response?.data;

  if (response.response?.status === HTTP_CODES.NOT_FOUND) {
    openInvalidDataModal(
      openModal,
      props.invalidateQueryKey,
      'common.item_is_invalid'
    );
  }

  if (response.response?.status === HTTP_CODES.FORBIDDEN && data) {
    toast.error(data.message);
  }

  if (response.response?.status === HTTP_CODES.BAD_REQUEST) {
    toast.error(parseError(response));
  }
}

function invalidateQuery(response: AData) {
  queryClient.setQueriesData<ApiTableResponse<TData>>(
    { queryKey: [props.invalidateQueryKey] },
    (oldData: ApiTableResponse<TData> | undefined): ApiTableResponse<TData> => {
      if (!props.onChangeUpdate) {
        return oldData as ApiTableResponse<TData>;
      }

      return props.onChangeUpdate(oldData, response);
    }
  );
}

async function onClickHandler(event: Event) {
  setIsLoading(true);

  const target = event.target as HTMLInputElement;
  const originalValue = target.checked;

  target.checked = !originalValue;

  const response = await props.onChangeHandle(originalValue);

  setIsLoading(false);

  if (response instanceof AxiosError) {
    handleError(response);

    return;
  }

  if (props.invalidateQueryKey) {
    invalidateQuery(response);
  }

  const successMessage = response.message || response.data.message;

  if (successMessage) {
    toast.success(successMessage);
  }

  target.checked = originalValue;
}
</script>

<template>
  <Switch
    :id="props.id"
    :class="props.class"
    :is-disabled="props.isDisabled"
    :label-position="props.labelPosition"
    :label-text="props.labelText"
    :model-value="modelValue"
    :name="props.name"
    @on-click-handler="onClickHandler"
  >
  </Switch>
</template>
