import { computed } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';

import { INITIAL_TABLE_RESPONSE, type AllowedApiKeys } from '@/constants/api';

import type { ApiTableParams, ApiTableResponse } from '@/types/api';

function useTableData<T>({
  apiParams,
  apiKey,
  fetchApiData
}: {
  apiParams?: ApiTableParams;
  apiKey: AllowedApiKeys;
  fetchApiData: (apiParams?: ApiTableParams) => Promise<ApiTableResponse<T>>;
}) {
  const { data, isFetching, isError, isPlaceholderData } = useQuery({
    queryKey: [apiKey, apiParams],
    queryFn: () => fetchApiData(apiParams),
    placeholderData: keepPreviousData
  });

  const dataStore = computed<ApiTableResponse<T>>(() => {
    return data.value ?? INITIAL_TABLE_RESPONSE;
  });

  return { data: dataStore, isFetching, isError, isPlaceholderData };
}

export default useTableData;
