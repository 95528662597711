<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import {
  DropdownMenuSeparator,
  type DropdownMenuSeparatorProps
} from 'radix-vue';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  DropdownMenuSeparatorProps & {
    class?: HTMLAttributes['class'];
  }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <DropdownMenuSeparator
    v-bind="delegatedProps"
    :class="cn('-mx-1 my-1 h-px bg-gray-200', props.class)"
  />
</template>
