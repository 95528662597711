import { computed } from 'vue';

import { useQuery } from '@tanstack/vue-query';
import { fetchUser } from '@/api/user';

import { INITIAL_USER, USER_API } from '@/constants/api';
import type { ListUser, User } from '@/types/users';

function useUser(currentUser: ListUser) {
  const { data, isFetching, isError } = useQuery({
    queryKey: [USER_API, currentUser],
    queryFn: () => fetchUser(currentUser.id),
    select(data) {
      return data.data;
    }
  });

  const user = computed<User>(() => {
    return (data.value as User) ?? INITIAL_USER;
  });

  return { user, isFetching, isError };
}

export default useUser;
