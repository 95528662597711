export const PRESET_TABLE_ROWS_COUNT = 4;

export const INITIAL_ORGANIZATION_USER_PRESET = {
  name: '',
  description: null,
  groups: [],
  isSsoEnabled: 0,
  patentsProductEnabled: 0,
  patentsUserType: null,
  patentsSelectedRoles: [],
  ideaDatabaseProductEnabled: 0,
  ideaDatabaseRoles: null,
  note: ''
};
