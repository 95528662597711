import i18next, { use, changeLanguage } from 'i18next';

import LocizeBackend, {
  type LocizeBackendOptions
} from 'i18next-locize-backend';

import LanguageDetector from 'i18next-browser-languagedetector';

import { DEFAULT_NAMESPACE, SUPPORTED_LANGUAGES } from '@/constants/languages';

import {
  FAILED_TO_CHANGE_LANGUAGE,
  TRANSLATION_INIT_ERROR
} from '@/constants/errors';

use(LocizeBackend)
  .use(LanguageDetector)
  .init<LocizeBackendOptions>({
    debug: import.meta.env.DEV,
    returnNull: false,
    returnEmptyString: false,
    fallbackLng: SUPPORTED_LANGUAGES[0],
    ns: DEFAULT_NAMESPACE,
    saveMissing: false,
    defaultNS: DEFAULT_NAMESPACE,
    supportedLngs: SUPPORTED_LANGUAGES,
    backend: {
      projectId: import.meta.env.VITE_APP_LOCIZE_PROJECT_ID,
      apiKey: import.meta.env.VITE_APP_LOCIZE_API_KEY
    }
  })
  .then(() => {
    if (window.currentUser.language !== i18next.language) {
      changeLanguage(window.currentUser.language).catch(e => {
        console.error(FAILED_TO_CHANGE_LANGUAGE, e);
      });
    }
  })
  .catch(e => console.error(TRANSLATION_INIT_ERROR, e));

export const { t } = i18next;

export default i18next;
