import request from '@/api/base';

import type { ApiErrorResponse, ApiSimpleResponse } from '@/types/api';

import type {
  PasswordConfirmationParams,
  UpdateUserPasswordParams
} from '@/api/profile/types';

import type { Session } from '@/types/userSettings';

export async function disableUserTwoFactor(params?: PasswordConfirmationParams) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/profile/two-factor-disable`,
      {
        password: params?.password
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function regenerateTwoFactorCodes(
  params?: PasswordConfirmationParams
) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/profile/two-factor-recovery-codes`,
      {
        password: params?.password
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function enableUserTwoFactor(params?: PasswordConfirmationParams) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/profile/two-factor-enable`,
      {
        password: params?.password
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function updateUserPassword(params: UpdateUserPasswordParams) {
  return await request
    .patch<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/profile/password`,
      params
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function fetchUserSessions() {
  return await request
    .get<{
      sessions: Session[];
    }>(`${import.meta.env.VITE_API_VERSION}/profile/sessions`)
    .then(response => response.data);
}

export async function clearOtherUserSessions(
  params?: PasswordConfirmationParams
) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/profile/sessions`,
      {
        data: params
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}
