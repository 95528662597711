<script setup lang="ts" generic="T extends string | number">
import type { HTMLAttributes } from 'vue';
import { Users, X } from 'lucide-vue-next';

import { Badge } from '@/components/ui/Badge';
import { Button } from '@/components/ui/Button';
import { UserInfoBlock } from '@/views/Accounts/components';

import BellIcon from 'assets/icons/bell-01.svg?component';
import FolderIcon from 'assets/icons/folder.svg';

import { cn } from '@/lib/utils';

import {
  ACCESS_LEVEL_NAMES,
  NAMESPACE_MONITORING,
  NAMESPACE_PORTFOLIO
} from '@/constants/containerAccessLevel';

import { BUTTON_VARIANTS, COMPONENT_VARIANTS } from '@/constants/component';
import { TYPE_CONTAINER, TYPE_GROUP, TYPE_USER } from '@/constants/contentType';
import { STATUS_DEFAULT } from '@/constants/status';

import type { ListBadgeVariants, SelectOption } from '@/types/components';

import type { Container } from '@/types/containers';
import type { GroupSimplified } from '@/types/groups';
import type { UserSimplified } from '@/types/users';

const props = withDefaults(
  defineProps<{
    canBeRemoved?: boolean;
    class?: HTMLAttributes['class'];
    type: ListBadgeVariants;
    data?: SelectOption<T> | null;
    group?: GroupSimplified | null;
    container?: Container | null;
    subtitle?: string;
  }>(),
  {
    canBeRemoved: false,
    class: null,
    data: null,
    type: STATUS_DEFAULT,
    title: '',
    subtitle: '',
    group: null,
    container: null
  }
);

defineEmits<{
  (e: 'remove-option'): void;
}>();
</script>

<template>
  <Badge
    v-if="type === TYPE_USER"
    shape="rectangular"
    :variant="COMPONENT_VARIANTS.ghost"
    :class="
      cn(
        'px-2.5 py-2 min-h-13 min-w-13 text-xs',
        canBeRemoved && 'pointer-events-auto'
      )
    "
  >
    <UserInfoBlock
      user-details-class="w-3/4"
      class="relative bg-white px-2.5 py-2 h-full items-center border border-gray-200 shadow rounded-md"
      :email="(data?.data as UserSimplified).email"
      :full-name="(data?.data as UserSimplified).fullName"
      truncate
      is-small
    >
      <Button
        v-if="canBeRemoved"
        :variant="BUTTON_VARIANTS.icon"
        class="p-0 absolute right-4"
        @click="$emit('remove-option')"
      >
        <X class="w-4 h-4 shrink-0 text-gray-500" />
      </Button>
    </UserInfoBlock>
  </Badge>

  <Badge
    v-else-if="type === TYPE_GROUP"
    :variant="COMPONENT_VARIANTS.ghost"
    :title="group?.name"
    shape="rectangular"
    :class="
      cn(
        'px-2.5 py-2 min-h-13 min-w-13 text-xs',
        canBeRemoved && 'pointer-events-auto'
      )
    "
  >
    <Users class="w-4 h-4 shrink-0 ml-1 mr-1.5 stroke-black" />
    <div :class="cn('flex flex-col overflow-hidden', canBeRemoved && 'pr-6')">
      <p class="text-black font-semibold truncate">
        {{ group?.name }}
      </p>
      <p class="text-gray-500 truncate">
        {{ group?.description || '-' }}
      </p>
    </div>
    <Button
      v-if="canBeRemoved"
      :variant="BUTTON_VARIANTS.icon"
      class="p-0 absolute right-4"
      @click="$emit('remove-option')"
    >
      <X class="w-4 h-4 shrink-0 text-gray-500" />
    </Button>
  </Badge>

  <Badge
    v-else-if="type === TYPE_CONTAINER"
    :variant="COMPONENT_VARIANTS.ghost"
    :title="container?.name"
    shape="rectangular"
    :class="
      cn(
        'self-stretch px-2.5 py-2 h-auto min-h-13 min-w-13 text-xs',
        canBeRemoved && 'pointer-events-auto'
      )
    "
  >
    <BellIcon
      v-if="container?.namespace === NAMESPACE_MONITORING"
      class="w-4 h-4 shrink-0 ml-1 mr-1.5 stroke-black"
    />
    <FolderIcon
      v-else-if="container?.namespace === NAMESPACE_PORTFOLIO"
      class="w-4 h-4 shrink-0 ml-1 mr-1.5 stroke-black"
    />
    <div :class="cn('flex flex-col overflow-hidden')">
      <p class="text-black font-semibold truncate">
        {{ container?.name }}
      </p>
      <p class="text-gray-500 break-word">
        {{
          container?.accessLevel
            ? $t(ACCESS_LEVEL_NAMES[container.accessLevel], {
                owner_name: container.ownerName
              })
            : '-'
        }}
      </p>
    </div>
  </Badge>

  <Badge
    v-else
    :variant="COMPONENT_VARIANTS.ghost"
    shape="rectangular"
    :class="
      cn('relative shadow text-gray-800 flex gap-1.5 text-sm', props.class)
    "
    :title="data?.label"
  >
    <div class="overflow-hidden pr-6">
      <p v-if="data?.label" class="font-semibold truncate">{{ data.label }}</p>
      <p v-if="subtitle" class="text-gray-500">{{ subtitle }}</p>
    </div>
    <Button
      v-if="canBeRemoved"
      :variant="BUTTON_VARIANTS.icon"
      class="p-0 absolute right-4"
      @click="$emit('remove-option')"
    >
      <X class="w-4 h-4 shrink-0 text-gray-500" />
    </Button>
  </Badge>
</template>
