export const DASHBOARD_ROUTE = '/dashboard';
export const MANAGE_ACCOUNT_ROUTE = '/manage-accounts';
export const MANAGE_GROUPS_ROUTE = '/groups';
export const MANAGE_TOKEN_ROUTE = '/tokens';
export const ORGANIZATION_SETTINGS_ROUTE = '/organization';
export const SETTINGS_ROUTE = '/user/profile';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';

export type AllowedRoutes =
  | typeof DASHBOARD_ROUTE
  | typeof MANAGE_ACCOUNT_ROUTE
  | typeof MANAGE_GROUPS_ROUTE
  | typeof MANAGE_TOKEN_ROUTE
  | typeof ORGANIZATION_SETTINGS_ROUTE
  | typeof SETTINGS_ROUTE;
