<script setup lang="ts">
import { computed, inject, provide, ref, type Ref } from 'vue';

import TableOfContentsLayout from '@/layouts/TableOfContentsLayout/TableOfContentsLayout.vue';
import { CustomCard } from '@/components/custom';

import { LOGIN_TYPES_NAMES } from '@/constants/user';
import { USER_SETTINGS_SECTIONS } from '@/constants/userSettings';

import type { CurrentUser } from '@/types/users';
import type { SetIsFetchingUserSettings } from '@/types/userSettings';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
const isFetchingUserSettings = ref<boolean>(false);

const setIsFetchingUserSettings = (isFetching: boolean) =>
  (isFetchingUserSettings.value = isFetching);

provide<Ref<boolean>>('isFetchingUserSettings', isFetchingUserSettings);
provide<SetIsFetchingUserSettings>(
  'setIsFetchingUserSettings',
  setIsFetchingUserSettings
);

const availableSections = computed(() => {
  return Object.values(USER_SETTINGS_SECTIONS).filter(
    section =>
      (section.id !== 'two-factor-settings' ||
        (
          currentUser.value.organization.twoFactorEnabled ||
          currentUser.value.twoFactorEnabled
        ) &&
          currentUser.value.loginType === LOGIN_TYPES_NAMES.TYPE_EMAIL
      ) &&
      (section.id !== 'update-password' ||
        currentUser.value.loginType === LOGIN_TYPES_NAMES.TYPE_EMAIL)
  );
});
</script>

<template>
  <TableOfContentsLayout
    :heading="$t('common.settings')"
    main-content-class="flex flex-col gap-5 pb-8"
  >
    <template #main-content>
      <CustomCard
        v-for="section in availableSections"
        :id="section.id"
        :key="`$card-${section.id}`"
        :card-title="$t(section.title)"
        :card-description="$t(section.description)"
        :content-class="section.contentClass"
        :footer-class="section.footerClass"
        :has-footer="section.hasFooter"
        class="h-max shrink-0 overflow-hidden"
      >
        <template #card-content>
          <component :is="section.mainComponent" />
        </template>

        <template #card-footer>
          <component :is="section.footerComponent" />
        </template>
      </CustomCard>
    </template>

    <template #aside-content>
      <ul class="text-sm flex flex-col gap-4 text-gray-800">
        <li v-for="section in availableSections" :key="`link-${section.id}`">
          <a :href="`#${section.id}`">{{ $t(section.title) }}</a>
        </li>
      </ul>
    </template>
  </TableOfContentsLayout>
</template>
