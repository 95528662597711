import request, { getQueryData } from '@/api/base';

import { DEFAULT_GROUPS_SORT } from '@/constants/api';

import type {
  ApiErrorResponse,
  ApiResponse,
  ApiSimpleResponse,
  ApiTableParams,
  ApiTableResponse
} from '@/types/api';

import type {
  BulkAddUsersToGroupParams,
  CreateGroupParams,
  UpdateGroupParams,
  SingleGroupResponse,
  BulkDeleteGroupsParams,
  FetchGroupsSimplifiedParams,
  ExportGroupsParams
} from '@/api/group/types';

import type { GroupSimplified, Group, ListGroup } from '@/types/groups';

export async function fetchGroups(params?: ApiTableParams) {
  return await request
    .get<
      ApiTableResponse<ListGroup>
    >(`${import.meta.env.VITE_API_VERSION}/groups${getQueryData({ params, defaultSortField: DEFAULT_GROUPS_SORT })}`)
    .then(response => response.data);
}

export async function fetchGroupsSimplified({
  group_ids,
  params
}: FetchGroupsSimplifiedParams = {}) {
  return await request
    .get<ApiResponse<GroupSimplified[]>>(
      `${import.meta.env.VITE_API_VERSION}/groups/fetch/groups${getQueryData({ params })}`,
      {
        ...(group_ids && { params: { group_ids } })
      }
    )
    .then(response => response.data);
}

export async function fetchGroup(groupId: number) {
  return await request
    .get<
      ApiResponse<Group>
    >(`${import.meta.env.VITE_API_VERSION}/groups/${groupId}`)
    .then(response => response.data);
}

export async function createGroup(group: CreateGroupParams) {
  return await request
    .post<SingleGroupResponse>(
      `${import.meta.env.VITE_API_VERSION}/groups`,
      group
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function updateGroup(group: UpdateGroupParams) {
  return await request
    .patch<SingleGroupResponse>(
      `${import.meta.env.VITE_API_VERSION}/groups/${group.id}`,
      group
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function deleteGroup(groupId: number) {
  return await request
    .delete<SingleGroupResponse>(
      `${import.meta.env.VITE_API_VERSION}/groups/${groupId}`
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkAddUsersToGroups({
  group_ids,
  user_ids
}: BulkAddUsersToGroupParams) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/groups/bulk-actions/add-users`,
      {
        group_ids,
        user_ids
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function bulkDeleteGroups({ group_ids }: BulkDeleteGroupsParams) {
  return await request
    .delete<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/groups/bulk-actions/delete`,
      {
        data: {
          group_ids
        }
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function exportGroupsToExcel({
  group_ids,
  params
}: ExportGroupsParams = {}) {
  return await request
    .post<ApiSimpleResponse>(
      `${import.meta.env.VITE_API_VERSION}/groups/export${getQueryData({ params })}`,
      {
        ...(group_ids && { group_ids })
      }
    )
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}
