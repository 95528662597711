<script setup lang="ts">
import { inject, ref, type Ref } from 'vue';
import { AxiosError } from 'axios';

import { useFileDialog } from '@vueuse/core';
import { useToast } from 'vue-toastification';
import { useTranslation } from 'i18next-vue';

import { Button } from '@/components/ui/Button';

import { openDeleteLogoModal } from '@/lib/modals/organizationSettingsModals';
import { uploadOrganizationLogo } from '@/api/organization';

import { COMPONENT_VARIANTS } from '@/constants/component';
import { HTTP_CODES } from '@/constants/api';

import type { CurrentUser } from '@/types/users';
import type { UseModal } from '@/types/modals';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
const openModal = inject('openModal') as UseModal['openModal'];

const isLoading = ref<boolean>(false);

const toast = useToast();
const { t } = useTranslation();

const { files, open, onChange, reset } = useFileDialog({
  accept: 'image/*', // Restricts file types
  multiple: false
});

onChange(async () => {
  const formData = new FormData();
  const selectedFile: File | undefined = files.value?.[0];

  isLoading.value = true;

  if (selectedFile) {
    formData.append('image', selectedFile);

    const response = await uploadOrganizationLogo(
      currentUser.value.organization.id,
      selectedFile
    );

    if (
      response instanceof AxiosError &&
      response.response?.status &&
      Object.values(HTTP_CODES).includes(
        response.response.status as (typeof HTTP_CODES)[keyof typeof HTTP_CODES]
      )
    ) {
      toast.error(
        t('validation.uploaded', {
          attribute: t('common.logo_placeholder')
        })
      );
    } else {
      toast.success(t('organization.logo_upload_success'));
    }

    reset();

    isLoading.value = false;
  }
});
</script>

<template>
  <div class="w-full flex flex-col sm:flex-row sm:justify-end gap-3.5">
    <Button
      v-if="
        !currentUser.organization.profilePictureUrl.includes(
          'avatar-placeholder'
        )
      "
      :variant="COMPONENT_VARIANTS.destructive"
      :is-fetching="isLoading"
      class="min-w-25"
      @click="openDeleteLogoModal(openModal, currentUser.organization)"
      >{{ $t('common.delete') }}
    </Button>

    <Button
      :variant="COMPONENT_VARIANTS.info"
      :is-fetching="isLoading"
      class="min-w-25"
      @click="open"
      >{{ $t('organizationDashboard.upload') }}
    </Button>
  </div>
</template>

<style>
.Vue-Toastification__toast.custom-toast-container {
  font-family: 'Poppins', 'Lato', Helvetica, 'Roboto', Arial, sans-serif;
  width: 400px;
}

.Vue-Toastification__toast-body.custom-toast-body {
  font-size: 15px;
  line-height: 22px;
}

.Vue-Toastification__toast--success.custom-toast-container {
  background-color: rgb(16 185 129);
  color: #fff;
}

.Vue-Toastification__toast--error.custom-toast-container {
  background-color: rgb(239 68 68);
  color: #fff;
}
</style>
