import { computed, inject, type Ref, watch } from 'vue';
import { useValidationDataSimplified } from '@/lib/hooks/validation';

import { createValidationData, removeNotFoundItems } from '@/lib/validation';

import type { SavedModalState, UseModal } from '@/types/modals';

import type { DataSimplified } from '@/lib/hooks/table/useDataSimplified';
import type { ListContentType } from '@/types/components';
import type { AllowedValidationKeys } from '@/types/validation';

function useValidationError({
  listType,
  dataKey = 'user_ids',
  data,
  isFetching
}: {
  listType: ListContentType;
  dataKey?: AllowedValidationKeys;
  data: Ref<DataSimplified[]>;
  isFetching: Ref<boolean>;
}) {
  const { simplifiedData, isFetching: isValidationDataFetching } =
    useValidationDataSimplified(listType);
  const previousSavedState = inject(
    'previousSavedState'
  ) as Ref<SavedModalState>;
  const validationErrors = inject(
    'validationErrors'
  ) as UseModal['validationErrors'];
  const savedModalState = inject(
    'savedModalState'
  ) as UseModal['savedModalState'];
  const setModalStateByKey = inject(
    'setModalStateByKey'
  ) as UseModal['setModalStateByKey'];
  const validationData = computed(() => {
    return createValidationData<DataSimplified>(
      previousSavedState.value[dataKey as keyof SavedModalState] || [],
      simplifiedData.value,
      dataKey
    );
  });

  watch(isFetching, () => {
    if (validationErrors.value[dataKey]) {
      setModalStateByKey(
        dataKey as never,
        removeNotFoundItems(
          savedModalState.value[dataKey as keyof SavedModalState],
          data.value
        ) as never
      );
    }
  });

  return {
    validationData,
    isValidationDataFetching
  };
}

export default useValidationError;
