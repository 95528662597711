<script setup lang="ts">
import {
  PatentsPlatformStatistic,
  PatentsAPIStatistic,
  IdeaDatabaseStatistic
} from '@/views/Dashboard/components';

import { CustomCard } from '@/components/custom';
import { computed, inject, type Ref } from 'vue';
import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const showPatentsPlatformStatistic = computed(() => {
  return (
    currentUser.value.isPatentsPlatformAvailable &&
    (currentUser.value.isPatentsPlatformAdministrator ||
      currentUser.value.isIdeaDatabaseAdministrator)
  );
});
const showPatentsAPIStatistic = computed(() => {
  return (
    currentUser.value.isPatentsApiAvailable &&
    currentUser.value.isPatentsPlatformAdministrator
  );
});
const showIdeaDatabaseStatistic = computed(() => {
  return (
    currentUser.value.isIdeaDatabaseAvailable &&
    (currentUser.value.isPatentsPlatformAdministrator ||
      currentUser.value.isIdeaDatabaseAdministrator)
  );
});
</script>

<template>
  <CustomCard
    v-if="
      showPatentsPlatformStatistic ||
      showPatentsAPIStatistic ||
      showIdeaDatabaseStatistic
    "
    :card-title="$t('organizationDashboard.organization_statistics')"
    class="shrink-0"
    content-class="flex flex-col gap-12"
    has-header-separator
  >
    <template #card-content>
      <PatentsPlatformStatistic v-if="showPatentsPlatformStatistic" />
      <PatentsAPIStatistic v-if="showPatentsAPIStatistic" />
      <IdeaDatabaseStatistic v-if="showIdeaDatabaseStatistic" />
    </template>
  </CustomCard>
</template>
