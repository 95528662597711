import type { Ref } from 'vue';

export function setIsSidebarOpen(isOpen: boolean): void {
  localStorage.setItem('isSidebarOpen', isOpen.toString());
}

export function isSidebarOpened(isDesktop: Ref<boolean>): boolean {
  const isOpen = localStorage.getItem('isSidebarOpen');

  return typeof isOpen === 'string' ? isOpen === 'true' : isDesktop.value;
}
