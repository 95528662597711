import { computed, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchUsersSimplified } from '@/api/user';
import { fetchTokensSimplified } from '@/api/token';
import { fetchGroupsSimplified } from '@/api/group';

import {
  type SimplifiedDataKeys,
  GROUPS_SIMPLIFIED_API,
  TOKENS_SIMPLIFIED_API,
  USERS_SIMPLIFIED_API
} from '@/constants/api';

import { ACCOUNTS_LIST, TOKENS_LIST } from '@/constants/table';

import type { ApiResponse } from '@/types/api';
import type { UserSimplified } from '@/types/users';
import type { GroupSimplified } from '@/types/groups';
import type { ListContentType } from '@/types/components';
import type { TokenSimplified } from '@/types/tokens';

async function fetchQueryData(listType: Ref<ListContentType>) {
  if (listType.value === TOKENS_LIST) {
    return (await fetchTokensSimplified()) as SimplifiedApiResponse;
  }

  return (
    listType.value === ACCOUNTS_LIST
      ? await fetchGroupsSimplified()
      : await fetchUsersSimplified()
  ) as SimplifiedApiResponse;
}

export function getDataKey(
  listType: ListContentType | undefined
): SimplifiedDataKeys | undefined {
  if (!listType) {
    return undefined;
  }

  if (listType === TOKENS_LIST) {
    return TOKENS_SIMPLIFIED_API;
  }

  return listType === ACCOUNTS_LIST
    ? USERS_SIMPLIFIED_API
    : GROUPS_SIMPLIFIED_API;
}

function useDataSimplified(listType: Ref<ListContentType>) {
  const { data, isError, isFetching } = useQuery({
    queryKey: [getDataKey(listType.value)],
    queryFn: () => fetchQueryData(listType),
    select(data) {
      return data.data;
    }
  });

  const dataSimplified = computed<DataSimplified[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedData: dataSimplified, isError, isFetching };
}

export default useDataSimplified;

export type SimplifiedApiResponse = ApiResponse<
  UserSimplified[] | GroupSimplified[] | TokenSimplified[]
>;

export type DataSimplified = UserSimplified | GroupSimplified | TokenSimplified;
