<script setup lang="ts">
import { inject, type Ref } from 'vue';

import {
  PatentsPlatformUsers,
  ProgressCircle
} from '@/components/custom/Charts';

import { CustomAlert } from '@/components/custom/CustomAlert';
import { Heading } from '@/components/custom';
import { StatisticCard } from '@/views/Dashboard/components';

import { COMPONENT_VARIANTS } from '@/constants/component';
import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>

<template>
  <div class="flex gap-6 flex-col" data-testid="iamip-platform-statistics">
    <div class="flex flex-col gap-2.5">
      <Heading
        size="h4"
        class="text-lg md:text-xl"
        data-testid="iamip-platform-statistics-title"
      >
        {{ $t('organizationDashboard.patents_platform_statistics') }}
      </Heading>

      <p
        class="text-sm md:text-base text-gray-500 font-medium"
        data-testid="iamip-platform-statistics-description"
      >
        {{ $t('organizationDashboard.patents_platform_subtitle') }}
      </p>
    </div>

    <CustomAlert
      v-if="
        !currentUser.organizationStatistics.activeMonitorings &&
        !currentUser.organizationStatistics.limitMonitorings
      "
      :variant="COMPONENT_VARIANTS.info"
      :heading="$t('modalTitle.notice')"
      class="items-center"
      data-testid="statistics-inactive-monitoring-alert"
    >
      <p class="font-normal">
        {{ $t('organizationDashboard.monitoring_inactive') }}
      </p>
    </CustomAlert>

    <CustomAlert
      v-if="
        currentUser.organizationStatistics.limitMonitorings <=
          currentUser.organizationStatistics.activeMonitorings &&
        currentUser.organizationStatistics.limitMonitorings > 0
      "
      :variant="COMPONENT_VARIANTS.warning"
      :heading="$t('modalTitle.notice')"
      class="items-center"
      data-testid="statistics-monitoring-limit-alert"
    >
      <p class="font-normal">
        {{ $t('organizationDashboard.monitoring_limit_reached') }}
      </p>
    </CustomAlert>

    <div
      class="grid grid-cols-1 lg:grid-cols-2 w-full justify-start items-start gap-5"
    >
      <StatisticCard
        title="organizationDashboard.total_users"
        tooltip="organizationDashboard.total_users_info"
        data-testid="iamip-platform-statistics-total-users"
      >
        <ProgressCircle
          :current-value="
            currentUser.organizationStatistics.patentsUsersTotal.enabled
          "
          :max-value="
            currentUser.organizationStatistics.patentsUsersTotal.limit
          "
          sub-title="common.available"
        />
      </StatisticCard>

      <StatisticCard
        title="organizationDashboard.active_monitorings"
        tooltip="organizationDashboard.active_monitorings_info"
        data-testid="iamip-platform-statistics-active-monitorings"
      >
        <ProgressCircle
          :current-value="currentUser.organizationStatistics.activeMonitorings"
          :max-value="currentUser.organizationStatistics.limitMonitorings"
          sub-title="common.current_limit"
        />
      </StatisticCard>
    </div>

    <StatisticCard
      title="organizationDashboard.active_legal_status_watches"
      tooltip="organizationDashboard.active_legal_status_watches_info"
      data-testid="iamip-platform-statistics-legal-status-watches"
    >
      <p class="text-gray-800 text-3xl font-medium">
        {{ currentUser.organizationStatistics.activeLegalStatusWatches }}
      </p>
    </StatisticCard>

    <StatisticCard
      title="organizationDashboard.patents_platform_users"
      tooltip="organizationDashboard.patents_platform_users_info"
      data-testid="iamip-platform-statistics-platform-users"
    >
      <PatentsPlatformUsers />
    </StatisticCard>
  </div>
</template>
