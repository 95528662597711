<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';

import {
  DialogDescription,
  type DialogDescriptionProps,
  useForwardProps
} from 'radix-vue';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  DialogDescriptionProps & {
    class?: HTMLAttributes['class'];
  }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <DialogDescription
    v-bind="forwardedProps"
    :class="cn('text-base text-gray-500 font-medium', props.class)"
  >
    <slot />
  </DialogDescription>
</template>
