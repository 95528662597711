<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { Label, type LabelProps } from 'radix-vue';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<LabelProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <Label
    v-bind="delegatedProps"
    :class="
      cn(
        'text-gray-800 text-sm font-semibold leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
        props.class
      )
    "
  >
    <slot />
  </Label>
</template>
