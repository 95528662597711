<script setup lang="ts">
import TableOfContentsLayout from '@/layouts/TableOfContentsLayout/TableOfContentsLayout.vue';
import { CustomCard } from '@/components/custom';
import { ORGANIZATION_SETTINGS_SECTIONS } from '@/constants/organization';
import { computed, inject, type Ref } from "vue";
import type { CurrentUser } from "@/types/users";

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const availableSections = computed( () => {
  return Object.values(ORGANIZATION_SETTINGS_SECTIONS).filter(
    section => section.id !== 'force-two-factor' || currentUser.value.organization.twoFactorEnabled
  )
});
</script>

<template>
  <TableOfContentsLayout
    :heading="$t('organizationDashboard.organization_settings')"
    main-content-class="flex flex-col gap-5 pb-8"
  >
    <template #main-content>
      <CustomCard
        v-for="section in availableSections"
        :id="section.id"
        :key="`$card-${section.id}`"
        :card-title="$t(section.title)"
        :card-description="$t(section.description)"
        :has-footer="section.hasFooter"
        class="shrink-0 overflow-hidden"
      >
        <template #card-content>
          <component :is="section.mainComponent" />
        </template>

        <template #card-footer>
          <component :is="section.footerComponent" />
        </template>
      </CustomCard>
    </template>

    <template #aside-content>
      <ul class="text-sm flex flex-col gap-4 text-gray-800">
        <li
          v-for="section in availableSections"
          :key="`link-${section.id}`"
        >
          <a :href="`#${section.id}`">{{ $t(section.title) }}</a>
        </li>
      </ul>
    </template>
  </TableOfContentsLayout>
</template>
