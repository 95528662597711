<script setup lang="ts" generic="TData">
import { computed, ref, inject, onUpdated } from 'vue';
import { useMediaQuery } from '@vueuse/core';

import {
  Pagination,
  PaginationEllipsis,
  PaginationList,
  PaginationListItem,
  PaginationNext,
  PaginationPrev
} from '@/components/ui/Pagination';

import { Button } from '@/components/ui/Button';
import { CustomSelect } from '@/components/custom/CustomSelect';

import { cn, valueUpdater } from '@/lib/utils';

import {
  DEFAULT_PAGE_INDEX,
  PER_PAGE_DEFAULT,
  SIBLING_COUNT
} from '@/constants/table';

import { ALLOWED_BREAKPOINTS } from '@/constants/layout';
import { COMPONENT_VARIANTS } from '@/constants/component';

import type { Table } from '@tanstack/vue-table';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

const props = defineProps<{
  isFetching?: boolean;
  table: Table<TData>;
  total: number;
}>();

const currentPage = inject<UseAdvancedTable['currentPage']>('currentPage');
const perPage = inject<UseAdvancedTable['perPage']>('perPage');
const perPageOptions =
  inject<UseAdvancedTable['perPageOptions']>('perPageOptions');

const handleCurrentPageClick = inject<
  UseAdvancedTable['handleCurrentPageClick']
>('handleCurrentPageClick');
const handlePageSizeChange = inject<UseAdvancedTable['handlePageSizeChange']>(
  'handlePageSizeChange'
);

const handleNextPageClick = inject<UseAdvancedTable['handleNextPageClick']>(
  'handleNextPageClick'
);
const handlePrevPageClick = inject<UseAdvancedTable['handlePrevPageClick']>(
  'handlePrevPageClick'
);

const totalPages = ref<number>(props.total * 10);
const isDesktop = useMediaQuery(ALLOWED_BREAKPOINTS.lg);

const hasSinglePage = computed(
  () =>
    props.total * 10 < (perPage?.value ?? PER_PAGE_DEFAULT) ||
    totalPages.value === 0
);

function handlePerPageSelect(selectedValue: string): void {
  if (handlePageSizeChange) {
    handlePageSizeChange(Number(selectedValue));
  }
  return;
}

onUpdated((): void => {
  if (!Object.keys(props.table).length) {
    valueUpdater(PER_PAGE_DEFAULT, totalPages);
    return;
  }
  // INFO: Supplied pages number needs to be multiplied by 10
  totalPages.value =
    Math.min(props.total / (perPage?.value ?? PER_PAGE_DEFAULT)) * 10;
});
</script>

<template>
  <footer
    :class="
      cn(
        'grid grid-cols-2 grid-rows-2 md:grid-rows-1 md:grid-cols-5 gap-2.5 w-full items-center justify-between p-5 border-t border-t-gray-200 dark:border-t-gray-700',
        hasSinglePage && 'grid-cols-[1fr_2fr_1fr] grid-rows-1 md:grid-cols-3'
      )
    "
  >
    <div
      :class="
        cn(
          'order-2 md:order-none text-sm text-muted-foreground',
          hasSinglePage && 'order-1 md:order-none'
        )
      "
    >
      <p>
        <span v-if="isFetching" class="font-semibold">{{
          $t('common.loading')
        }}</span>
        <span v-else class="inline-block">
          <span class="font-semibold">{{ total }}</span>
        </span>
        <span :class="!isDesktop && hasSinglePage && 'block'">{{
          ` ${$t('advancedTable.selectedResults')}`
        }}</span>
      </p>
    </div>

    <Pagination
      v-slot="{ page }"
      :class="
        cn(
          'order-1 col-span-2 md:order-none md:col-span-3',
          hasSinglePage && 'order-2 col-span-1 md:order-none md:col-span-1'
        )
      "
      :disabled="isFetching || hasSinglePage"
      :page="currentPage"
      :show-edges="isDesktop"
      :sibling-count="SIBLING_COUNT"
      :total="hasSinglePage ? DEFAULT_PAGE_INDEX : totalPages"
    >
      <PaginationList
        v-slot="{ items }"
        class="flex justify-center items-center"
      >
        <PaginationPrev
          class="w-11 h-11 p-0 rounded-none rounded-tl-lg rounded-bl-lg border-r-0"
          @click="handlePrevPageClick && handlePrevPageClick()"
        />
        <template v-for="(item, index) in items">
          <PaginationListItem
            v-if="item.type === 'page'"
            :key="index"
            :value="item.value"
            as-child
          >
            <Button
              type="button"
              :class="
                cn(
                  'w-11 h-11 p-0 text-base text-gray-500 rounded-none border-r-0 shadow-none',
                  index === items.length - 1 && 'border-r-1',
                  item.value === page && 'bg-gray-100 dark:bg-slate-800'
                )
              "
              :variant="COMPONENT_VARIANTS.ghost"
              @click="
                handleCurrentPageClick && handleCurrentPageClick(item.value)
              "
            >
              <span>{{ item.value }}</span>
            </Button>
          </PaginationListItem>
          <PaginationEllipsis
            v-else
            :key="item.type"
            :index="index"
            class="w-11 h-11 rounded-none text-gray-500 border border-gray-200 dark:border-gray-700 border-r-0"
          />
        </template>
        <PaginationNext
          class="w-11 h-11 p-0 justify-center rounded-none rounded-tr-lg rounded-br-lg border-l-0"
          @click="handleNextPageClick && handleNextPageClick()"
        />
      </PaginationList>
    </Pagination>

    <div
      :class="
        cn(
          'h-min w-full max-w-36 order-2 flex items-center space-x-2 justify-self-end',
          hasSinglePage && 'order-3'
        )
      "
    >
      <CustomSelect
        :model-value="perPage ? perPage?.toString() : ''"
        :is-disabled="isFetching"
        :options="perPageOptions ?? []"
        class="w-full h-11 self-end"
        content-class="h-32"
        has-small-text
        @update:model-value="handlePerPageSelect($event)"
      />
      <p class="hidden md:block text-sm">{{ $t('advancedTable.perPage') }}</p>
    </div>
  </footer>
</template>
