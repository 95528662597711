import type {
  LanguagesResponse,
  ProfileSettingsParams
} from '@/api/profileSettings/types';
import request from '@/api/base';
import type { ApiErrorResponse, ApiSimpleResponse } from '@/types/api';

export async function updateProfile(profileSettings: ProfileSettingsParams) {
  return await request
    .patch<ApiSimpleResponse>(`${import.meta.env.VITE_API_VERSION}/profile`, {
      first_name: profileSettings.firstName,
      last_name: profileSettings.lastName,
      email: profileSettings.email,
      language: profileSettings.language,
      language_preference: profileSettings.languagePreference?.reduce(
        (acc: string[], cur: string) => {
          if (cur !== null) {
            acc.push(cur);
          }

          return acc;
        },
        [] as string[]
      )
    })
    .then(response => response.data)
    .catch(error => error as ApiErrorResponse);
}

export async function fetchLanguages() {
  return (await request
    .get<ApiSimpleResponse>(`${import.meta.env.VITE_API_VERSION}/languages`)
    .then(response => response.data.data)) as LanguagesResponse[];
}
