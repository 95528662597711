import { isEmpty, isEqual, pick } from 'lodash';

import { filterIdeaDatabaseRoles } from '@/lib/modals/accountsModals';

import {
  GROUP_FILTER,
  IDEA_DATABASE_ROLES_FILTER,
  LOGIN_TYPE_FILTER,
  MULTISELECT_FILTERING_TYPES,
  PATENTS_USER_TYPE,
  SELECT_FILTERING_TYPES,
  USER_STATUS_FILTER,
  prepareLoginTypeOptions,
  prepareStatusOptions,
  FILTER_TRANSLATIONS,
  USER_FILTER,
  TOKEN_ACCESS_LEVEL_FILTER,
  prepareTokenAccessTypeOptions,
  TOKEN_STATUS_FILTER
} from '@/constants/filtering';

import {
  IDEA_DATABASE_ROLES_NAMES,
  PATENTS_USER_TYPES
} from '@/constants/user';

import type {
  TableFilter,
  Filter,
  FilteringOperators,
  SelectFilteringTypes,
  MultiSelectFilteringTypes
} from '@/types/filtering';

import type {
  CurrentUser,
  IdeaDatabaseUserRole,
  UserSimplified
} from '@/types/users';

import type { GroupSimplified } from '@/types/groups';
import type { SelectOption } from '@/types/components';
import type { DataSimplified } from '@/lib/hooks/table/useDataSimplified';
import type { TFunction } from 'i18next';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';
import type { Ref } from 'vue';

// Filters out duplicated filters
export const getUniqueFilters = (filters: Filter[], filterToRemove: Filter) =>
  filters.filter(
    filter =>
      !isEqual(
        pick(filter, ['filterType']),
        pick(filterToRemove, ['filterType'])
      )
  );

// Returns unique filter types
export const getUniqueFilterTypes = (
  filterTypes: ReadonlyArray<TableFilter>,
  activeFilters: Filter[]
) =>
  activeFilters
    .filter(filter => filterTypes.includes(filter.filterType as TableFilter))
    .map(filter => filter.filterType);

// Returns select options of filter types
export const prepareFilterTypesOptions = (
  filterTypes: ReadonlyArray<TableFilter>,
  activeFilters: Filter[],
  t: TFunction
) => {
  const duplicatedFilters = getUniqueFilterTypes(filterTypes, activeFilters);

  const uniqueFilters =
    duplicatedFilters.length > 0
      ? filterTypes.filter(
          filterType => !duplicatedFilters.includes(filterType)
        )
      : filterTypes;

  return uniqueFilters.map(option => ({
    label: t(FILTER_TRANSLATIONS[option]) as string,
    value: option
  }));
};

// Returns select options of filtering operators
export const prepareOperatorOptions = (
  operators: FilteringOperators,
  filterState: Filter,
  t: TFunction
): SelectOption[] => {
  if (!filterState.filterType) return [];

  return operators.columns[filterState.filterType].map(
    (operator, index: number) => {
      return {
        label: t(`operators.${operator}`),
        value: operator,
        selected: index === 0
      };
    }
  );
};

// Returns select options of roles for both products
export const prepareRoleOptions = (
  filterType: TableFilter,
  t: TFunction,
  currentUser?: Ref<CurrentUser>
) => {
  const patentsUsers = filterType === PATENTS_USER_TYPE;

  const ideaDatabaseRoles = currentUser?.value
    ? filterIdeaDatabaseRoles(
        Object.keys(IDEA_DATABASE_ROLES_NAMES) as IdeaDatabaseUserRole[],
        currentUser
      )
    : (Object.keys(IDEA_DATABASE_ROLES_NAMES) as IdeaDatabaseUserRole[]);
  const userTypes = patentsUsers
    ? Object.keys(PATENTS_USER_TYPES)
    : ideaDatabaseRoles;

  return userTypes.map(userType => ({
    label: patentsUsers
      ? t(
          `${PATENTS_USER_TYPES[Number(userType) as keyof typeof PATENTS_USER_TYPES]}`
        )
      : t(`${IDEA_DATABASE_ROLES_NAMES[userType as IdeaDatabaseUserRole]}`),
    value: userType
  }));
};

const filteringSelectOptions = (
  filterType: TableFilter,
  t: TFunction,
  currentUser?: Ref<CurrentUser>
) => [
  { filter: LOGIN_TYPE_FILTER, options: prepareLoginTypeOptions(t) },
  {
    filter: PATENTS_USER_TYPE,
    options: prepareRoleOptions(filterType, t)
  },
  { filter: USER_STATUS_FILTER, options: prepareStatusOptions(t) },
  {
    filter: TOKEN_ACCESS_LEVEL_FILTER,
    options: prepareTokenAccessTypeOptions(t)
  },
  { filter: TOKEN_STATUS_FILTER, options: prepareStatusOptions(t) },
  {
    filter: IDEA_DATABASE_ROLES_FILTER,
    options: prepareRoleOptions(filterType, t, currentUser)
  }
];

// Returns select options based on filtering type
export const getFilteringSelectOptions = (
  filterType: TableFilter,
  t: TFunction,
  currentUser?: Ref<CurrentUser>
) =>
  filteringSelectOptions(filterType, t, currentUser).find(
    type => type.filter === filterType
  )?.options as SelectOption[];

// Returns parsed label text for filtering row badges
export const parseBadgeValue = (
  filterType: TableFilter,
  filterValue: string,
  filterValues: string[],
  dataSimplified: DataSimplified[],
  t: TFunction
) => {
  const selectOptions = getFilteringSelectOptions(filterType, t);

  if (SELECT_FILTERING_TYPES.includes(filterType as SelectFilteringTypes)) {
    return selectOptions?.find(option => option.value === filterValue)?.label;
  }

  if (
    MULTISELECT_FILTERING_TYPES.includes(
      filterType as MultiSelectFilteringTypes
    ) &&
    !isEmpty(filterValues)
  ) {
    if (filterType === GROUP_FILTER) {
      return (dataSimplified as GroupSimplified[])
        .filter(group => filterValues.includes(group.id.toString()))
        .map(group => group.name);
    }

    if (filterType === USER_FILTER) {
      return (dataSimplified as UserSimplified[])
        .filter(user => filterValues.includes(user.id.toString()))
        .map(user => user.fullName);
    }

    const filteredLabels = selectOptions
      ?.map(option => filterValues.find(value => value === option.value))
      .filter(option => Boolean(option));

    if (filterType === IDEA_DATABASE_ROLES_FILTER) {
      return filteredLabels?.map(label =>
        t(`${IDEA_DATABASE_ROLES_NAMES[label as IdeaDatabaseUserRole]}`)
      );
    }

    return filteredLabels;
  }
  return filterValue;
};

export function getSelectedIds({
  rowSelection,
  currentFilteredIds,
  isAllSelected
}: {
  rowSelection?: UseAdvancedTable['rowSelection'];
  currentFilteredIds?: UseAdvancedTable['currentFilteredIds'];
  isAllSelected?: UseAdvancedTable['isAllSelected'];
}) {
  const validRowSelectionIds = Object.keys(rowSelection?.value || {}).map(
    Number
  );
  const validCurrentFilteredIds = currentFilteredIds?.value || [];

  return isAllSelected?.value
    ? Array.from(new Set([...validRowSelectionIds, ...validCurrentFilteredIds]))
    : validRowSelectionIds;
}
