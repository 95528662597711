import { computed } from 'vue';
import { useSelectedIds } from '@/lib/hooks';
import { useQuery } from '@tanstack/vue-query';

import { fetchUsersSimplifiedWithStatus } from '@/api/user';

import { USERS_SIMPLIFIED_STATUS_API } from '@/constants/api';

import type { UserSimplifiedWithStatus } from '@/types/users';

function useUsersSimplifiedStatus(useParams = false) {
  const selectedIds = useSelectedIds(useParams);

  const { data, isError, isFetching } = useQuery({
    queryKey: [USERS_SIMPLIFIED_STATUS_API, selectedIds],
    queryFn: () =>
      fetchUsersSimplifiedWithStatus({
        user_ids: selectedIds.value
      }),
    select(data) {
      return data.data;
    }
  });

  const simplifiedUsersWithStatus = computed<UserSimplifiedWithStatus[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedUsersWithStatus, isError, isFetching };
}

export default useUsersSimplifiedStatus;
