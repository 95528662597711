<script setup lang="ts">
import { inject, type Ref } from 'vue';
import { cn } from '@/lib/utils';

import type { CurrentUser } from '@/types/users';

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
</script>

<template>
  <div
    :class="
      cn(
        'w-25 h-25 flex justify-center items-center bg-gray-100 text-sm border border-gray-200 rounded-xl overflow-hidden',
        currentUser.organization.profilePictureUrl.includes(
          'avatar-placeholder'
        ) && 'flex justify-center items-center'
      )
    "
  >
    <img
      :src="currentUser.organization.profilePictureUrl"
      :alt="$t('common.logo_placeholder')"
      class="w-auto max-w-25 h-auto max-h-25 object-contain"
    />
  </div>
</template>
