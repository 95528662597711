<script setup lang="ts">
import { computed, inject } from 'vue';

import {
  Trash2,
  MessageSquareText,
  LockKeyholeOpen,
  MessageSquarePlus,
  Ellipsis
} from 'lucide-vue-next';

import {
  CustomDropdownMenu,
  CustomDropdownMenuItem
} from '@/components/custom/CustomDropdownMenu';

import { Button } from '@/components/ui/Button';
import { CustomCollapsible } from '@/components/custom/CustomCollapsible';

import EditIcon from 'assets/icons/edit-05.svg?component';

import {
  openAddNoteModal,
  openDeleteNoteModal,
  openEditNoteModal
} from '@/lib/modals/notesModals';

import {
  openAccountDeleteModal,
  openAccountEditModal,
  openTriggerResetPasswordModal
} from '@/lib/modals/accountsModals';

import { BUTTON_VARIANTS } from '@/constants/component';

import type { ListUser } from '@/types/users';
import type { UseModal } from '@/types/modals';
import { PATENTS_EXTERNAL } from "@/constants/user";

const props = defineProps<{
  user: ListUser;
}>();

const shouldDisplayTriggerResetPassword = computed(() => {
  const { ideaDatabaseProductAvailable, patentsProductAvailable, patentsPlatformUserType, isSsoEnabled } = props.user;

  const anyProductAvailable = patentsProductAvailable || ideaDatabaseProductAvailable;
  const isExternal = patentsPlatformUserType === PATENTS_EXTERNAL;

  return anyProductAvailable && (!isExternal || ideaDatabaseProductAvailable) && !isSsoEnabled;
});

const openModal = inject('openModal') as UseModal['openModal'];
</script>

<template>
  <div class="flex justify-center" @click.stop>
    <CustomDropdownMenu>
      <template #trigger>
        <Button type="button" :variant="BUTTON_VARIANTS.icon">
          <Ellipsis class="w-6 h-6 text-gray-800" />
        </Button>
      </template>

      <template #menu-options>
        <CustomDropdownMenuItem
          item-class="stroke-1"
          :item-label="$t('manageAccounts.edit_account')"
          :item-icon="EditIcon"
          @click="openAccountEditModal(openModal, user)"
        />
        <CustomDropdownMenuItem
          v-if="shouldDisplayTriggerResetPassword"
          :item-label="$t('manageAccounts.trigger_password_action')"
          :item-icon="LockKeyholeOpen"
          @click="openTriggerResetPasswordModal(openModal, user)"
        />
        <CustomDropdownMenuItem
          v-if="!user.note"
          :item-label="$t('manageAccounts.add_note_action')"
          :item-icon="MessageSquarePlus"
          item-class="stroke-gray-800"
          @click="openAddNoteModal(openModal, user)"
        />
        <CustomCollapsible
          v-else
          :item-icon="MessageSquareText"
          active-class="text-blue-600 stroke-blue-600"
          is-stretched
          is-seperated
          trigger-styles="justify-start stroke-gray-800"
          :trigger-label="$t('manageAccounts.manage_note')"
        >
          <CustomDropdownMenuItem
            class="pl-6"
            :item-label="$t('manageAccounts.edit_note_action')"
            @click="openEditNoteModal(openModal, user)"
          />
          <CustomDropdownMenuItem
            class="pl-6 text-red-500"
            :item-label="$t('manageAccounts.delete_note_action')"
            @click="openDeleteNoteModal(openModal, user)"
          />
        </CustomCollapsible>
        <CustomDropdownMenuItem
          class="text-red-500"
          :item-label="$t('manageAccounts.delete_account')"
          :item-icon="Trash2"
          @click="openAccountDeleteModal(openModal, user)"
        />
      </template>
    </CustomDropdownMenu>
  </div>
</template>
