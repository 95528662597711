<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@/lib/utils';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>

<template>
  <th
    :class="
      cn(
        'h-10 px-4 text-left align-middle uppercase text-xs font-semibold text-slate-500 [&:has([role=checkbox])]:pr-0 dark:text-slate-400',
        props.class
      )
    "
  >
    <slot />
  </th>
</template>
