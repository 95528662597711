import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchUsersSimplified } from '@/api/user';
import { fetchTokensSimplified } from '@/api/token';
import { fetchGroupsSimplified } from '@/api/group';

import {
  type DataSimplified,
  getDataKey,
  type SimplifiedApiResponse
} from '@/lib/hooks/table/useDataSimplified';

import { ACCOUNTS_LIST, TOKENS_LIST } from '@/constants/table';

import type { ListContentType } from '@/types/components';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

export async function fetchQueryData(
  listType: ListContentType | undefined,
  currentParams?: UseAdvancedTable['filteringParams'] | undefined
) {
  if (!listType) {
    return new Promise<SimplifiedApiResponse>(resolve => resolve({ data: [] }));
  }

  if (listType === TOKENS_LIST) {
    return (await fetchTokensSimplified({
      params: currentParams
    })) as SimplifiedApiResponse;
  }

  return (
    listType === ACCOUNTS_LIST
      ? await fetchUsersSimplified({ params: currentParams })
      : await fetchGroupsSimplified({ params: currentParams })
  ) as SimplifiedApiResponse;
}

function useTableDataSimplified(
  listType: ListContentType | undefined,
  filteringParams: UseAdvancedTable['filteringParams']
) {
  const { data, isError, isFetching } = useQuery({
    queryKey: [getDataKey(listType), filteringParams],
    queryFn: () => fetchQueryData(listType, filteringParams),
    select(data) {
      return data.data;
    }
  });

  const dataSimplified = computed<DataSimplified[]>(() => {
    return data.value ?? [];
  });

  return { simplifiedData: dataSimplified, isError, isFetching };
}

export default useTableDataSimplified;
