<script setup lang="ts">
import { CollapsibleTrigger, type CollapsibleTriggerProps } from 'radix-vue';

interface CustomCollapsibleTriggerProps extends CollapsibleTriggerProps {
  class?: string;
}

const props = defineProps<CustomCollapsibleTriggerProps>();
</script>

<template>
  <CollapsibleTrigger
    v-bind="props"
    class="hover:bg-transparent dark:hover:bg-transparent"
  >
    <slot />
  </CollapsibleTrigger>
</template>
