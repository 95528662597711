<script setup lang="ts">
import { type HTMLAttributes } from 'vue';
import { useVModel } from '@vueuse/core';

import i18next from 'i18next';
import { DatePicker } from 'v-calendar';
import 'assets/calendar.css';

import { cn } from '@/lib/utils';

import { inputVariants } from '@/components/ui/Input';

import {
  CALENDAR_CONFIG,
  CALENDAR_MASK,
  DEFAULT_CALENDAR_LANGUAGE,
  FIRST_DAY_OF_THE_WEEK
} from '@/constants/date';

import { STATUS_DEFAULT } from '@/constants/status';

const props = defineProps<{
  class?: HTMLAttributes['class'];
  modelValue: {
    start: string | Date;
    end: string | Date;
  };
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', payload: typeof props.modelValue): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true
});
</script>

<template>
  <DatePicker
    v-model.range="modelValue"
    class="calendar"
    :masks="{
      modelValue: CALENDAR_MASK,
      input: CALENDAR_MASK
    }"
    :popover="{
      visibility: 'focus'
    }"
    :first-day-of-week="FIRST_DAY_OF_THE_WEEK"
    :locale="i18next.language || DEFAULT_CALENDAR_LANGUAGE"
    :model-config="CALENDAR_CONFIG"
    trim-weeks
  >
    <template #default="{ inputValue, inputEvents }">
      <div class="flex flex-col justify-center items-center gap-2">
        <input
          :class="cn(inputVariants({ variant: STATUS_DEFAULT, size: 'small' }))"
          :value="inputValue.start"
          v-on="inputEvents.start"
        />
        <input
          :class="cn(inputVariants({ variant: STATUS_DEFAULT, size: 'small' }))"
          :value="inputValue.end"
          v-on="inputEvents.end"
        />
      </div>
    </template>
  </DatePicker>
</template>
