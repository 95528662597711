import { unref } from 'vue';
import axios from 'axios';

import { IDEA_DATABASE_ROLES_FILTER } from '@/constants/filtering';
import { SORT_ASCENDING } from '@/constants/sorting';
import { IDEA_DATABASE_USER_ADMIN } from '@/constants/user';

import type { ApiTableParams } from '@/types/api';
import type { Filter } from '@/types/filtering';
import type { SortedColumns } from '@/types/sorting';

axios.defaults.baseURL = '/';

const request = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

request.interceptors.request.use(config => {
  config.data = config.data ?? {};

  return config;
});

export function getPaginationQuery(perPage?: number, page?: number): string {
  if (!perPage || !page) {
    return '';
  }

  return `per_page=${perPage}&page=${page}`;
}

export function getSearchQuery(searchQuery?: string): string {
  const searchQueryValue = searchQuery?.trim() || '';

  return searchQueryValue.length
    ? `search=${encodeURIComponent(searchQueryValue)}`
    : '';
}

export function getSortingQuery(
  sortedColumns?: SortedColumns,
  defaultSortField = ''
): string {
  if (!sortedColumns) {
    return '';
  }

  const activeColumn = sortedColumns?.find(column => column.isActive);
  const sortType = activeColumn?.name ?? defaultSortField;
  const sortingOrder = activeColumn?.order ?? SORT_ASCENDING;

  return `sort_by=${sortType}&order=${sortingOrder}`;
}

export function getFilteringQuery(filters?: Filter[]) {
  if (!filters || !filters?.length) {
    return '';
  }

  const getOperatorQuery = (filter: Filter) =>
    `&filter[${filter.filterType}][operator]=${filter.operator}`;

  const getValueQuery = (filter: Filter) => {
    if (filter.values.length > 0) {
      const parsedFilterValues = filter.values.map(filter =>
        filter === IDEA_DATABASE_USER_ADMIN ? 1 : filter
      );

      const filterValuesQuery = parsedFilterValues.map(
        filterValue =>
          `&filter[${filter.filterType}][value]${filter.filterType === IDEA_DATABASE_ROLES_FILTER ? '' : '[]'}=${encodeURIComponent(filterValue)}`
      );

      return `${filterValuesQuery.join('')}`;
    }

    return `&filter[${filter.filterType}][value]=${encodeURIComponent(filter.value)}`;
  };

  return filters
    .map(filter => `${getOperatorQuery(filter)}${getValueQuery(filter)}`)
    .join('')
    .slice(1);
}

function parseQuery(prev: string, next: string): string {
  return prev.length && next.length ? `${prev}&${next}` : prev || next;
}

function getAdditionalFiltersQuery(
  hasPrevQuery: boolean,
  additionalFilters?: string
) {
  if (!additionalFilters?.length) {
    return '';
  }

  return hasPrevQuery ? `&${additionalFilters}` : `?${additionalFilters}`;
}

export function getQueryData({
  params,
  defaultSortField,
  additionalFilters
}: {
  params?: ApiTableParams;
  defaultSortField?: string;
  additionalFilters?: string;
}): string {
  if (!params) {
    return getAdditionalFiltersQuery(false, additionalFilters);
  }

  const paramsValue = unref(params);
  const queryWithPagination = getPaginationQuery(
    paramsValue.perPage,
    paramsValue.page
  );
  const queryWithSearch = parseQuery(
    queryWithPagination,
    getSearchQuery(paramsValue.searchQuery)
  );
  const queryWithSorting = parseQuery(
    queryWithSearch,
    getSortingQuery(paramsValue.sortedColumns, defaultSortField)
  );
  const queryWithFiltering = parseQuery(
    queryWithSorting,
    getFilteringQuery(paramsValue.filters)
  );

  return queryWithFiltering.length
    ? `?${queryWithFiltering}${getAdditionalFiltersQuery(true, additionalFilters)}`
    : getAdditionalFiltersQuery(false, additionalFilters);
}

export default request;
