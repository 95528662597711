<script setup lang="ts">
import { computed, type InputTypeHTMLAttribute } from 'vue';

import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';

import { INPUT_MESSAGES } from '@/constants/component';

import type { ValidationStatuses } from '@/types/components';
import type { ParseKeys } from 'i18next';
import type { ValidationMessage } from '@/types/validation';

const props = defineProps<{
  attribute: ParseKeys;
  value: string;
  id: string;
  type?: InputTypeHTMLAttribute;
  minSize?: number;
  isDisabled?: boolean;
  labelText?: ParseKeys;
  placeholder: string;
  validationStatus: ValidationStatuses;
  showErrorMessage: boolean;
  messages?: ValidationMessage;
}>();
defineEmits<{
  (e: 'changeValue', payload: string): void;
}>();

const inputMessages = computed(() => {
  return (props.messages ?? INPUT_MESSAGES)(props.attribute, props.minSize);
});
</script>

<template>
  <div class="w-full flex-col justify-start items-start gap-2.5 flex">
    <Label v-if="labelText" :for="id">{{ $t(labelText) }} </Label>

    <Input
      :id="id"
      :type="type"
      :is-disabled="isDisabled"
      :validation-status="validationStatus"
      :show-error-message="showErrorMessage"
      :messages="inputMessages"
      :placeholder="placeholder"
      :model-value="value"
      @update:model-value="$emit('changeValue', $event as string)"
    />
  </div>
</template>
