import { inject } from 'vue';
import { useTableData } from '@/lib/hooks';

import { fetchTokens } from '@/api/token';

import { TOKENS_API } from '@/constants/api';

import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';
import type { ListToken } from '@/types/tokens';

function useTokens() {
  const apiParams = inject<UseAdvancedTable['apiParams']>('apiParams');

  const { data, isFetching, isError, isPlaceholderData } =
    useTableData<ListToken>({
      apiParams,
      apiKey: TOKENS_API,
      fetchApiData: fetchTokens
    });

  return { tokens: data, isFetching, isError, isPlaceholderData };
}

export default useTokens;
