<script setup lang="ts">
import { FileQuestionIcon } from 'lucide-vue-next';

import { Button } from '@/components/ui/Button';

import { COMPONENT_VARIANTS, ELEMENT_SIZES } from '@/constants/component';

import type { ParseKeys } from 'i18next';

const props = defineProps<{
  notFoundText: ParseKeys;
  addFirstText: ParseKeys;
  createNewItemText: ParseKeys;
}>();
const emits = defineEmits<{
  (e: 'createNewItem'): void;
}>();
</script>

<template>
  <div class="w-full flex-col justify-center items-center gap-9 inline-flex">
    <div class="w-16 h-16">
      <div
        class="w-16 h-16 rounded-lg bg-blue-100 justify-center items-center inline-flex"
      >
        <FileQuestionIcon class="relative w-8 h-8 stroke-blue-800" />
      </div>
    </div>
    <div class="flex-col justify-center items-center gap-2 flex">
      <div class="text-black text-3xl font-semibold">
        {{ $t(props.notFoundText) }}
      </div>
      <div class="text-gray-500 text-lg font-normal">
        {{ $t(props.addFirstText) }}
      </div>
    </div>
    <Button
      type="button"
      :variant="COMPONENT_VARIANTS.info"
      :size="ELEMENT_SIZES.small"
      class="px-4 grow-[2] rounded-lg"
      @click="emits('createNewItem')"
    >
      <span>{{ $t(props.createNewItemText) }}</span>
    </Button>
  </div>
</template>
