<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { PaginationEllipsis, type PaginationEllipsisProps } from 'radix-vue';
import { MoreHorizontal } from 'lucide-vue-next';

import { cn } from '@/lib/utils';
import { createDelegatedProps } from '@/lib/helpers';

const props = defineProps<
  PaginationEllipsisProps & { class?: HTMLAttributes['class'] }
>();

const delegatedProps = computed(() =>
  createDelegatedProps<typeof props>(props)
);
</script>

<template>
  <PaginationEllipsis
    v-bind="delegatedProps"
    :class="cn('w-9 h-9 flex items-center justify-center', props.class)"
  >
    <slot>
      <MoreHorizontal />
    </slot>
  </PaginationEllipsis>
</template>
