import { t } from '@/i18next';

import type { ParseKeys } from 'i18next';

// Table layout
export const DEFAULT_COL_SPAN = 1;
export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_ROW_SPAN = 1;
export const SIBLING_COUNT = 2;
export const DEFAULT_COLUMN_MAX_SIZE = 50;

export const DISABLED_VIEW_ACTION_LIST = [
  'actions',
  'select',
  'active',
  'isEnabled'
] as const;

// Pagination
export const PER_PAGE_DEFAULT = 10;
export const PER_PAGE = [PER_PAGE_DEFAULT, 25, 50, 100, 500] as const;

// List content
export const ACCOUNTS_LIST = 'accounts';
export const GROUPS_LIST = 'groups';
export const TOKENS_LIST = 'tokens';

// Custom table constants
export const FULLNAME_COLUMN = 'fullName';
export const EMAIL_COLUMN = 'email';
export const GROUPS_COLUMN = 'groups';
export const LOGIN_TYPE = 'loginType';
export const ROLES_COLUMN = 'roles';
export const USER_TYPE_COLUMN = 'userType';
export const NAME_COLUMN = 'name';
export const DESCRIPTION_COLUMN = 'description';
export const PRODUCT_ACCESS_COLUMN = 'productAccess';

export const DEFAULT_RENDERED_ROWS = [0, 1, 2, 3] as const;

export const EXTENDED_COLUMNS = [GROUPS_COLUMN] as const;

export const SORTABLE_COLUMNS = [NAME_COLUMN] as const;

export function createColumn(
  key: AllowedColumns,
  label: ParseKeys,
  tooltip?: ParseKeys
) {
  return {
    key: key,
    label: t(label),
    tooltip: tooltip ? t(tooltip) : ''
  } as const;
}

export const COLUMNS_MAP = {
  [NAME_COLUMN]: () =>
    createColumn(NAME_COLUMN, 'common.name', 'manageGroups.sortByName'),
  [DESCRIPTION_COLUMN]: () =>
    createColumn(DESCRIPTION_COLUMN, 'manageGroups.description'),
  [PRODUCT_ACCESS_COLUMN]: () =>
    createColumn(PRODUCT_ACCESS_COLUMN, 'manageAccounts.productAccess'),
  [FULLNAME_COLUMN]: () => createColumn(FULLNAME_COLUMN, 'common.name'),
  [EMAIL_COLUMN]: () => createColumn(EMAIL_COLUMN, 'manageAccounts.email'),
  [GROUPS_COLUMN]: () => createColumn(GROUPS_COLUMN, 'modals.assigned_groups'),
  [LOGIN_TYPE]: () => createColumn(LOGIN_TYPE, 'modals.login_method'),
  [ROLES_COLUMN]: () => createColumn(ROLES_COLUMN, 'manageAccounts.user_roles'),
  [USER_TYPE_COLUMN]: () =>
    createColumn(USER_TYPE_COLUMN, 'manageAccounts.patents_platform_user_type')
} as const;

function isExtendedColumn(column: AllowedColumns) {
  return EXTENDED_COLUMNS.some(extendedColumn => extendedColumn === column);
}

function isSortableColumn(column: AllowedColumns) {
  return SORTABLE_COLUMNS.some(extendedColumn => extendedColumn === column);
}

export function createTableColumns(columns: AllowedColumns[]) {
  return columns.map(column => ({
    isExtended: isExtendedColumn(column),
    isSortable: isSortableColumn(column),
    value: COLUMNS_MAP[column]()
  }));
}

export function renderRow(cellData: unknown) {
  if (Array.isArray(cellData)) {
    return cellData.length ? cellData.join(', ') : t('common.none');
  }

  return cellData || t('common.none');
}

export type AllowedColumns =
  | typeof FULLNAME_COLUMN
  | typeof EMAIL_COLUMN
  | typeof GROUPS_COLUMN
  | typeof LOGIN_TYPE
  | typeof ROLES_COLUMN
  | typeof USER_TYPE_COLUMN
  | typeof NAME_COLUMN
  | typeof DESCRIPTION_COLUMN
  | typeof PRODUCT_ACCESS_COLUMN;

export type CustomTableColumn = ReturnType<typeof createTableColumns>[number];
