<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue';

import { Label } from '@/components/ui/Label';
import { Textarea } from '@/components/ui/Textarea';

import { cn } from '@/lib/utils';

import { INPUT_MESSAGES } from '@/constants/component';
import { STATUS_DEFAULT } from '@/constants/status';

import type { ParseKeys } from 'i18next';
import type { ValidationStatuses } from '@/types/components';
import type { ValidationMessage } from '@/types/validation';

const props = withDefaults(
  defineProps<{
    attribute: ParseKeys;
    value: string;
    id: string;
    labelText: ParseKeys;
    description?: ParseKeys | null;
    validationStatus?: ValidationStatuses;
    isDisabled?: boolean;
    isReadonly?: boolean;
    placeholder: string;
    showErrorMessage?: boolean;
    class?: HTMLAttributes['class'];
    // eslint-disable-next-line vue/require-default-prop
    messages?: ValidationMessage;
  }>(),
  {
    class: null,
    description: null,
    showErrorMessage: false,
    validationStatus: STATUS_DEFAULT,
    isDisabled: false,
    isReadonly: false
  }
);

const textareaMessages = computed(() => {
  return (props.messages ?? INPUT_MESSAGES)(props.attribute);
});

defineEmits<{
  (e: 'changeValue', payload: string): void;
}>();
</script>

<template>
  <div class="w-full flex-col justify-start items-start gap-2.5 flex">
    <Label :for="id" class="text-gray-800 text-sm font-semibold">
      {{ $t(labelText) }}
    </Label>
    <p v-if="description" class="text-sm text-gray-500">
      {{ $t(description) }}
    </p>
    <Textarea
      :id="id"
      :model-value="value"
      :validation-status="validationStatus"
      :class="cn('resize-none h-48', $props.class)"
      :placeholder="placeholder"
      :messages="textareaMessages"
      :show-error-message="showErrorMessage"
      :is-disabled="isDisabled"
      :is-readonly="isReadonly"
      @update:model-value="$emit('changeValue', $event)"
    />
  </div>
</template>
