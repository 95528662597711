import type { CalendarSlotName } from 'v-calendar/dist/types/src/components/Calendar/CalendarSlot.vue';

export const CALENDAR_MASK = { weekdays: 'WW' };
export const CALENDAR_MODE_TIME = 'time';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_CALENDAR_COLUMNS = 1;
export const DEFAULT_CALENDAR_LANGUAGE = 'en-EN';
export const FIRST_DAY_OF_THE_WEEK = 2;
export const RANGE_DATE = 'range';
export const SINGLE_DATE = 'single';

export const CALENDAR_SLOT_NAMES: CalendarSlotName[] = [
  'day-content',
  'day-popover',
  'dp-footer',
  'footer',
  'header-title-wrapper',
  'header-title',
  'header-prev-button',
  'header-next-button',
  'nav',
  'nav-prev-button',
  'nav-next-button',
  'page',
  'time-header'
];

export const CALENDAR_CONFIG = {
  type: 'string',
  mask: DATE_FORMAT
};
