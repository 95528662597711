<script setup lang="ts">
import { computed, inject, ref, type Ref } from 'vue';
import { useTranslation } from 'i18next-vue';
import { useMediaQuery } from '@vueuse/core';
import { useDataSimplified } from '@/lib/hooks';

import { X } from 'lucide-vue-next';
import { format } from 'date-fns';

import { OnClickOutside } from '@vueuse/components';

import {
  CustomDatePicker,
  RangePicker,
  MultiSelect
} from '@/components/custom';

import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { CustomSelect } from '@/components/custom/CustomSelect';

import {
  getFilteringSelectOptions,
  prepareFilterTypesOptions,
  prepareOperatorOptions,
  prepareRoleOptions
} from '@/lib/filtering';

import { cn } from '@/lib/utils';

import {
  ACCOUNTS_FILTERS,
  FILTERING_OPERATORS,
  BETWEEN_OPERATOR,
  DATE_FILTER,
  FILTERING_POPUP_INITIAL_STATE,
  GROUP_FILTER,
  IDEA_DATABASE_ROLES_FILTER,
  MULTISELECT_FILTERING_TYPES,
  SELECT_FILTERING_TYPES,
  GROUPS_FILTERS,
  TOKENS_FILTERS,
  USER_FILTER
} from '@/constants/filtering';

import { ACCOUNTS_LIST, GROUPS_LIST } from '@/constants/table';
import { ALLOWED_BREAKPOINTS } from '@/constants/layout';
import { BUTTON_VARIANTS } from '@/constants/component';
import { DATE_FORMAT } from '@/constants/date';

import type {
  TableFilter,
  Filter,
  DateOperators,
  MultiSelectFilteringTypes,
  SelectFilteringTypes
} from '@/types/filtering';

import type { CurrentUser, UserSimplified } from '@/types/users';

import type { GroupSimplified } from '@/types/groups';
import type { ListContentType } from '@/types/components';
import type { UseAdvancedTable } from '@/lib/hooks/table/useAdvancedTable';

const props = defineProps<{ activeFilters: Filter[] }>();

defineEmits<{
  (e: 'close-popup'): void;
}>();

const currentListContent = inject('currentListContent') as Ref<ListContentType>;
const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;

const handleFilterCreate = inject(
  'handleFilterCreate'
) as UseAdvancedTable['handleFilterCreate'];

const handlePopupToggle =
  inject<UseAdvancedTable['handlePopupToggle']>('handlePopupToggle');

const { simplifiedData } = useDataSimplified(currentListContent);

const newFilter = ref({ ...FILTERING_POPUP_INITIAL_STATE });

const { t } = useTranslation();
const isLargeScreen = useMediaQuery(ALLOWED_BREAKPOINTS.lg);

const handleNewFilterCreate = (isEnter = false) => {
  if (
    isEnter &&
    MULTISELECT_FILTERING_TYPES.includes(
      newFilter.value.filterType as MultiSelectFilteringTypes
    )
  ) {
    return;
  }

  if (newFilter.value.operator === BETWEEN_OPERATOR) {
    newFilter.value.value = `${format(newFilter.value.dateRange.start, DATE_FORMAT)}:${format(newFilter.value.dateRange.end, DATE_FORMAT)}`;
  }

  if (
    !newFilter.value.value &&
    DATE_FILTER.includes(newFilter.value.operator as DateOperators) &&
    newFilter.value.operator !== BETWEEN_OPERATOR
  ) {
    newFilter.value.value = format(new Date(), DATE_FORMAT);
  }

  if (
    newFilter.value.filterType.length &&
    newFilter.value.operator.length &&
    (newFilter.value.value.length || newFilter.value.values.length)
  ) {
    handleFilterCreate(newFilter.value);
    newFilter.value.filterType = '' as Filter['filterType'];
    newFilter.value.operator = '';
    newFilter.value.value = '';
    newFilter.value.values = [];
  }
};

const onFilterTypeSelect = (selectedType: TableFilter) => {
  newFilter.value.filterType = selectedType;
  newFilter.value.operator = '';
  newFilter.value.value = '';
  newFilter.value.values = [];
};

const onFilterRemove = (filterValue: string | number) => {
  newFilter.value.values = newFilter.value.values.filter(
    filter => filter !== filterValue
  );
};

const filterTypeOptions = computed(() => {
  if (currentListContent.value === ACCOUNTS_LIST) {
    return prepareFilterTypesOptions(
      ACCOUNTS_FILTERS as ReadonlyArray<TableFilter>,
      props.activeFilters,
      t
    );
  }

  if (currentListContent.value === GROUPS_LIST) {
    return prepareFilterTypesOptions(
      GROUPS_FILTERS as ReadonlyArray<TableFilter>,
      props.activeFilters,
      t
    );
  }

  return prepareFilterTypesOptions(
    TOKENS_FILTERS as ReadonlyArray<TableFilter>,
    props.activeFilters,
    t
  );
});

const operatorsOptions = computed(() => {
  return prepareOperatorOptions(
    FILTERING_OPERATORS,
    newFilter.value as Filter,
    t
  );
});

const multiSelectOptions = computed(() => {
  if (newFilter.value.filterType === IDEA_DATABASE_ROLES_FILTER) {
    return prepareRoleOptions(newFilter.value.filterType, t, currentUser);
  }

  if (newFilter.value.filterType === GROUP_FILTER && simplifiedData.value) {
    return (simplifiedData.value as GroupSimplified[]).map(group => ({
      label: group.name,
      value: group.id.toString()
    }));
  }

  if (newFilter.value.filterType === USER_FILTER && simplifiedData.value) {
    return (simplifiedData.value as UserSimplified[]).map(user => ({
      label: user.fullName,
      value: user.id.toString()
    }));
  }

  return [];
});

const selectValueOptions = computed(() => {
  if (
    newFilter.value.filterType &&
    SELECT_FILTERING_TYPES.includes(
      newFilter.value.filterType as SelectFilteringTypes
    )
  ) {
    return getFilteringSelectOptions(newFilter.value.filterType, t);
  }

  return [];
});

const isApplyDisabled = computed(() => {
  if (DATE_FILTER.includes(newFilter.value.operator as DateOperators)) {
    return newFilter.value.operator === BETWEEN_OPERATOR
      ? !newFilter.value.dateRange.start && !newFilter.value.dateRange.end
      : !newFilter.value.value.length || !newFilter.value.dateRange.start;
  }

  return !newFilter.value.value.length && !newFilter.value.values.length;
});
</script>

<template>
  <OnClickOutside @trigger="handlePopupToggle && handlePopupToggle(false)">
    <div
      :class="
        cn(
          'h-max rounded-xl w-72 bg-white shadow-lg border border-gray-200',
          newFilter.operator.length > 0 && isLargeScreen && 'w-140'
        )
      "
      @keyup.enter="handleNewFilterCreate(true)"
    >
      <header
        class="w-full flex justify-between bg-gray-100 px-4 py-2 rounded-t-lg"
      >
        <p class="text-gray-800 text-sm">{{ $t('common.add_new_filter') }}</p>
        <Button
          :variant="BUTTON_VARIANTS.icon"
          size="icon"
          @click="$emit('close-popup')"
        >
          <X class="w-4 h-4 shrink-0" />
        </Button>
      </header>
      <div class="flex flex-col p-4 pb-3 gap-6">
        <div
          :class="
            cn(
              'grid grid-cols-1 gap-2.5 w-full',
              isLargeScreen && newFilter.operator.length > 0 && 'grid-cols-2'
            )
          "
        >
          <div class="flex flex-col gap-2.5 w-full">
            <CustomSelect
              :model-value="newFilter.filterType"
              :placeholder="$t('common.select_category')"
              :options="filterTypeOptions"
              class="w-full"
              has-small-text
              @update:model-value="onFilterTypeSelect($event as TableFilter)"
            />
            <CustomSelect
              v-if="newFilter.filterType.length > 0"
              v-model="newFilter.operator"
              :placeholder="$t('common.select_condition')"
              :options="operatorsOptions"
              has-small-text
            />
          </div>
          <div v-if="newFilter.operator.length > 0">
            <MultiSelect
              v-if="
                MULTISELECT_FILTERING_TYPES.includes(
                  newFilter.filterType as MultiSelectFilteringTypes
                )
              "
              v-model="newFilter.values"
              :options="multiSelectOptions"
              @on-option-remove="onFilterRemove"
            />
            <CustomSelect
              v-else-if="
                SELECT_FILTERING_TYPES.includes(
                  newFilter.filterType as SelectFilteringTypes
                )
              "
              v-model="newFilter.value"
              :options="selectValueOptions"
              has-small-text
            />
            <RangePicker
              v-else-if="
                DATE_FILTER.includes(newFilter.operator as DateOperators) &&
                newFilter.operator === BETWEEN_OPERATOR
              "
              v-model="newFilter.dateRange"
            />
            <CustomDatePicker
              v-else-if="
                DATE_FILTER.includes(newFilter.operator as DateOperators)
              "
              v-model="newFilter.value"
            />
            <Input
              v-else
              v-model="newFilter.value"
              :placeholder="$t('common.input_value')"
            />
          </div>
        </div>
        <Button
          v-if="newFilter.operator.length > 0"
          :variant="BUTTON_VARIANTS.info"
          :is-disabled="isApplyDisabled"
          size="sm"
          class="w-full"
          @click="handleNewFilterCreate(false)"
        >
          {{ $t('common.apply') }}
        </Button>
      </div>
    </div>
  </OnClickOutside>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
