<script setup lang="ts">
import {
  computed,
  type InputTypeHTMLAttribute,
  nextTick,
  ref,
  watch
} from 'vue';
import { usePrevious, useVModel } from '@vueuse/core';

import { AlertCircle, Check, X, Eye, EyeOff } from 'lucide-vue-next';

import { cn } from '@/lib/utils';
import { prepareMessage } from '@/lib/helpers';

import { Button } from '@/components/ui/Button';
import { inputVariants } from '@/components/ui/Input/index';

import {
  STATUS_DEFAULT,
  STATUS_ERROR,
  STATUS_SUCCESS
} from '@/constants/status';

import { BUTTON_VARIANTS } from '@/constants/component';

import type { InputProps } from '@/types/components';

const props = withDefaults(defineProps<InputProps>(), {
  canBeCleared: false,
  canBeRefocused: false,
  class: null,
  type: 'text',
  defaultValue: '',
  iconComponent: null,
  id: 'default-input',
  isDisabled: false,
  showErrorMessage: true,
  messages: () => ({
    errorMessage: 'An error has occurred.',
    successMessage: 'Success!'
  }),
  modelValue: '',
  placeholder: 'Type here',
  validationStatus: STATUS_DEFAULT
});
const inputRef = ref<HTMLInputElement | null>(null);
const wasInputFocused = ref<boolean>(false);
const isDisabled = computed<boolean>(() => props.isDisabled);
const prevIsDisabled = usePrevious(isDisabled);

const inputMessage = computed(() =>
  prepareMessage(props.validationStatus, props.messages)
);

watch(isDisabled, () => {
  if (!props.canBeRefocused) {
    return;
  }

  if (isDisabled.value && !prevIsDisabled.value) {
    wasInputFocused.value = document.activeElement === inputRef.value;
  }

  if (wasInputFocused.value && !isDisabled.value && inputRef.value) {
    nextTick(() => {
      wasInputFocused.value = false;
      (inputRef.value as HTMLInputElement).focus();
    });
  }
});

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number | Date): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue
});
const currentType = ref<InputTypeHTMLAttribute>(props.type ?? 'text');

function onPasswordToggle() {
  currentType.value = currentType.value === 'password' ? 'text' : 'password';
}
</script>

<template>
  <div class="w-full">
    <div class="relative">
      <input
        :id="id"
        ref="inputRef"
        v-model="modelValue"
        :type="currentType"
        :disabled="isDisabled"
        :class="
          cn(
            inputVariants({ variant: validationStatus }),
            iconComponent && 'pl-9',
            (type === 'password' || (modelValue && canBeCleared)) && 'pr-9',
            (type === 'password' || (modelValue && canBeCleared)) &&
              validationStatus !== STATUS_DEFAULT &&
              'pr-16',
            props.class
          )
        "
        :placeholder="placeholder"
      />
      <!-- Leading content icon slot -->
      <div
        v-if="iconComponent"
        class="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4 peer-disabled:opacity-50 peer-disabled:pointer-events-none"
      >
        <component
          :is="iconComponent"
          class="stroke-gray-500 w-3.5 h-3.5"
        ></component>
      </div>
      <div
        v-if="
          type === 'password' ||
          (modelValue && canBeCleared) ||
          validationStatus !== STATUS_DEFAULT
        "
        class="flex gap-2.5 p-0 absolute top-1/2 end-3 -translate-y-1/2"
      >
        <div
          v-if="validationStatus === STATUS_ERROR"
          class="flex items-center pointer-events-none"
        >
          <AlertCircle class="w-4 h-4 stroke-red-500" />
        </div>

        <div
          v-if="validationStatus === STATUS_SUCCESS"
          class="flex items-center pointer-events-none"
        >
          <Check class="w-4 h-4 stroke-emerald-500" />
        </div>
        <Button
          v-if="modelValue && canBeCleared"
          :is-disabled="isDisabled"
          :variant="BUTTON_VARIANTS.icon"
          class="p-0 cursor-pointer"
          @click="modelValue = ''"
        >
          <X class="w-4 h-4 stroke-gray-500" />
        </Button>
        <Button
          v-if="type === 'password'"
          :variant="BUTTON_VARIANTS.icon"
          class="p-0 cursor-pointer"
          @click="onPasswordToggle"
        >
          <EyeOff
            v-if="currentType === 'password'"
            class="w-4 h-4 stroke-black"
          />
          <Eye v-else class="w-4 h-4 stroke-black" />
        </Button>
      </div>
    </div>
    <p
      v-if="inputMessage && showErrorMessage"
      :class="
        cn(
          'text-start text-sm mt-1',
          validationStatus === STATUS_ERROR && 'text-red-600',
          validationStatus === STATUS_SUCCESS && 'text-emerald-600'
        )
      "
    >
      {{ inputMessage }}
    </p>
  </div>
</template>

<style scoped>
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
