// User related
export const TYPE_GROUP = 'group';
export const TYPE_USER = 'user';
export const TYPE_CONTAINER = 'container';

// Products
export const PRODUCT_PATENT_PLATFORM = 'Patent Platform';
export const PRODUCT_IDEA_DATABASE = 'Idea Database';

export type AllowedProducts =
  | typeof PRODUCT_PATENT_PLATFORM
  | typeof PRODUCT_IDEA_DATABASE;
