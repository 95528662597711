<script setup lang="ts">
import type { Component } from 'vue';
import type { ParseKeys } from 'i18next';

import { cn } from '@/lib/utils';
import { ConditionalTooltip } from '@/components/ui/Tooltip';

const props = defineProps<{
  currentRoute: string;
  icon?: Component;
  isSidebarOpen: boolean;
  href: string;
  testId?: string;
  translation: ParseKeys;
}>();
</script>

<template>
  <ConditionalTooltip
    :should-show="!isSidebarOpen"
    :content="$t(props.translation)"
    placement="right"
  >
    <a
      :class="
        cn(
          'rounded-lg grow-1 px-3 py-2 h-9 justify-start items-center gap-2.5 flex',
          isSidebarOpen ? 'justify-start' : 'justify-center',
          currentRoute === props.href && 'bg-gray-100 font-semibold'
        )
      "
      :href="props.href"
      :data-testid="testId"
    >
      <component
        :is="icon"
        stroke-width="1.5"
        class="shrink-0 h-4 w-4"
      ></component>
      <span v-if="props.isSidebarOpen" class="block dark:text-black text-sm">{{
        $t(props.translation)
      }}</span>
    </a>
  </ConditionalTooltip>
</template>
