import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchToken } from '@/api/token';

import { INITIAL_TOKEN, TOKEN_API } from '@/constants/api';

import type {DetailedToken, ListToken} from '@/types/tokens';

function useToken(currentToken: ListToken) {
  const { data, isFetching, isError, isPlaceholderData } = useQuery({
    queryKey: [TOKEN_API, currentToken],
    queryFn: () => fetchToken(currentToken.id),
    select(data) {
      return data.data;
    }
  });

  const token = computed<DetailedToken>(() => {
    return data.value ?? INITIAL_TOKEN;
  });

  return { token, isFetching, isError, isPlaceholderData };
}

export default useToken;
