import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { fetchSingleOrganizationUserPreset } from '@/api/organizationUserPreset';

import { INITIAL_ORGANIZATION_USER_PRESET } from '@/constants/organizationUserPreset';
import { ORGANIZATION_USER_PRESETS_API } from '@/constants/api';

import type {
  OrganizationUserPreset,
  OrganizationUserPresetSimplified
} from '@/types/organizationUserPresets';

function useOrganizationUserPreset(
  activePreset: OrganizationUserPresetSimplified
) {
  const { data, isFetching, isError, isPlaceholderData } = useQuery({
    queryKey: [ORGANIZATION_USER_PRESETS_API, activePreset],
    queryFn: () => fetchSingleOrganizationUserPreset(activePreset.id),
    select(data) {
      return data.data;
    }
  });

  const organizationUserPreset = computed<OrganizationUserPreset>(() => {
    return (
      data.value ??
      (INITIAL_ORGANIZATION_USER_PRESET as unknown as OrganizationUserPreset)
    );
  });

  return { organizationUserPreset, isFetching, isError, isPlaceholderData };
}

export default useOrganizationUserPreset;
