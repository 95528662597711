export const SELECTED_ALL_ROW_WARNING = 'common.all_selected_affected';
export const NOTE_OVERWRITE = 'manageAccounts.note_overwrite';
export const NO_PRODUCT_SELECTED = 'manageAccounts.assign_product_warning';
export const ACCESS_CHANGE_WARNING = 'common.lose_access_warning';
export const ORGANIZATION_TWO_FACTOR_FORCED =
  'organization.two_factor_force_warning';

export const V_HTML_ALERTS = [
  SELECTED_ALL_ROW_WARNING,
  ORGANIZATION_TWO_FACTOR_FORCED
] as const;

export type AllowedAlert =
  | typeof SELECTED_ALL_ROW_WARNING
  | typeof NOTE_OVERWRITE
  | typeof NO_PRODUCT_SELECTED
  | typeof ACCESS_CHANGE_WARNING
  | typeof ORGANIZATION_TWO_FACTOR_FORCED;
