import { h } from 'vue';

import { AsyncSwitch } from '@/components/custom';
import { Button } from '@/components/ui/Button';
import SelectColumn from '@/components/custom/AdvancedTable/SelectColumn';

import UserInfoBlock from '@/views/Accounts/components/UserInfoBlock.vue';
import ProductAccessLabel from '@/views/Accounts/components/ProductAccessLabel.vue';
import AccountsActions from '@/views/Accounts/components/AccountsActions.vue';

import { getColumnSorting, getSortingIcon } from '@/lib/sorting';
import { changeUserStatus } from '@/api/user';

import { BUTTON_VARIANTS } from '@/constants/component';
import { USERS_API } from '@/constants/api';

import type { ApiResponse, ApiTableResponse } from '@/types/api';
import type { ColumnDef } from '@tanstack/vue-table';
import type { ListUser } from '@/types/users';
import type { ColumnsParams } from '@/types/table';

function updateUsersList(
  oldData: ApiTableResponse<unknown> | undefined,
  response: ApiResponse<unknown>
) {
  return {
    ...(oldData as ApiTableResponse<ListUser>),
    data: (oldData as ApiTableResponse<ListUser>).data.map(user => {
      if (
        user.id ===
        (
          response as ApiResponse<{
            user: ListUser;
          }>
        ).data.user.id
      ) {
        return (
          response as ApiResponse<{
            user: ListUser;
          }>
        ).data.user;
      }

      return user;
    })
  };
}

export const accountsColumns = ({
  isFetching,
  isAllSelected,
  sortedColumns,
  handleSortingUpdate,
  handleIsAllSelected,
  t,
  total
}: ColumnsParams): ColumnDef<ListUser>[] => [
  SelectColumn({
    isAllSelected,
    isFetching,
    handleIsAllSelected,
    t
  }),
  {
    accessorKey: 'fullName',
    header: () => {
      return h(
        Button,
        {
          class: 'text-xs font-semibold text-inherit uppercase px-0 py-0 h-min',
          isDisabled: isFetching.value || !total,
          variant: BUTTON_VARIANTS.icon,
          title: t('manageAccounts.sortByName'),
          onClick: () => {
            handleSortingUpdate(getColumnSorting(sortedColumns, 'full_name'));
          }
        },
        () => [
          t('common.name'),
          h(getSortingIcon(sortedColumns, 'full_name'), {
            class: 'text-gray-800 h-4 w-4'
          })
        ]
      );
    },
    cell: ({ row }) =>
      h(UserInfoBlock, {
        'full-name': row.original.fullName,
        email: row.original.email
      })
  },
  {
    accessorKey: 'lastLogin',
    header: () => {
      return h(
        Button,
        {
          class: 'text-xs font-semibold text-inherit uppercase px-0 py-0 h-min',
          isDisabled: isFetching.value || !total,
          variant: BUTTON_VARIANTS.icon,
          title: t('manageAccounts.sortByLoginDate'),
          onClick: () => {
            handleSortingUpdate(getColumnSorting(sortedColumns, 'last_login'));
          }
        },
        () => [
          t('manageAccounts.last_login'),
          h(getSortingIcon(sortedColumns, 'last_login'), {
            class: 'text-gray-800 h-4 w-4'
          })
        ]
      );
    },
    cell: ({ row }) =>
      h(
        'p',
        { class: 'whitespace-nowrap text-ellipsis overflow-hidden' },
        row.getValue('lastLogin') || '-'
      )
  },
  {
    accessorKey: 'productAccess',
    header: () => h('p', t('manageAccounts.productAccess')),
    cell: ({ row }) =>
      h(ProductAccessLabel, {
        'idea-database-roles': row.original.ideaDatabaseRoles,
        'is-id-enabled': row.original.ideaDatabaseProductAvailable,
        'is-patent-platform-enabled': row.original.patentsProductAvailable,
        'patent-platform-role': row.original.patentsPlatformUserType
      })
  },
  {
    accessorKey: 'isEnabled',
    size: '10%' as unknown as number,
    header: () => h('p', t('common.status')),
    cell: ({ row }) => {
      return h(AsyncSwitch, {
        'model-value': Boolean(row.getValue('isEnabled')),
        invalidateQueryKey: USERS_API,
        onChangeHandle: changeUserStatus.bind(null, row.original.id),
        onChangeUpdate: updateUsersList
      });
    }
  },
  {
    accessorKey: 'actions',
    size: '10%' as unknown as number,
    header: () => h('p', t('common.listActions')),
    cell: ({ row }) => h(AccountsActions, { user: row.original }),
    enablePinning: true
  }
];
