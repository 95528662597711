<script setup lang="ts">
import { inject, type Ref } from "vue";

import { Button } from '@/components/ui/Button';

import {
  disableOrganizationTwoFactorConfirmationModal,
  openForceOrganizationTwoFactorConfirmationModal
} from "@/lib/modals/organizationSettingsModals";

import { COMPONENT_VARIANTS } from "@/constants/component";

import type { CurrentUser } from "@/types/users";
import type { UseModal } from "@/types/modals";

const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
const openModal = inject('openModal') as UseModal['openModal'];
</script>

<template>
  <div class="w-full flex flex-col sm:flex-row sm:justify-end gap-3.5">
    <Button
      v-if="!currentUser.organization.twofaForced"
      :variant="COMPONENT_VARIANTS.info"
      class="min-w-25"
      @click="openForceOrganizationTwoFactorConfirmationModal(openModal, currentUser.organization, currentUser.loginType)"
    >{{ $t('userProfile.Enable') }}
    </Button>

    <Button
      v-if="currentUser.organization.twofaForced"
      :variant="COMPONENT_VARIANTS.destructive"
      class="min-w-25"
      @click="disableOrganizationTwoFactorConfirmationModal(openModal, currentUser.organization, currentUser.loginType)"
    >{{ $t('userProfile.Disable') }}
    </Button>
  </div>
</template>
