<script setup lang="ts">
import { computed, inject, type Ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useTranslation } from 'i18next-vue';

import { ALLOWED_BREAKPOINTS } from '@/constants/layout';
import { CHART_DEFAULT_NO_VALUE } from '@/constants/charts';

import type { ApexOptions } from 'apexcharts';
import type { CurrentUser } from '@/types/users';

const { t } = useTranslation();

const isLargeScreen = useMediaQuery(ALLOWED_BREAKPOINTS.lg);
const currentUser = inject<Ref<CurrentUser>>('currentUser') as Ref<CurrentUser>;
const series = computed(() => [
  {
    name: t('common.active'),
    data: [
      currentUser.value.organizationStatistics.patentsAdministratorUsers.limit
        ? currentUser.value.organizationStatistics.patentsAdministratorUsers
            .enabled
        : CHART_DEFAULT_NO_VALUE,
      currentUser.value.organizationStatistics.patentsFullUsers.limit
        ? currentUser.value.organizationStatistics.patentsFullUsers.enabled
        : CHART_DEFAULT_NO_VALUE,
      currentUser.value.organizationStatistics.patentsLimitedUsers.limit
        ? currentUser.value.organizationStatistics.patentsLimitedUsers.enabled
        : CHART_DEFAULT_NO_VALUE,
      currentUser.value.organizationStatistics.patentsReviewerUsers.limit
        ? currentUser.value.organizationStatistics.patentsReviewerUsers.enabled
        : CHART_DEFAULT_NO_VALUE,
      currentUser.value.organizationStatistics.patentsExternalUsers.enabled
    ]
  },
  {
    name: t('common.available'),
    data: [
      currentUser.value.organizationStatistics.patentsAdministratorUsers.limit -
        currentUser.value.organizationStatistics.patentsAdministratorUsers
          .enabled,
      currentUser.value.organizationStatistics.patentsFullUsers.limit -
        currentUser.value.organizationStatistics.patentsFullUsers.enabled,
      currentUser.value.organizationStatistics.patentsLimitedUsers.limit -
        currentUser.value.organizationStatistics.patentsLimitedUsers.enabled,
      currentUser.value.organizationStatistics.patentsReviewerUsers.limit -
        currentUser.value.organizationStatistics.patentsReviewerUsers.enabled
    ]
  }
]);
const chartOptions = computed<ApexOptions>(() => ({
  chart: {
    id: `Patents_Platform_Users_Chart`,
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: '100%',
    toolbar: {
      show: false
    },
    fontFamily: 'Poppins',
    fontSize: 14,
    fontColor: '#1F2937'
  },
  colors: ['#4361EE', '#E5E7EB'],
  dataLabels: {
    enabled: isLargeScreen.value,
    style: {
      colors: ['#FFFFFF', '#1F2937'],
      cssClass: 'text-gray-800 text-xs font-medium',
      fontFamily: 'Poppins'
    },
    formatter(_val, opts) {
      const seriesValue =
        series.value[opts.seriesIndex].data[opts.dataPointIndex];

      return series.value[opts.seriesIndex].data[opts.dataPointIndex] ===
        CHART_DEFAULT_NO_VALUE
        ? 0
        : seriesValue;
    }
  },
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
  states: {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none'
      }
    }
  },
  xaxis: {
    type: 'category',
    categories: [
      t('organizationDashboard.administrator'),
      t('organizationDashboard.full_access_user'),
      t('organizationDashboard.limited_access_user'),
      t('organizationDashboard.reviewer'),
      t('organizationDashboard.viewers')
    ],
    labels: {
      show: isLargeScreen.value,
      formatter(val) {
        return `${val}%`;
      },
      style: {
        cssClass: 'text-gray-800 text-xs font-medium',
        fontFamily: 'Poppins'
      }
    }
  },
  yaxis: {
    labels: {
      show: true,
      style: {
        cssClass: 'text-gray-800 text-xs font-medium',
        fontFamily: 'Poppins'
      }
    }
  },
  tooltip: {
    enabled: false
  },
  fill: {
    opacity: 1
  },
  legend: {
    onItemClick: {
      toggleDataSeries: isLargeScreen.value
    },
    horizontalAlign: 'left',
    cssClass: 'text-gray-800 text-xs font-medium',
    fontFamily: 'Poppins'
  }
}));
</script>
<template>
  <div class="w-full">
    <apexchart
      type="bar"
      :height="chartOptions.chart?.height"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
